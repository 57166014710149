import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const RegisterPanel = props => {
    const classes = useStyles();
    const [ usernameFocused, setUsernameFocused] = useState(false);

    const { isProcessing, username, password, confirmPassword, 
        firstname, lastname, displayName, email,
        isValidPassword } = props;

    const { onUsernameChange, onPasswordChange, onConfirmPasswordChange, 
        onFirstnameChange, onLastnameChange, onDisplayNameChange, 
        onEmailChange, onSubmitRegisterClick } = props;

    let usernameInput;

    const setFocus = () => {
        usernameInput.focus();
        setUsernameFocused(true);
    }

    useEffect(() => {
        if (!usernameFocused) {
            setFocus();
        }        
    })

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <form className={classes.form} onSubmit={onSubmitRegisterClick} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="username-input"
                        label="Username"
                        name="username-input"
                        autoComplete="username-input"
                        autoFocus={true}
                        inputRef={ input => {usernameInput = input;} }                      
                        value={username}
                        onChange={onUsernameChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        value={password}
                        error={!isValidPassword()}
                        onChange={onPasswordChange}                        
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="confirmPassword"
                        label="Confirm Password"
                        type="password"
                        id="confirmPassword"
                        value={confirmPassword}
                        error={!isValidPassword()}
                        onChange={onConfirmPasswordChange}                        
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="firstname-input"
                        label="First name"
                        name="firstname-input"
                        autoComplete="firstname-input"                      
                        value={firstname}
                        onChange={onFirstnameChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="lastname-input"
                        label="Last name"
                        name="lastname-input"
                        autoComplete="lastname-input"                      
                        value={lastname}
                        onChange={onLastnameChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="displayname-input"
                        label="Display name"
                        name="displayname-input"
                        autoComplete="displayname-input"
                        helperText="Name displayed in app"                                              
                        value={displayName}
                        onChange={onDisplayNameChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email-input"
                        label="Email"
                        name="email-input"
                        autoComplete="email-input"
                        helperText="To use to send you forgotten password email and registration confirmation."                      
                        value={email}
                        onChange={onEmailChange}
                    />                    
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={isProcessing} 
                        type="submit"
                    >
                        Register
                    </Button>                    
                </form>
            </div>
            <Box mt={3}>
                <Typography variant="body2" color="textSecondary">
                    An email confirmation will be sent to the email address provided. Please check your junk email folder if you cannot find it.<br />
                    Registration to Christ The Beloved Bible App ensures your data is synced to the cloud. This allows to have same user experience and data on multiple devices and to protect against data loss.
                </Typography>
                <br />                
            </Box>
        </Container>
    );
}

RegisterPanel.propTypes = {
    isProcessing: PropTypes.bool.isRequired,
    username: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    confirmPassword: PropTypes.string.isRequired,
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    isValidPassword: PropTypes.func.isRequired,
    onUsernameChange: PropTypes.func.isRequired,
    onPasswordChange: PropTypes.func.isRequired,
    onConfirmPasswordChange: PropTypes.func.isRequired,
    onFirstnameChange: PropTypes.func.isRequired,
    onLastnameChange: PropTypes.func.isRequired,
    onDisplayNameChange: PropTypes.func.isRequired,
    onEmailChange: PropTypes.func.isRequired,
    onSubmitRegisterClick: PropTypes.func.isRequired
}

export default RegisterPanel;