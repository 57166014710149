import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import StyledVerse from './StyledVerse';

const VerseBox = props => {
    const { verse, shouldStyleVerseOfTheDay, onHandleMenuOpenClick, onHandleMenuPointerDown } = props;
    
    let highlightedText = '';
    let verseOfTheDayText = '';

    if (verse.isHighlighted) {
        if (verse.highlightedText) {
            highlightedText = verse.highlightedText;
        } else {
            highlightedText = verse.verseText;
        }
    }

    if (shouldStyleVerseOfTheDay && verse.isVerseOfTheDay) {
        if (verse.verseOfTheDayText) {
            verseOfTheDayText = verse.verseOfTheDayText;
        } else {
            verseOfTheDayText = verse.verseText;
        }
    }

    return (
        <Box fontSize="h6.fontSize" m={1} key={verse.verseVersionId}>
            <span onPointerDown={onHandleMenuPointerDown} onClick={onHandleMenuOpenClick}>{verse.verseNumber}</span> <StyledVerse 
            text={verse.verseText} 
            highlight={highlightedText}
            verseOfTheDay={verseOfTheDayText}/>
        </Box>
    );
}

VerseBox.propTypes = {
    verse: PropTypes.object.isRequired,
    shouldStyleVerseOfTheDay: PropTypes.bool.isRequired,
    onHandleMenuOpenClick: PropTypes.func.isRequired,
    onHandleMenuPointerDown: PropTypes.func.isRequired
}

export default VerseBox;