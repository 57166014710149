import { take, put, fork } from 'redux-saga/effects';
import * as chaptersActions from '../actions';
import { getBookChaptersFromStorageActions } from '../actions';

function* loadBookChapters(bibleVersionId, bookId) {
    yield put(getBookChaptersFromStorageActions.loadBookChaptersFromStorage(bibleVersionId, bookId));
}

export const watchLoadChapters = function* () {
    while(true) {
        const {bibleVersionId, bookId} = yield take(chaptersActions.LOAD_BOOK_CHAPTERS);        
        yield fork(loadBookChapters, bibleVersionId, bookId);
    }
}