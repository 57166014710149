import { take, call, fork, put } from 'redux-saga/effects';
import { getBibleVersionsFromStorageActions, getBibleVersionsFromApiActions } from '../actions';
import { fetchBibleVersionsFromStorageService } from '../indexedDbStorage/service';
import { fetchEntity } from '../../../../sagas/sagaHelper';

// bind Generators
const fetchBibleVersionsFromStorage = fetchEntity.bind(null, getBibleVersionsFromStorageActions.getBibleVersionsFromStorage, fetchBibleVersionsFromStorageService);

function* loadBibleVersions() {
    yield call(fetchBibleVersionsFromStorage);    
}

export const watchLoadBibleVersionsFromStorage = function* () {
    const { GET_BIBLE_VERSIONS_FROM_STORAGE } = getBibleVersionsFromStorageActions;
    
    while(true) {
        yield take(getBibleVersionsFromStorageActions.LOAD_BIBLE_VERSIONS_FROM_STORAGE);
        yield fork(loadBibleVersions);
        
        const action = yield take([GET_BIBLE_VERSIONS_FROM_STORAGE.SUCCESS, GET_BIBLE_VERSIONS_FROM_STORAGE.FAILURE]);
        if (action.type === GET_BIBLE_VERSIONS_FROM_STORAGE.FAILURE) {
            yield put(getBibleVersionsFromApiActions.loadBibleVersionsFromApi());
        }        
    }
}