import { take, call, fork } from 'redux-saga/effects';
import { verseOfTheDayActions } from '../actions';
import { fetchPersonalVerseOfTheDayByDateFromStorageService } from '../indexedDbStorage';
import { fetchEntity } from '../../../sagas/sagaHelper';

// bind Generators
const fetchPersonalVerseOfTheDayFromStorage = fetchEntity.bind(null, verseOfTheDayActions.getPersonalVerseOfTheDayFromStorage, fetchPersonalVerseOfTheDayByDateFromStorageService.fetchPersonalVerseOfTheDayByDateFromStorage);

function* loadPersonalVerseOfTheDay(bibleVersionId, date) {
    yield call(fetchPersonalVerseOfTheDayFromStorage, bibleVersionId, date);    
}

export const watchLoadPersonalVerseOfTheDayFromStorage = function* () {
    const { LOAD_PERSONAL_VERSEOFTHEDAY_FROM_STORAGE, GET_PERSONAL_VERSEOFTHEDAY_FROM_STORAGE } = verseOfTheDayActions;
    
    while(true) {
        const {bibleVersionId, date} = yield take(LOAD_PERSONAL_VERSEOFTHEDAY_FROM_STORAGE);
        yield fork(loadPersonalVerseOfTheDay, bibleVersionId, date);
        
        const action = yield take([
            GET_PERSONAL_VERSEOFTHEDAY_FROM_STORAGE.SUCCESS, 
            GET_PERSONAL_VERSEOFTHEDAY_FROM_STORAGE.FAILURE
        ]);
        
        if (action.type === GET_PERSONAL_VERSEOFTHEDAY_FROM_STORAGE.FAILURE) {            
        }        
    }
}