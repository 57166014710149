import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import * as Routes from '../../../constants';

const SignOutMenu = props => {
    const { signOutAccountAnchorEl, onAccountMenuClose, onSignOutMenuClick } = props;

    const isSignOutAccountMenuOpen = Boolean(signOutAccountAnchorEl);
    const signOutAccountMenuId = 'signOut-account-menu';

    return (
        <Menu
            anchorEl={signOutAccountAnchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            id={signOutAccountMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isSignOutAccountMenuOpen}
            onClose={onAccountMenuClose}
        >
            <MenuItem component={Link} to={Routes.ROUTES_UPDATE_USER_INFO_PATHNAME} onClick={onAccountMenuClose}>View/Update Info</MenuItem>
            <MenuItem component={Link} to={Routes.ROUTES_CHANGE_PASSWORD_PATHNAME} onClick={onAccountMenuClose}>Change Password</MenuItem>
            <MenuItem onClick={onSignOutMenuClick}>Sign Out</MenuItem>
        </Menu>
    );
}

SignOutMenu.propTypes = {
    onAccountMenuClose: PropTypes.func.isRequired,
    onSignOutMenuClick: PropTypes.func.isRequired,
}

export default SignOutMenu;