import { take, put } from 'redux-saga/effects';
import * as bibleVersionsActions from '../../Bible/Versions/actions';
import { verseOfTheDayActions } from '../actions';
import { ROUTES_VERSEOFTHEDAY_PATHNAME } from '../../../constants';

export const watchSetVerseOfTheDayVersion = function* () {
    while(true) {
        const {versionId} = yield take(bibleVersionsActions.SET_BIBLE_VERSION);
        if (window.location.pathname === ROUTES_VERSEOFTHEDAY_PATHNAME) {            
            yield put(verseOfTheDayActions.getVerseOfTheDay(versionId));
        }             
    }
}