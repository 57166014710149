import { take, call, fork } from 'redux-saga/effects';
import { UPDATE_BOOKS_STORAGE } from '../actions';
import { addBooksToLocalStore } from '../indexedDbStorage';

function* updateLocalStorage(books) {
    yield call(addBooksToLocalStore, books);
}

export const watchUpdateBibleBooksLocalStorage = function* () {
    while(true) {
        const {books} = yield take(UPDATE_BOOKS_STORAGE);
        yield fork(updateLocalStorage, books);
    }
}