import { take, call, fork, select, put } from 'redux-saga/effects';
import { bibleChaptersFromApiActions, updateChaptersStorage } from '../actions';
import { fetchBibleChaptersApi } from '../bibleChaptersApi';
import { fetchEntity } from '../../../../sagas/sagaHelper';

// bind Generators
const fetchBibleChapters = fetchEntity.bind(null, bibleChaptersFromApiActions.getBibleChaptersFromApi, fetchBibleChaptersApi);

const getApiUrl = state => state.systemSettings.apiUrl;

function* loadChapters(bibleVersionId) {
    let apiUrl = yield select(getApiUrl);
    
    yield call(fetchBibleChapters, apiUrl, bibleVersionId);
}

export const watchLoadBibleChaptersFromApi = function* () {
    const { GET_BIBLE_CHAPTERS_FROM_API } = bibleChaptersFromApiActions;

    while(true) {
        const {bibleVersionId} = yield take(bibleChaptersFromApiActions.LOAD_BIBLE_CHAPTERS_FROM_API);        
        yield fork(loadChapters, bibleVersionId);

        const action = yield take([GET_BIBLE_CHAPTERS_FROM_API.SUCCESS, GET_BIBLE_CHAPTERS_FROM_API.FAILURE]);
        if (action.type === GET_BIBLE_CHAPTERS_FROM_API.SUCCESS) {
            const chapters = action.response.entities.chapters;
            yield put(updateChaptersStorage(chapters));
        }
    }
}