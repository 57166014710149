import { take, put, select } from 'redux-saga/effects';
import * as booksActions from '../../Books/actions';
import * as chaptersActions from '../actions';
import { getBookChaptersFromStorageActions, getBookChaptersFromApiActions } from '../../Chapters/actions';
import { getBibleBooks } from '../../Books/selectors/getBibleBooksSelector';
import { getChaptersByBookId } from '../selectors/getChaptersByBookIdSelector';

const { GET_BOOK_CHAPTERS_FROM_STORAGE } = getBookChaptersFromStorageActions;
const { GET_BOOK_CHAPTERS_FROM_API } = getBookChaptersFromApiActions;

const getBibleBook = state => state.bibleBook;
const getBookChapter = state => state.bookChapter;
const getChaptersForCurrentBookId = state => getChaptersByBookId(state);
const getBooks = state => getBibleBooks(state);

export const watchRequestPrevChapter = function* () {
    while(true) {        
        yield take(chaptersActions.REQUEST_PREV_CHAPTER);
        const bibleBook = yield select(getBibleBook);
        const bookChapter = yield select(getBookChapter);
        const bibleBooks = yield select(getBooks);

        let chaptersForCurrentBookId = yield select(getChaptersForCurrentBookId);
        const firstChapterForCurrentBookId = chaptersForCurrentBookId[0];

        let prevChapterId = bookChapter.id;

        if (bookChapter.id > firstChapterForCurrentBookId.chapterId) {
            prevChapterId = bookChapter.id - 1;
        } else if (bibleBook.id > bibleBooks[0].bookId) {
            const prevBibleBookId = bibleBook.id - 1;
            const skipSettingFirstChapter = true;
            yield put(booksActions.setBibleBook(prevBibleBookId, skipSettingFirstChapter));
            chaptersForCurrentBookId = yield select(getChaptersForCurrentBookId);

            if (!chaptersForCurrentBookId[chaptersForCurrentBookId.length - 1]) {        
                yield take([
                    GET_BOOK_CHAPTERS_FROM_STORAGE.SUCCESS, 
                    GET_BOOK_CHAPTERS_FROM_API.SUCCESS
                ]);        
                chaptersForCurrentBookId = yield select(getChaptersForCurrentBookId);
            }
        
            if (chaptersForCurrentBookId[chaptersForCurrentBookId.length - 1]) {
                prevChapterId = chaptersForCurrentBookId[chaptersForCurrentBookId.length - 1].chapterId;
            }            
        }
        
        if (prevChapterId !== bookChapter.id) {
            yield put(chaptersActions.setBookChapter(prevChapterId));
        }
    }
}