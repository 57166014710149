import { take, call, fork, put } from 'redux-saga/effects';
import { loginActions, signedInUsersStorageActions } from '../actions';
import { fetchSubmitLoginRequestApi } from '../api/loginApi';
import { fetchEntity } from '../../../../sagas/sagaHelper';

// bind Generators
const fetchSubmitLoginRequest = fetchEntity.bind(null, loginActions.login, fetchSubmitLoginRequestApi);

function* submitLoginRequest(encodedCredentials) {
    yield call(fetchSubmitLoginRequest, encodedCredentials);
}

export const watchSubmitLoginRequest = function* () {
    while(true) {
        const {encodedCredentials, shouldRememberUser} = yield take(loginActions.SUBMIT_LOGIN_REQUEST);
        yield fork(submitLoginRequest, encodedCredentials);

        const action = yield take([loginActions.LOGIN.SUCCESS, loginActions.LOGIN.FAILURE]);
        if (action.type === loginActions.LOGIN.SUCCESS) {
            if (shouldRememberUser) {
                yield put(signedInUsersStorageActions.saveUserToStorage(action.response));
            }
                        
            window.history.back();             
        }
    }
}