import * as entitiesNames from '../../common/entities/constants';

export const highlightedVerses = {    
    entityName: entitiesNames.HIGHLIGHTEDVERSES,
    storageTableName: 'highlightedVerses',
    apiTableName: 'HighlightedVerses',
    addApiData(payload) {
        return getApiDataToAdd(payload)
    },
    addToStoragePayload(payload) { 
        const storageId = payload.versionId ? `${payload.versionId}-${payload.verseId}` : `0-${payload.verseId}`;
        return {...{'storageId': storageId}, ...payload}
    },
    getLoadedEntities(entities) {
        return entities;
    }
}

const getApiDataToAdd = data => {
    let newFields = {verseId: data.verseId};
    if (data.highlightedText) {
        switch (data.versionId) {
            case 1:
                newFields = { ...newFields, versionId: data.versionId, uncVerseText: data.highlightedText };
                break;
            default:
                newFields = { ...newFields, versionId: data.versionId, verseText: data.highlightedText }
        }
    }

    if (data.userId) {
        newFields = { ...newFields, userId: data.userId }
    }
    return newFields;
}