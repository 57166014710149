import { take, call, fork, select, put } from 'redux-saga/effects';
import * as bibleBooksActions from '../actions';
import { getBooksFromStorageActions, getBooksFromApiActions } from '../actions';
import { getBooksByBibleVersion } from '../selectors/getBooksByBibleVersionSelector';
import * as appVersionActions from '../../../Version/actions';

const getBibleBooksList = (state) => getBooksByBibleVersion(state);
const getBibleBook = state => state.bibleBook;

const setFirstBook = function* () {
    let bibleBooksList = yield select(getBibleBooksList);        

    if (bibleBooksList[0]) {        
        const firstBookId = bibleBooksList[0].bookId;
        yield put(bibleBooksActions.setBibleBook(firstBookId));
    }        
}

function* loadBibleBooks(bibleVersionId) {
    yield put(getBooksFromStorageActions.loadBibleBooksFromStorage(bibleVersionId));
}

export const watchLoadBibleBooks = function* () {
    const { GET_BIBLE_BOOKS_FROM_STORAGE } = getBooksFromStorageActions;
    const { GET_BIBLE_BOOKS_FROM_API } = getBooksFromApiActions;

    while(true) {
        const {bibleVersionId} = yield take(bibleBooksActions.LOAD_BIBLE_BOOKS);
        yield put(appVersionActions.validateAppVersion());
        yield fork(loadBibleBooks, bibleVersionId);

        const action = yield take([
            GET_BIBLE_BOOKS_FROM_STORAGE.SUCCESS, 
            GET_BIBLE_BOOKS_FROM_API.SUCCESS
        ]);

        if ((action.type === GET_BIBLE_BOOKS_FROM_STORAGE.SUCCESS) || (action.type === GET_BIBLE_BOOKS_FROM_API.SUCCESS)) {
            let bibleBook = yield select(getBibleBook);        
            if (bibleBook.id === 0) {
                yield call(setFirstBook);
            }
        }
    }
}