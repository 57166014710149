import * as actions from '../../../actions/actionRequestTypes';

const { createRequestTypes, action } = actions;

export const LOAD_SETTINGS_REQUEST = 'LOAD_SETTINGS_REQUEST'
export const loadSettingsRequest = () => action(LOAD_SETTINGS_REQUEST)

export const GET_SETTINGS = createRequestTypes('GET_SETTINGS')

export const getSettings = {
    request: () => action(GET_SETTINGS.REQUEST),
    success: (id, response) => action(GET_SETTINGS.SUCCESS, {response}),
    failure: (id, error) => action(GET_SETTINGS.FAILURE, {error})
}