import { indexedDb } from '../../../../services/indexedDb';
import { USER_SETTINGS_SIGNEDIN_USERS } from '../../../constants';

export const saveUserToStorage = (userId, displayName, token) => {
    indexedDb.userSettings
        .put({
            settingName: USER_SETTINGS_SIGNEDIN_USERS,
            userId: userId,
            displayName: displayName,
            token: token
        })
        .catch(error => {
        });
}