import * as _verseOfTheDayActions from './verseOfTheDayActions';
import * as _verseOfTheDayListActions from './verseOfTheDayListActions';
import * as _verseOfTheDayListStorageActions from './verseOfTheDayListStorageActions';
import * as _verseOfTheDayListFromApiActions from './verseOfTheDayListFromApiActions';
import * as _verseOfTheDayFullVersesFromApiActions from './verseOfTheDayFullVersesFromApiActions';

export const verseOfTheDayActions = _verseOfTheDayActions;
//export const verseOfTheDayListActions = _verseOfTheDayListActions;
export const verseOfTheDayListStorageActions = _verseOfTheDayListStorageActions;
export const verseOfTheDayListFromApiActions = _verseOfTheDayListFromApiActions;
//export const verseOfTheDayFullVersesFromApiActions = _verseOfTheDayFullVersesFromApiActions;