import * as ActionTypes from './actions';

const { GET_CHAPTER_VERSES_FROM_STORAGE } = ActionTypes.getChapterVersesFromStorageActions;
const { GET_CHAPTER_VERSES_FROM_API } = ActionTypes.getChapterVersesFromApiActions;
const { LOAD_BOOK_VERSES_FROM_API } = ActionTypes.bookVersesFromApiActions;
const { LOAD_BIBLE_VERSES_FROM_API } = ActionTypes.bibleVersesFromApiActions;
const { DISPLAY_DOWNLOAD_BIBLE_OPTIONS } = ActionTypes;

const initialState = {
    isLoading: false,
    isLoadingSuccessful: false,
    canDisplayDownloadBibleOptions: false
}

export const chapterVerses = (state = initialState, action) => {
    const { type } = action

    switch (type) {
        case DISPLAY_DOWNLOAD_BIBLE_OPTIONS:
            return {
                ...state,
                canDisplayDownloadBibleOptions: true
            }
        case LOAD_BOOK_VERSES_FROM_API:
        case LOAD_BIBLE_VERSES_FROM_API:
            return {
                ...state,
                canDisplayDownloadBibleOptions: false
            }
        case GET_CHAPTER_VERSES_FROM_STORAGE.REQUEST:
        case GET_CHAPTER_VERSES_FROM_API.REQUEST:
            return {
                ...state,
                isLoading: true,
                canDisplayDownloadBibleOptions: false
            }
        case GET_CHAPTER_VERSES_FROM_STORAGE.SUCCESS:
        case GET_CHAPTER_VERSES_FROM_API.SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoadingSuccessful: true
            }
        case GET_CHAPTER_VERSES_FROM_STORAGE.FAILURE:
        case GET_CHAPTER_VERSES_FROM_API.FAILURE:
            return {
                ...state,
                isLoading: false,
                isLoadingSuccessful: false
            }
        default:
            return state;
    }
}