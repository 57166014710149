import { callApi, callApiNormalizedResponse, API_POST } from '../../../services/api';
import { entitiesResourceUrl } from '../../../services/apiResources';
import { entityByName } from '../metadata';

// entities api service
export const fetchSubmitAddEntityRequestApi = (entityName, payload) => {
    let apiTableName = entityName;
    const entity = entityByName(entityName);
    if (entity && entity.apiTableName) {
        apiTableName = entity.apiTableName;
    }
    return callApi(entitiesResourceUrl(apiTableName), API_POST, payload)
};


export const fetchEntityListApi = (entityName, filter) => callApi(entitiesResourceUrl(entityName, filter));
export const fetchNormalizedEntityListApi = (entityName, filter, entitySchemaArray) => callApiNormalizedResponse(entitiesResourceUrl(entityName, filter), entitySchemaArray);