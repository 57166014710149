import { fork, all } from 'redux-saga/effects';
import { watchLoadBibleChaptersFromApi } from './getBibleChaptersFromApiSaga';
import { watchLoadChapters } from './getChaptersSaga';
import { watchLoadChaptersFromStorage } from './getChaptersFromStorageSaga';
import { watchLoadChaptersFromApi } from './getChaptersFromApiSaga';
import { watchUpdateChaptersLocalStorage } from './updateChaptersLocalStorageSaga';
import { watchSetChapter } from './setChapterSaga';
import { watchRequestNextChapter } from './requestNextChapterSaga';
import { watchRequestPrevChapter } from './requestPrevChapterSaga';
import { watchUpdateViewFiltersBookChapterLocalStorage } from './updateViewFiltersBookChapterLocalStoreSaga';
import { watchLoadViewFiltersBookChapterFromStorage } from './getViewFiltersBookChapterFromStorageSaga';

export const watchChaptersRequests = function*() {
    yield all([
        fork(watchLoadBibleChaptersFromApi),
        fork(watchLoadChapters),
        fork(watchLoadChaptersFromStorage),
        fork(watchLoadChaptersFromApi),
        fork(watchUpdateChaptersLocalStorage),
        fork(watchSetChapter),
        fork(watchRequestNextChapter),
        fork(watchRequestPrevChapter),
        fork(watchUpdateViewFiltersBookChapterLocalStorage),
        fork(watchLoadViewFiltersBookChapterFromStorage)        
    ])
}