import React from 'react';
import { Typography } from '@material-ui/core';

const RegisterHeader = () => {
    return (
        <Typography variant="h6" color="inherit" >
            Register
        </Typography>
    );
}

export default RegisterHeader;