import { take, call, fork } from 'redux-saga/effects';
import { appVersionFromStorageActions } from '../actions';
import { fetchAppVersionFromStorageService } from '../indexedDbStorage';
import { fetchEntity } from '../../../sagas/sagaHelper';

// bind Generators
const fetchAppVersionFromStorage = fetchEntity.bind(null, appVersionFromStorageActions.getAppVersionFromStorage, fetchAppVersionFromStorageService.fetchAppVersionFromStorage);

function* loadAppVersion(appVersion) {
    yield call(fetchAppVersionFromStorage, appVersion);    
}

export const watchLoadAppVersionFromStorage = function* () {
    const { GET_APP_VERSION_FROM_STORAGE } = appVersionFromStorageActions;
    
    while(true) {
        const {appVersion} = yield take(appVersionFromStorageActions.LOAD_APP_VERSION_FROM_STORAGE);
        yield fork(loadAppVersion, appVersion);
        
        const action = yield take([
            GET_APP_VERSION_FROM_STORAGE.SUCCESS, 
            GET_APP_VERSION_FROM_STORAGE.FAILURE
        ]);
    
        if (action.type === GET_APP_VERSION_FROM_STORAGE.FAILURE) {            
        }       
    }
}