import { take, fork, call, put, select } from 'redux-saga/effects';
import { SYNC_ENTITY_LIST_TO_THECLOUD, lastUploadedEntityListSettingStorageActions, entityListStorageActions, addEntityActions } from '../actions';
import { entityByName } from '../metadata';
import { SETTINGS_LASTUPLOADED_ENTITYLIST_NAME } from '../../../features/constants';
import { fetchEntitySettingFromStorageService } from '../indexedDbStorage';
import { fetchSubmitAddEntityRequestApi } from '../api/entitiesApi';
import { settle, fetchEntity } from '../../../sagas/sagaHelper';
import { saveSettingsItemToStorage } from '../../../services/storage/saveSettingsItemToStorageService';
import { getCurrentDateTimeLtISO } from '../../../utils/dateHelper';

// bind Generators
const fetchLastUploadedEntityListSettingFromStorage = fetchEntity.bind(null, 
    lastUploadedEntityListSettingStorageActions.getLastUploadedEntityListSettingFromStorage, 
    fetchEntitySettingFromStorageService.fetchEntitySettingFromStorage);

const fetchSubmitAddEntityApiRequest = fetchEntity.bind(null, addEntityActions.addEntityApi, fetchSubmitAddEntityRequestApi);

const getLoggedinUser = state => state.loggedinUser;

function* loadLastUploadedEntityListSetting(entityName) {
    yield call(fetchLastUploadedEntityListSettingFromStorage, entityName, SETTINGS_LASTUPLOADED_ENTITYLIST_NAME(entityName));    
}

function* submitAddEntityApiRequest(entityName, payload) {
    const ADD_ENTITY_API = addEntityActions.ADD_ENTITY_API(entityName);

    yield fork(fetchSubmitAddEntityApiRequest, entityName, JSON.stringify(payload));

    yield take([ADD_ENTITY_API.SUCCESS, ADD_ENTITY_API.FAILURE]);
    //const action = yield take([ADD_ENTITY_API.SUCCESS, ADD_ENTITY_API.FAILURE]);
    //if (action.type === ADD_ENTITY_API.SUCCESS) {
        //entityData = { ...entityData, ...action.response };
    //}
}

function* addEntityListToApi(entityName, entityList) {
    const entity = entityByName(entityName); 
    let addEntityToApiRequests = entityList.map(entityItem => {
        return settle(submitAddEntityApiRequest, entityName, entity.addApiData(entityItem));
    });

    for (let request of addEntityToApiRequests) {
        yield request;
    }
}

export const watchSyncEntityListToTheCloud = function* (entityName) {
    const GET_ENTITY_LIST_LASTUPLOADED_SETTING_FROM_STORAGE = lastUploadedEntityListSettingStorageActions.GET_ENTITY_LIST_LASTUPLOADED_SETTING_FROM_STORAGE(entityName);
    const GET_ENTITY_LIST_FROM_STORAGE = entityListStorageActions.GET_ENTITY_LIST_FROM_STORAGE(entityName);
    const entity = entityByName(entityName); 

    while(true) {
        yield take(SYNC_ENTITY_LIST_TO_THECLOUD(entityName));
        yield fork(loadLastUploadedEntityListSetting, entityName); 
        
        const action = yield take([
            GET_ENTITY_LIST_LASTUPLOADED_SETTING_FROM_STORAGE.SUCCESS, 
            GET_ENTITY_LIST_LASTUPLOADED_SETTING_FROM_STORAGE.FAILURE
        ]);

        let lastUploadedDateTimeLt = null;
        if (action.type === GET_ENTITY_LIST_LASTUPLOADED_SETTING_FROM_STORAGE.SUCCESS) {            
            lastUploadedDateTimeLt = action.response.entitySetting.lastUploadedDateTimeLt;                        
        }
        yield put(entityListStorageActions.loadEntityListFromStorage(entityName, entity.getUnsynchedEntitiesFilter(lastUploadedDateTimeLt)));
        
        const getEntityListAction = yield take([
            GET_ENTITY_LIST_FROM_STORAGE.SUCCESS, 
            GET_ENTITY_LIST_FROM_STORAGE.FAILURE
        ]);

        if (getEntityListAction.type === GET_ENTITY_LIST_FROM_STORAGE.SUCCESS) {
            let entityList = getEntityListAction.response;
            let loggedinUser = yield select(getLoggedinUser);
            let addEntityToApiRequests = entityList.map(entityItem => {
                if (loggedinUser) {
                    entityItem = { ...entityItem, userId: loggedinUser.userId }
                }
                return settle(submitAddEntityApiRequest, entityName, entity.addApiData(entityItem));
            });

            for (let request of addEntityToApiRequests) {
                yield request;
            }
            
            saveSettingsItemToStorage(SETTINGS_LASTUPLOADED_ENTITYLIST_NAME(entityName), { lastUploadedDateTimeLt: getCurrentDateTimeLtISO() });
        }

        // Possibly move sections of yield/take..yield/put into their own methods or Command files
    } 
}