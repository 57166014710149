import * as actions from '../../../../actions/actionRequestTypes';

const { createRequestTypes, action } = actions;

export const LOAD_BOOK_CHAPTERS_FROM_STORAGE = 'LOAD_BOOK_CHAPTERS_FROM_STORAGE';
export const loadBookChaptersFromStorage = (bibleVersionId, bookId) => action(LOAD_BOOK_CHAPTERS_FROM_STORAGE, {bibleVersionId, bookId});

export const GET_BOOK_CHAPTERS_FROM_STORAGE = createRequestTypes('GET_BOOK_CHAPTERS_FROM_STORAGE');
export const getBookChaptersFromStorage = {
    request: () => action(GET_BOOK_CHAPTERS_FROM_STORAGE.REQUEST),
    success: (id, response) => action(GET_BOOK_CHAPTERS_FROM_STORAGE.SUCCESS, {response}),
    failure: (id, error) => action(GET_BOOK_CHAPTERS_FROM_STORAGE.FAILURE, {error})
}