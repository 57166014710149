import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import * as Routes from '../../constants';
import MessageBox from '../../common/components/MessageBox';
import MessageBanner from '../../common/components/MessageBanner';
import Main from './Main';
import Login from '../auth/Login';
import Register from '../auth/Register';
import UpdateUserInfo from '../auth/UpdateUserInfo';
import ChangePassword from '../auth/ChangePassword';
import ForgotPassword from '../auth/ForgotPassword';
import VerseOfTheDay from '../VerseOfTheDay';
import Verses from '../Bible/Verses';
import BibleHistory from '../BibleHistory';
import FavoriteVerses from '../Bible/components/FavoriteVerses';
import Topics from './components/Topics';
import Settings from './components/Settings';
import Copyrights from './components/Copyrights';
import ReleaseNotes from './components/ReleaseNotes';

const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: theme.mixins.toolbar,
});

class HomePageMain extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        theme: PropTypes.object.isRequired,
        shouldDisplayNewVersionAvailable: PropTypes.bool.isRequired,    
        shouldDisplayAlertMessage: PropTypes.bool.isRequired,
        alertType: PropTypes.string.isRequired,
        alertMessageText: PropTypes.string.isRequired,
        onHandleMessageBoxClose: PropTypes.func.isRequired,
        onHandleAppVersionUpdateClick: PropTypes.func.isRequired
    }

    render() {
        const { classes } = this.props;
        const { shouldDisplayNewVersionAvailable, shouldDisplayAlertMessage, alertType, alertMessageText, 
            onHandleMessageBoxClose, onHandleAppVersionUpdateClick } = this.props;
        
        return (
            <div>                
                <div className={classes.toolbar} />
{/*                 {(shouldDisplayNewVersionAvailable
                    ? <MessageBanner bannerMessage='A new version is available to download!'
                        buttonCaption='update'
                        onHandleClose={onHandleAppVersionUpdateClick} />
                    : <span />
                )} */}
                <MessageBox
                    canDisplayMessageBox={shouldDisplayAlertMessage}
                    variant={alertType}
                    message={alertMessageText}
                    onHandleClose={(event, reason) => onHandleMessageBoxClose(event, reason)} />

                <Route exact path={Routes.ROUTES_HOME_PATHNAME} component={Main} />
                <Route exact path={Routes.ROUTES_LOGIN_PATHNAME} component={Login} />
                <Route exact path={Routes.ROUTES_REGISTER_PATHNAME} component={Register} />
                <Route exact path={Routes.ROUTES_UPDATE_USER_INFO_PATHNAME} component={UpdateUserInfo} />
                <Route exact path={Routes.ROUTES_CHANGE_PASSWORD_PATHNAME} component={ChangePassword} />
                <Route exact path={Routes.ROUTES_FORGOTPASSWORD_PATHNAME} component={ForgotPassword} />
                <Route exact path={Routes.ROUTES_VERSEOFTHEDAY_PATHNAME} component={VerseOfTheDay} />
                <Route path={Routes.ROUTES_BIBLE_PATHNAME} component={Verses} />
                <Route path={Routes.ROUTES_READINGHISTORY_PATHNAME} component={BibleHistory} />
                <Route path={Routes.ROUTES_FAVORITEVERSES_PATHNAME} component={FavoriteVerses} />
                <Route path={Routes.ROUTES_TOPICS_PATHNAME} component={Topics} />
                <Route path={Routes.ROUTES_SETTINGS_PATHNAME} component={Settings} />
                <Route path={Routes.ROUTES_COPYRIGHTS_PATHNAME} component={Copyrights} />
                <Route path={Routes.ROUTES_RELEASENOTES_PATHNAME} component={ReleaseNotes} />
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(HomePageMain);