import * as LoginActionTypes from '../features/auth/Login/actions';

const { LOGIN } = LoginActionTypes.loginActions;
const { AUTOSIGNIN } = LoginActionTypes.autoSignInActions;

export const viewFilters = (state = {userLoggingIn: false }, action) => {
    const { type } = action

    switch (type) {
        case LOGIN.REQUEST:
        case AUTOSIGNIN.REQUEST:
            return {
                ...state,
                userLoggingIn: true
            }
        case LOGIN.SUCCESS:
        case LOGIN.FAILURE:
        case AUTOSIGNIN.SUCCESS:
        case AUTOSIGNIN.FAILURE:
            return {
                ...state,
                userLoggingIn: false
            }       
        default:
            return state;
    }
}