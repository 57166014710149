import React from 'react';


const FavoriteWords = ({ text = '', highlight = '', search = '', favorite = '', verseOfTheDay = '' }) => {
    return (
        <span className='favoriteText'>
            {text}
        </span>)
}

export default FavoriteWords;