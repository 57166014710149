import * as actions from '../../../../actions/actionRequestTypes';

const { createRequestTypes, action } = actions;

export const SUBMIT_REGISTER_REQUEST = 'SUBMIT_REGISTER_REQUEST';
export const submitRegisterRequest = newUser => action(SUBMIT_REGISTER_REQUEST, {newUser});

export const REGISTER = createRequestTypes('REGISTER');
export const register = {
    request: () => action(REGISTER.REQUEST),
    success: (id, response) => action(REGISTER.SUCCESS, {response}),
    failure: (id, error) => action(REGISTER.FAILURE, {error})
}