import { put, call } from 'redux-saga/effects';

// resuable fetch Subroutine
// entity :  aircraftList | repo | starred | stargazers
// apiFn  : api.fetchAircraftList | api.fetchRepo | ...
// id     : login | fullName
// url    : next page url. If not provided will use pass it to apiFn
export const fetchEntity = function* (entity, apiFn, id, param1, param2, param3) {
    yield put (entity.request(id));
    const {response, error} = yield call(apiFn, id, param1, param2, param3);
    if (response) {
        yield put(entity.success(id, response));
    }
    else {
        yield put(entity.failure(id, error));
    }
}

export const settle = (fn, ...args) =>
    call(function* () {
        try {
            return { status: "fulfilled", value: yield call(fn, ...args) };
        } catch (err) {
            return { status: "rejected", reason: err };
        }
    });