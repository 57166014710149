import * as _fetchAddEntityToStorageService from './fetchAddEntityToStorageService';
import * as _fetchEntityListFromStorageService from './fetchEntityListFromStorageService';
import * as _fetchLastUpdatedEntityListSettingFromStorageService from './fetchLastUpdatedEntityListSettingFromStorageService';
import * as _fetchLastAddedEntityFromStorageService from './fetchLastAddedEntityFromStorageService';
import * as _fetchEntitySettingFromStorageService from './fetchEntitySettingFromStorageService';
import * as _saveEntityListToStorageService from './saveEntityListToStorageService';

export const fetchAddEntityToStorageService = _fetchAddEntityToStorageService;
export const fetchEntityListFromStorageService = _fetchEntityListFromStorageService;
export const fetchLastUpdatedEntityListSettingFromStorageService = _fetchLastUpdatedEntityListSettingFromStorageService;
export const fetchLastAddedEntityFromStorageService = _fetchLastAddedEntityFromStorageService;
export const fetchEntitySettingFromStorageService = _fetchEntitySettingFromStorageService;
export const saveEntityListToStorageService = _saveEntityListToStorageService;