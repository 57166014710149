import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import HomePage from './HomePage';
import { resetAlertMessage } from '../../actions';
import { loadSettingsRequest } from '../Settings/System/actions';
import { forceAppReload } from '../Version/actions';
import { updateAppVersionStorage } from '../Version/actions/appVersionFromStorageActions';
import { ROUTES_HOME_PATHNAME } from '../../constants';
import { viewFilters } from './../../reducers/viewFilters';

class Home extends Component {
    static propTpes = {
        isLoggedIn: PropTypes.bool.isRequired,
        userDisplayName: PropTypes.string.isRequired,
        isProcessing: PropTypes.bool.isRequired,
        loadSettingsRequest: PropTypes.func.isRequired,
        resetAlertMessage: PropTypes.func.isRequired,
        forceAppReload: PropTypes.func.isRequired,
        updateAppVersionStorage: PropTypes.func.isRequired,
        latestAppVersion: PropTypes.string.isRequired
    }

    state = {
        mobileMoreAnchorEl: null,        
    };

    componentDidMount() { 
        this.props.loadSettingsRequest();
    }    

    handleMobileMenuClose = () => {
        this.setState({ mobileMoreAnchorEl: null })
    }

    handleMobileMenuOpen = event => {
        this.setState({ mobileMoreAnchorEl: event.currentTarget })
    }

    handleAppVersionUpdateClick = (event) => {
        event.preventDefault();

        const { latestAppVersion, updateAppVersionStorage, forceAppReload } = this.props;
        navigator.serviceWorker.forceSWupdate();
        updateAppVersionStorage(latestAppVersion);
        forceAppReload();

        if (window.location.pathname === ROUTES_HOME_PATHNAME) {
            window.location.reload(true);
        } else {
            this.props.history.push(ROUTES_HOME_PATHNAME);
        }                
    }

    handleMessageBoxClose = (event, reason) => {       
        if (reason === 'clickaway') {
            return;
        }

        this.props.resetAlertMessage();
    }

    render() {
        const { isLoggedIn, userLoggingIn, userDisplayName, isProcessing, shouldDisplayNewVersionAvailable, shouldDisplayAlertMessage, 
            alertType, alertMessageText } = this.props;

        return (
            <div>
                <HomePage isLoggedIn={isLoggedIn}
                    userLoggingIn={userLoggingIn}
                    userDisplayName={userDisplayName}
                    isProcessing={isProcessing}
                    shouldDisplayNewVersionAvailable={shouldDisplayNewVersionAvailable}
                    shouldDisplayAlertMessage={shouldDisplayAlertMessage}
                    alertType={alertType}
                    alertMessageText={alertMessageText}
                    onHandleMessageBoxClose={this.handleMessageBoxClose}
                    onHandleAppVersionUpdateClick={this.handleAppVersionUpdateClick} />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => { 
    const {
        settings,
        viewFilters,
        loggedinUser,
        appStatus,
        alertMessage
    } = state

    const isLoggedIn = loggedinUser ? true : false;
    const userLoggingIn = viewFilters.userLoggingIn;
    const userDisplayName = loggedinUser ? loggedinUser.displayName : '';
    const isProcessing = appStatus.isProcessing;
    const shouldDisplayNewVersionAvailable = settings.shouldDisplayNewVersionAvailable;
    const shouldDisplayAlertMessage = alertMessage ? true : false;
    const alertMessageText = alertMessage ? alertMessage.alertText : '';
    const alertType = alertMessage ? alertMessage.alertType : 'info';
    const latestAppVersion = settings.latestAppVersion;
    
    return {
        isLoggedIn,
        userLoggingIn,
        userDisplayName,
        isProcessing,
        shouldDisplayNewVersionAvailable,
        shouldDisplayAlertMessage,
        alertType,
        alertMessageText,
        latestAppVersion        
    }
}

export default withRouter(connect(mapStateToProps, {
    loadSettingsRequest,
    resetAlertMessage,
    forceAppReload,
    updateAppVersionStorage
    })(Home));