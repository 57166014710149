import { take, put, select, call } from 'redux-saga/effects';
import * as bibleBooksActions from '../actions';
import * as chaptersActions from '../../Chapters/actions';
import { getBookChaptersFromStorageActions, getBookChaptersFromApiActions, bibleChaptersFromApiActions } from '../../Chapters/actions';
import { getChaptersByBookId } from '../../Chapters/selectors/getChaptersByBookIdSelector';

const { GET_BOOK_CHAPTERS_FROM_STORAGE } = getBookChaptersFromStorageActions;
const { GET_BOOK_CHAPTERS_FROM_API } = getBookChaptersFromApiActions;
const { GET_BIBLE_CHAPTERS_FROM_API } = bibleChaptersFromApiActions;

const getBibleVersion = (state) => state.bibleVersion;
const getChaptersForCurrentBookId = state => getChaptersByBookId(state);

const setFirstChapter = function* () {
    let chaptersForCurrentBookId = yield select(getChaptersForCurrentBookId);        
    if (!chaptersForCurrentBookId[0]) {    
        yield take([
            GET_BOOK_CHAPTERS_FROM_STORAGE.SUCCESS, 
            GET_BOOK_CHAPTERS_FROM_API.SUCCESS,
            GET_BIBLE_CHAPTERS_FROM_API.SUCCESS
        ]);       
        chaptersForCurrentBookId = yield select(getChaptersForCurrentBookId);
    }

    if (chaptersForCurrentBookId[0]) {
        const firstChapterId = chaptersForCurrentBookId[0].chapterId;
        yield put(chaptersActions.setBookChapter(firstChapterId));
    }        
}

export const watchSetBibleBook = function* () {
    while(true) {
        const {bookId, skipSettingFirstChapter} = yield take(bibleBooksActions.SET_BIBLE_BOOK);
        yield put(bibleBooksActions.viewFiltersBibleBookFromStorageActions.updateViewFiltersBibleBookStorage(bookId));

        let bibleVersion = yield select(getBibleVersion);
        yield put(chaptersActions.loadBookChapters(bibleVersion.id, bookId));
        
        if (!skipSettingFirstChapter) {
            yield call(setFirstChapter);
        }         
    }
}