import * as actions from '../../../../actions/actionRequestTypes';

const { createRequestTypes, action } = actions;

export const LOAD_BIBLE_VERSIONS_FROM_STORAGE = 'LOAD_BIBLE_VERSIONS_FROM_STORAGE';
export const loadBibleVersionsFromStorage = () => action(LOAD_BIBLE_VERSIONS_FROM_STORAGE);

export const GET_BIBLE_VERSIONS_FROM_STORAGE = createRequestTypes('GET_BIBLE_VERSIONS_FROM_STORAGE');
export const getBibleVersionsFromStorage = {
    request: () => action(GET_BIBLE_VERSIONS_FROM_STORAGE.REQUEST),
    success: (id, response) => action(GET_BIBLE_VERSIONS_FROM_STORAGE.SUCCESS, {response}),
    failure: (id, error) => action(GET_BIBLE_VERSIONS_FROM_STORAGE.FAILURE, {error})
}