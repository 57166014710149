import { indexedDb } from '../../../services/indexedDb';

const loadEntitySettingFromLocalStore = (entityName, settingName) => {
    return indexedDb.settings.get(settingName);
}

export const fetchEntitySettingFromStorage = (entityName, settingName) => {
    return loadEntitySettingFromLocalStore(entityName, settingName)
        .then(entitySetting => {
            if (entitySetting) {       
                const response = {};
                return { ...response, entitySetting }
            } else {
            }
        })
        .catch(error => {
        })
        .then(
            response => ({ response }),
            error => ({ error: error.message })
        );         
}