import { indexedDb } from '../../../services/indexedDb';
import { normalize } from 'normalizr';
import { verseOfTheDayStorageSchemaArray } from '../schema';

const loadVerseOfTheDayListFromLocalStore = (versionId) => {
    return indexedDb.verseOfTheDayList.where('versionId')
        .equals(versionId)
        .toArray();
}

export const fetchVerseOfTheDayListFromStorage = (versionId) => {
    return loadVerseOfTheDayListFromLocalStore(versionId)
        .then(verses => {        
            if (verses.length > 0) {
                const normalized = normalize(verses, verseOfTheDayStorageSchemaArray);
                return {...normalized}
            }
            else {
                //throw new Error('No verses found in local storage!');
            }
        })
        .catch(error => {
            //throw error;
        })
        .then(
            response => ({ response }),
            error => ({ error: error.message })
        );         
}