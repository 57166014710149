import * as actions from '../../../../actions/actionRequestTypes';

const { createRequestTypes, action } = actions;

export const LOAD_BIBLE_VERSES_FROM_API = 'LOAD_BIBLE_VERSES_FROM_API';
export const loadBibleVersesFromApi = (bibleVersionId) => action(LOAD_BIBLE_VERSES_FROM_API, {bibleVersionId});

export const GET_BIBLE_VERSES_FROM_API = createRequestTypes('GET_BIBLE_VERSES_FROM_API');

export const getBibleVersesFromApi = {
    request: (bibleVersionId) => action(GET_BIBLE_VERSES_FROM_API.REQUEST, {bibleVersionId}),
    success: (id, response) => action(GET_BIBLE_VERSES_FROM_API.SUCCESS, {response}),
    failure: (id, error) => action(GET_BIBLE_VERSES_FROM_API.FAILURE, {error})
}