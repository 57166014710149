import { endpointUrl } from './apiHelper';

let apiUrl = null;

export const setApiUrl = apiServiceUrl => { apiUrl = apiServiceUrl; }

const TOKEN_RESOURCE = 'token';
export const tokenResourceUrl = () => endpointUrl(apiUrl, TOKEN_RESOURCE);
export const autoSignInResourceUrl = () => `${tokenResourceUrl()}/autoSignIn`;
export const forgotPasswordUrl = () => `${tokenResourceUrl()}/forgotPassword`;


const USERS_RESOURCE = 'users';
export const usersResourceUrl = () => endpointUrl(apiUrl, USERS_RESOURCE);
export const changePasswordUrl = () => `${usersResourceUrl()}/password`;
export const updateUserInfoUrl = userId => `${usersResourceUrl()}/${userId}`;

const ENTITIES_RESOURCE = 'entities';
const getUrlFilter = filter => {
    if (filter) {
        return Object.keys(filter).map(key => key + '=' + filter[key]).join('&')
    } else {
        return '';
    }   
};
const entitiesResourceFilter = filter => filter ? `?${getUrlFilter(filter)}` : '';
export const entitiesResourceUrl = (entityName, filter) => `${endpointUrl(apiUrl, ENTITIES_RESOURCE)}/${entityName}${entitiesResourceFilter(filter)}`;

const VERSEOFTHEDAY_RESOURCE = 'verseOfTheDay';
const verseOfTheDayResourceUrl = () => endpointUrl(apiUrl, VERSEOFTHEDAY_RESOURCE);
export const allVersesResourceUrl = (bibleVersionId) => { return `${verseOfTheDayResourceUrl()}?versionId=${bibleVersionId}` }
export const updatedVersesResourceUrl = (bibleVersionId, modifiedSinceDateTimeUtc) => { return `${allVersesResourceUrl(bibleVersionId)}&modifiedDateTimeUtc=${modifiedSinceDateTimeUtc}` }
