import { createSelector } from 'reselect';
import { getChaptersByBookIdAndBibleVersion } from './getChaptersByBookIdAndBibleVersionSelector';
import { getChaptersByBibleVersion } from './getChaptersByBibleVersionSelector';

const getSelectedChapter = state => state.bookChapter;

export const getChapterByBibleVersionAndSelectedChapterId = createSelector(
    [getChaptersByBookIdAndBibleVersion, getSelectedChapter],
    (chaptersByBookIdAndBibleVersion, selectedChapter) => {
        const chapters = chaptersByBookIdAndBibleVersion.filter(chapter => chapter.chapterId === selectedChapter.id);
        return chapters[0];
    }
)

const getChapterId = (state, chapterId) => chapterId;

export const getChapterByBibleVersionAndChapterId = createSelector(
    [getChaptersByBibleVersion, getChapterId],
    (chaptersByBibleVersion, chapterId) => {
        return chaptersByBibleVersion.find(chapter => chapter.chapterId === chapterId);
    }
)