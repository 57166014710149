import React from 'react';
import BibleHistoryPanelHeader from './Panel';

const BibleHistoryHeader = props => {
    return (
        <div>
            <BibleHistoryPanelHeader />
        </div>
    )
}

export default BibleHistoryHeader;