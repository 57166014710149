import * as _addChaptersToLocalStoreDb from './addChaptersToLocalStoreDb';
import * as _loadChaptersByBookIdAndLangIdFromLocalStoreDb from './loadChaptersByBookIdAndLangIdFromLocalStoreDb';
import * as _fetchChaptersByBookIdAndLangIdFromStorageService from './fetchChaptersByBookIdAndLangIdFromStorageService';
import * as _saveViewFiltersBookChapterToLocalStoreDb from './saveViewFiltersBookChapterToLocalStoreDb';
import * as _fetchViewFiltersBookChapterFromStorageService from './fetchViewFiltersBookChapterFromStorageService';

export const addChaptersToLocalStoreDb = _addChaptersToLocalStoreDb;
export const loadChaptersByBookIdAndLangIdFromLocalStoreDb = _loadChaptersByBookIdAndLangIdFromLocalStoreDb;
export const fetchChaptersByBookIdAndLangIdFromStorageService = _fetchChaptersByBookIdAndLangIdFromStorageService;
export const saveViewFiltersBookChapterToLocalStoreDb = _saveViewFiltersBookChapterToLocalStoreDb;
export const fetchViewFiltersBookChapterFromStorageService = _fetchViewFiltersBookChapterFromStorageService;