import React from 'react';
import _ from 'lodash';
import FavoriteWords from './FavoriteWords';
import VerseOfTheDayWords from './VerseOfTheDayWords';
import SearchWords from './SearchWords';

const HighlightedWords = ({ text = '', search = '', favorite = '', verseOfTheDay = '' }) => {
    const styledTagsList = [];
    
    const shouldProcessFavoriteText = favorite.trim() ? true : false;
    const shouldProcessVerseOfTheDayText = verseOfTheDay.trim() ? true : false;
    const shouldProcessSearchText = search.trim() ? true : false;

    const ProcessStylingVerse = (tag, textToStyle) => {               
        if (text.indexOf(textToStyle) >= 0) {
            const styleRegex = new RegExp(`(${_.escapeRegExp(textToStyle)})`, 'gi');
            const styleParts = text.split(styleRegex);

            styleParts.filter(part => part).map((part, i) => {
                var elementStart = text.indexOf(part);
                styleRegex.test(part) ? styledTagsList.push({element: tag, position: elementStart, count: i, text: part}) : styledTagsList.push({element: '<span>', position: elementStart, count: i, text: part})
            });
        }        
    }

    if (shouldProcessFavoriteText) {
        ProcessStylingVerse('<FavoriteWords>', favorite);        
    }

    if (shouldProcessVerseOfTheDayText) {
        ProcessStylingVerse('<VerseOfTheDayWords>', verseOfTheDay);        
    }

    if (shouldProcessSearchText) {
        ProcessStylingVerse('<SearchWords>', search);        
    }

    return (
        <mark>
            {
                styledTagsList.length === 0 ? text
                : styledTagsList.map((tag, i) => {
                    switch (tag.element) {
                        case '<FavoriteWords>' : return <FavoriteWords key={i} text={tag.text} />
                        case '<VerseOfTheDayWords>' : return <VerseOfTheDayWords key={i} text={tag.text} />
                        case '<SearchWords>' : return <SearchWords key={i} text={tag.text} />
                        default: return <span key={i}>{tag.text}</span>
                    }
                })
            }
        </mark>)
}

export default HighlightedWords;