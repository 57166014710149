import * as ActionTypes from './actions';

const { GET_BIBLE_BOOKS_FROM_STORAGE } = ActionTypes.getBooksFromStorageActions;
const { GET_BIBLE_BOOKS_FROM_API } = ActionTypes.getBooksFromApiActions;
const { GET_VIEWFILTERS_BIBLEBOOK_FROM_STORAGE } = ActionTypes.viewFiltersBibleBookFromStorageActions;

export const bibleBook = (state = {id: 0, isLoading: false, isLoadingSuccessful: false}, action) => {    
    const { type, bookId } = action
    
    if (type === ActionTypes.SET_BIBLE_BOOK) {
        return {
            ...state,
            id: bookId
        }
    } else if (type === GET_VIEWFILTERS_BIBLEBOOK_FROM_STORAGE.SUCCESS) {
        return {
            ...state,
            id: action.response.bibleBook.bookId
        }
    }  else if ((type === GET_BIBLE_BOOKS_FROM_STORAGE.REQUEST) || (type === GET_BIBLE_BOOKS_FROM_API.REQUEST)) {
        return {
            ...state,
            isLoading: true
        }
    } else if ((type === GET_BIBLE_BOOKS_FROM_STORAGE.SUCCESS) || (type === GET_BIBLE_BOOKS_FROM_API.SUCCESS)) {
        return {
            ...state,
            isLoading: false,
            isLoadingSuccessful: true
        }
    } else if ((type === GET_BIBLE_BOOKS_FROM_STORAGE.FAILURE) || (type === GET_BIBLE_BOOKS_FROM_API.FAILURE)) {
        return {
            ...state,
            isLoading: false,
            isLoadingSuccessful: false
        }
    }

    return state
}