import * as actions from './actionRequestTypes';

const { action } = actions;

export const RESET_ALERT_MESSAGE = 'RESET_ALERT_MESSAGE';
export const resetAlertMessage = () => action(RESET_ALERT_MESSAGE);

export const SET_ALERT_MESSAGE = 'SET_ALERT_MESSAGE';
export const setAlertMessage = (alertType, alertText) => action(SET_ALERT_MESSAGE, {alertType, alertText});

export const ADD_LOG_MESSAGE = 'ADD_LOG_MESSAGE';
export const addLogMessage = msg => action(ADD_LOG_MESSAGE, {msg});

export const RESET_LOG_MESSAGES = 'RESET_LOG_MESSAGES';
export const resetLogMessages = () => action(RESET_LOG_MESSAGES);

