import * as actions from '../../../actions/actionRequestTypes';
import { VERSEOFTHEDAY } from '../../../common/entities/constants';

const { createRequestTypes, action } = actions;

export const GET_VERSEOFTHEDAY_LIST = createRequestTypes(`GET_${VERSEOFTHEDAY}_LIST`);

export const getVerseOfTheDayList = {
    request: (bibleVersionId) => action(GET_VERSEOFTHEDAY_LIST.REQUEST, {bibleVersionId}),
    success: (id, response) => action(GET_VERSEOFTHEDAY_LIST.SUCCESS, {response}),
    failure: (id, error) => action(GET_VERSEOFTHEDAY_LIST.FAILURE, {error})
}