import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import DrawerList from './DrawerList';
import styles from '../../assets/jss/components/drawerStyle';
import drawerImage from "../../assets/img/river.jpg";
//import logo from "../../assets/img/ettLogo.png";

export const drawerWidth = 240;

const useStyles = makeStyles(styles);

const HomePageDrawer = props => {
    const classes = useStyles();
    const theme = useTheme();

    const { isMobileDrawerOpen, onHandleDrawerToggle } = props;

    const brand = (
        <div className={classes.brand}>
            <a
                href=""
                className={classes.brandLink}
                target="_blank"
            >
{/*                 <div className={classes.brandImage}>
                    <img src={logo} alt="logo" className={classes.img} />
                </div> */}
                Christ The Beloved
            </a>
        </div>
    )

    const drawerBackground = (
        <div className={classes.background}
            style={{ backgroundImage: "url(" + drawerImage + ")" }}
        />
    );

    const drawer = (
        <div className={classes.drawerContainer}>
            <DrawerList />
        </div>
    );

    return (
        <div>
            <Hidden smUp implementation="css">
                <Drawer
                    container={props.container}
                    variant="temporary"
                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                    open={isMobileDrawerOpen}
                    onClose={onHandleDrawerToggle}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    <div onClick={onHandleDrawerToggle}>
                        {brand}
                        {drawer}
                        {drawerBackground}
                    </div>
                </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
                <Drawer
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    variant="permanent"
                    open
                >
                    {brand}
                    {drawer}
                    {drawerBackground}
                </Drawer>
            </Hidden>
        </div>
    )
}

HomePageDrawer.propTypes = {
    isMobileDrawerOpen: PropTypes.bool.isRequired,
    onHandleDrawerToggle: PropTypes.func.isRequired,
}

export default HomePageDrawer;