import * as LoginActionTypes from './Login/actions';
import * as RegisterActionTypes from './Register/actions';
import * as UpdateUserInfoActionTypes from './UpdateUserInfo/actions';
import * as SignOutActionTypes from './SignOut/actions';
import { setToken } from '../../services/api';

const { LOGIN } = LoginActionTypes.loginActions;
const { AUTOSIGNIN } = LoginActionTypes.autoSignInActions;
const { REGISTER } = RegisterActionTypes.registerActions;
const { UPDATE_USER_INFO } = UpdateUserInfoActionTypes.updateUserInfoActions;
const { SIGNOUT_USER } = SignOutActionTypes.signOutActions;

export const loggedinUser = (state = null, action) => {    
    const { type } = action

    switch (type) {
        case LOGIN.SUCCESS:
        case REGISTER.SUCCESS:
        case UPDATE_USER_INFO.SUCCESS:
        case AUTOSIGNIN.SUCCESS:
            setToken(action.response.encodedToken);
            return { ...action.response };
        case SIGNOUT_USER:
            setToken(null);
            return null;
        default:
            return state;
    }
}