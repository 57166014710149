import { indexedDb } from '../../../services/indexedDb';
import _ from 'lodash';
import { SETTINGS_LASTUPDATED_VERSEOFTHEDAYLIST_NAME } from '../../constants';

export const saveVerseOfTheDayListToStorage = (verses, currentUtcTime) => {
    const versesList = _.values(verses);
    indexedDb.verseOfTheDayList.bulkPut(versesList).then(lastKey => {
    })
    .catch(error => {
    });

    indexedDb.settings.put({settingName: SETTINGS_LASTUPDATED_VERSEOFTHEDAYLIST_NAME, lastUpdatedUtcTime: currentUtcTime})
        .catch(error => {
        });
}