import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import BibleVersions from '../../Bible/Versions';

const styles = {
    root: {
        display: 'flex'                
      },
    grow: {
        flexGrow: 1,
      },
}

class VerseOfTheDayPanelHeader extends Component {
    static propTpes = {
        classes: PropTypes.object.isRequired,
        bibleVersionId: PropTypes.number.isRequired
    }

    render() {
        const { classes } = this.props;
        

        return (
            <div className={classes.root}>
                <Typography variant="h6" color="inherit" className={classes.grow} >
                    Verse Of The Day
                </Typography>                     
                <BibleVersions />
            </div>
        );
    }
}

export default withStyles(styles)(VerseOfTheDayPanelHeader);

