import * as actions from '../../../actions/actionRequestTypes';
import * as services from '../../../constants/services';
import { GET_ENTITY_LIST_ACTION_TYPE } from './entityListActions';

const { createRequestTypes, action } = actions;

export const LOAD_ENTITY_LIST_FROM_STORAGE = entityName => `LOAD_${entityName}_LIST_FROM_${services.SERVICE_ENDPOINT_TYPE_STORAGE}`;
export const loadEntityListFromStorage = (entityName, filter) => action(LOAD_ENTITY_LIST_FROM_STORAGE(entityName), filter);

export const GET_ENTITY_LIST_FROM_STORAGE = entityName => createRequestTypes(`${GET_ENTITY_LIST_ACTION_TYPE(entityName)}_FROM_${services.SERVICE_ENDPOINT_TYPE_STORAGE}`);
export const getEntityListFromStorage = {
    request: (entityName, filter) => action(GET_ENTITY_LIST_FROM_STORAGE(entityName).REQUEST, filter),
    success: (entityName, response) => action(GET_ENTITY_LIST_FROM_STORAGE(entityName).SUCCESS, {response}),
    failure: (entityName, error) => action(GET_ENTITY_LIST_FROM_STORAGE(entityName).FAILURE, {error})
}

export const SAVE_ENTITY_LIST_TO_STORAGE = entityName => `SAVE_${entityName}_LIST_TO_${services.SERVICE_ENDPOINT_TYPE_STORAGE}`;
export const saveEntityListToStorage = (entityName, entityList, currentUtcTime) => action(SAVE_ENTITY_LIST_TO_STORAGE(entityName), {entityList, currentUtcTime});