import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MainPanel from './Panel';

class Main extends Component {
    static propTpes = {
        shouldForceReload: PropTypes.bool.isRequired
    }

    componentDidMount() {
        const { shouldForceReload } = this.props;
        if (shouldForceReload) {
            window.location.reload(true);
        }        
    }
    
    render() {
        return (
            <div>
                <MainPanel />
            </div>
        );        
    }
}

const mapStateToProps = (state, ownProps) => {
    const {
        settings
    } = state
    
    const shouldForceReload = settings.shouldForceReload;
    
    return {
        shouldForceReload
    }
}

export default withRouter(connect(mapStateToProps, {    
})(Main));