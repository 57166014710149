import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ForgotPasswordPanel from './Panel';
import { forgotPasswordActions } from './actions';
import { setAlertMessage } from '../../../actions';
import { ALERT_TYPE_ERROR } from '../../../constants';
import { getCurrentDateTimeLt } from '../../../utils/dateHelper';

const { submitForgotPasswordRequest } = forgotPasswordActions;

const ForgotPassword = (props) => {
    const [username, setUsername] = useState('');

    const { isProcessing, setAlertMessage, submitForgotPasswordRequest } = props;

    const isFieldPopulated = (value) => {
        return value !== '';
    }

    const isValidUsername = () => {
        if (!isFieldPopulated(username)) {
            setAlertMessage(ALERT_TYPE_ERROR, 'Username cannot be empty');
            return false;
        }

        return true;
    }

    const handleUsernameChange = event => {
        setUsername(event.target.value);
    };

    const handleSubmitForgotPasswordClick = event => {
        event.preventDefault();
        if (isValidUsername()) {
            let encodedCredentials = window.btoa(username + ':_');
            const currentDateTimeLt = getCurrentDateTimeLt();
            submitForgotPasswordRequest(JSON.stringify({ EncodedCredentials: encodedCredentials, currentDateTimeLt: currentDateTimeLt }));
        }
    }

    return (
        <ForgotPasswordPanel isProcessing={isProcessing}
            username={username}
            onUsernameChange={handleUsernameChange}
            onSubmitClick={handleSubmitForgotPasswordClick} />
    );
}

ForgotPassword.propTypes = {
    isProcessing: PropTypes.bool.isRequired,
    submitForgotPasswordRequest: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    const {
        appStatus 
    } = state;

    const isProcessing = appStatus.isProcessing;
    return { 
        isProcessing
    }
}

export default withRouter(connect(mapStateToProps, {
    setAlertMessage,
    submitForgotPasswordRequest    
})(ForgotPassword));