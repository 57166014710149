import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import ChangePasswordPanel from './Panel';
import { changePasswordActions } from './actions';
import { setAlertMessage } from '../../../actions';
import { ALERT_TYPE_ERROR } from '../../../constants';
import { getCurrentDateTimeLt } from '../../../utils/dateHelper';
import { setToken } from '../../../services/api';

const { submitChangePasswordRequest } = changePasswordActions;

const ChangePassword = props => {    
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
    const [pageLoaded, setPageLoaded] = useState(false);

    const { isProcessing, setAlertMessage, submitChangePasswordRequest } = props;

    const initializeLoadingPage = () => {        
        const queryParams = queryString.parse(props.location.search);

        if (queryParams && queryParams.key) {
            setToken(queryParams.key);
        }
        setPageLoaded(true);
    }

    useEffect(() => {
        if (!pageLoaded) {
            initializeLoadingPage();            
        }
    })

    const isMatchingPasswords = (value1, value2) => {
        if (value1 !== value2) {
            setPasswordErrorMessage('Password mismatch');
            return false;
        }

        return true;
    }

    const isFilledPassword = (value1) => {
        if (value1 === '') {
            setPasswordErrorMessage('Password cannot be empty');
            return false;
        }

        return true;
    }

    const validatePassword = (value1, value2) => {
        if (isFilledPassword(value1) && isMatchingPasswords(value1, value2)) {
            setPasswordErrorMessage('');
        }
    }

    const handlePasswordChange = event => {
        setPassword(event.target.value);    
        validatePassword(event.target.value, confirmPassword);
    }

    const handleConfirmPasswordChange = event => {
        setConfirmPassword(event.target.value);
        validatePassword(password, event.target.value);
    }

    const handleSubmitChangePasswordClick = (event) => {
        event.preventDefault();
        if (isValidRequiredFields()) {
            let encodedCredentials = window.btoa('_:' + password);
            const dateTimeLt = getCurrentDateTimeLt();
            submitChangePasswordRequest(JSON.stringify({
                EncodedCredentials: encodedCredentials,
                currentDateTimeLt: dateTimeLt
            }));
        }
    }

    const isValidPassword = () => {
        return (passwordErrorMessage === '');
    }

    const isValidRequiredFields = () => {        
        if (!isFilledPassword(password) || !isValidPassword()) {
            var errorMsg = passwordErrorMessage !== '' ? passwordErrorMessage : 'Password cannot be empty'
            setAlertMessage(ALERT_TYPE_ERROR, errorMsg);
            return false;
        }        

        return true;
    }

    return (
        <ChangePasswordPanel isProcessing={isProcessing} 
            password={password}
            confirmPassword={confirmPassword}
            isValidPassword={isValidPassword}
            onPasswordChange={handlePasswordChange}
            onConfirmPasswordChange={handleConfirmPasswordChange}            
            onSubmitChangePasswordClick={handleSubmitChangePasswordClick} />
    );
}

ChangePassword.propTypes = {
    isProcessing: PropTypes.bool.isRequired,
    setAlertMessage: PropTypes.func.isRequired,
    submitChangePasswordRequest: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    const {
        //loggedinUser,
        appStatus 
    } = state;

    const isProcessing = appStatus.isProcessing;
    return { 
        isProcessing
    }
}

export default withRouter(connect(mapStateToProps, {
    setAlertMessage,
    submitChangePasswordRequest    
})(ChangePassword));