import * as ActionTypes from './actions';

const { GET_APP_VERSION_FROM_API } = ActionTypes.appVersionFromApiActions;
const { GET_APP_VERSION_FROM_STORAGE } = ActionTypes.appVersionFromStorageActions;
const { FORCE_APP_RELOAD } = ActionTypes;

export const settings = (state = {latestAppVersion: '', shouldDisplayNewVersionAvailable: false, shouldForceReload: false}, action) => {    
    const { type } = action
    
    if (type === GET_APP_VERSION_FROM_API.SUCCESS) {
        return {
            ...state,
            latestAppVersion: action.response.webAppVersion
        }
    } else if (type === GET_APP_VERSION_FROM_STORAGE.SUCCESS) {
        return {
            ...state,
            shouldDisplayNewVersionAvailable: false
        }
    } else if (type === GET_APP_VERSION_FROM_STORAGE.FAILURE) {
        return {
            ...state,
            shouldDisplayNewVersionAvailable: true
        }
    } else if (type === FORCE_APP_RELOAD) {
        return {
            ...state,
            shouldForceReload: true
        }
    }

    return state
}