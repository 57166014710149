import { indexedDb } from '../../../../services/indexedDb';
import _ from 'lodash';
import * as _saveViewFiltersBibleBookToLocalStoreDb from './saveViewFiltersBibleBookToLocalStoreDb';
import * as _fetchViewFiltersBibleBookFromStorageService from './fetchViewFiltersBibleBookFromStorageService';

export const saveViewFiltersBibleBookToLocalStoreDb = _saveViewFiltersBibleBookToLocalStoreDb;
export const fetchViewFiltersBibleBookFromStorageService = _fetchViewFiltersBibleBookFromStorageService;

export const addBooksToLocalStore = (books) => {
    const booksList = _.values(books);
    indexedDb.books.bulkPut(booksList).then(lastKey => {
    });
}

export const loadBooksFromLocalStore = (langId) => {
    return indexedDb.books.where('langId').equals(langId).toArray();
}