import { verseOfTheDay } from '../../features/VerseOfTheDay/metadata';
import { highlightedVerses } from '../../features/HighlightedVerses/metadata';
import { bibleHistory } from './../../features/BibleHistory/metadata';

export const entities = [];

export const initializeEntities = () => {
    entities.push(verseOfTheDay);
    entities.push(highlightedVerses);
    entities.push(bibleHistory);
}

export const entityByName = entityName => entities.find(entity => entity.entityName === entityName);

export const apiEntityResourceName = entityName => {
    let apiResourceName = entityName;
    const entity = entityByName(entityName);
    if (entity && entity.apiTableName) {
        apiResourceName = entity.apiTableName;
    }

    return apiResourceName;
}