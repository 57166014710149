import { normalize } from 'normalizr';
import { camelizeKeys } from 'humps';
import 'isomorphic-fetch';
//import configSettings from '../configSettings.json';

// Extracts the next page URL from Github API response.
function getNextPageUrl(response) {
    const link = response.headers.get('link')
    if (!link) {
        return null
    }

    const nextLink = link.split(',').find(s => s.indexOf('rel="next"') > -1)
    if (!nextLink) {
        return null
    }

    return nextLink.split(';')[0].slice(1, -1)
}

export const API_GET = 'GET'
export const API_POST = 'POST'
export const API_PUT = 'PUT'
export const API_DELETE = 'DELETE'

let currentAuthToken = null;

export const setToken = token => { currentAuthToken = token; }

const fetchHeaderAutorizationOption = () => {
    let fetchHeaderAuthorization = {}

    if (currentAuthToken) {
        fetchHeaderAuthorization = { 'Authorization': `bearer ${currentAuthToken}` }
    }

    return fetchHeaderAuthorization
}

const fetchHeaderOption = () => {
    let fetchHeader = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }

    fetchHeader = Object.assign(fetchHeader, fetchHeaderAutorizationOption())

    let headerOption = {
        headers: fetchHeader
    }

    return headerOption
}

const fetchOptions = (fetchMethod, fetchBody) => {
    let options = {
        method: fetchMethod
    }

    options = Object.assign(options, fetchHeaderOption())

    if ((fetchMethod !== API_GET) && (fetchMethod !== API_DELETE)) {
        let bodyOption = {body: fetchBody}
        options = Object.assign(options, bodyOption)
    }

    return options
}

export const callDeleteApi = (endpoint, fetchBody = '') => {
    const fetchMethod = API_DELETE;

    return fetch(endpoint, fetchOptions(fetchMethod, fetchBody))
        .then(response => {
            if (response.ok) {
                return {}
            } else {
            }
        })
        .then(
            response => ({ response }),
            error => ({ error: error.message })
        )
        .catch(error => console.log(`Error on fetch Delete - error= ${error}`))
} 

// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.
export const callApiNormalizedResponse = (endpoint, schema, fetchMethod = API_GET, fetchBody = '') => {
    return fetch(endpoint, fetchOptions(fetchMethod, fetchBody))
      .then(response => 
          response.json().then(json => ({ json, response }))
      ).then(({ json, response }) => {
            if (!response.ok) {
                return Promise.reject(json)
            }

            const camelizedJson = camelizeKeys(json);
            const nextPageUrl = getNextPageUrl(response);            

            const normalized = normalize(camelizedJson, schema);
            return {...normalized, nextPageUrl}   
      })
      .then(
        response => ({response}),
        error => ({error: error.message})
      )
}

const checkStatus = (response) => {
    if (response.ok) {
        return response;
    } else {
        const error = new Error(response.statusText);
        error.response = response;
        throw error;
    }
}

const parseJSON = (response) => {
    return response.json();
}

export const callApi = (endpoint, fetchMethod = API_GET, fetchBody = '') => {
    return fetch(endpoint, fetchOptions(fetchMethod, fetchBody))
      //  .then(checkStatus)
      //  .then(parseJSON)
      //  .then(json => ({ json, response }))
       .then(response => 
           response.json().then(json => ({ json, response }))
       )
      .then(({ json, response }) => {
            if (!response.ok) {
                const error = new Error(response.statusText);
                error.message = json;
                return Promise.reject(error)
                //return Promise.reject(json)
            }

            const camelizedJson = camelizeKeys(json);            
            return {...camelizedJson}   
      })      
      .then(
        response => ({response}),
        error => ({error: error.message})
      )      
      .catch(error => console.log(`Error on fetch - error= ${error}`))
}

export const callDuplicateApi = (endpoint, fetchMethod = API_GET, fetchBody = '') => {
    return fetch(endpoint, fetchOptions(fetchMethod, fetchBody))
        .then(checkStatus)
        .then(parseJSON)
      .then(response => 
          response.json().then(json => ({ json, response }))
      ).then(({ json, response }) => {
            if (!response.ok) {
                return Promise.reject(json)
            }

            const camelizedJson = camelizeKeys(json);            
            return {...camelizedJson}   
      })
      .catch(error => {
        console.log(`request failed ${error}`);
    })      
      .then(
        response => ({response}),
        error => ({error: error.message})
      )      
}