import { fork, all } from 'redux-saga/effects';
import { watchLoadAppVersionFromApi } from './getAppVersionFromApiSaga';
import { watchLoadAppVersionFromStorage } from './getAppVersionFromStorageSaga';
import { watchUpdateAppVersionLocalStorage } from './updateAppVersionLocalStorageSaga';

export const watchAppVersionRequests = function*() {
    yield all([        
        fork(watchLoadAppVersionFromApi),
        fork(watchLoadAppVersionFromStorage),
        fork(watchUpdateAppVersionLocalStorage)        
    ])
}