import * as actions from '../../../../actions/actionRequestTypes';

const { createRequestTypes, action } = actions;

export const SUBMIT_AUTOSIGNIN_REQUEST = 'SUBMIT_AUTOSIGNIN_REQUEST';
export const submitAutoSignInRequest = () => action(SUBMIT_AUTOSIGNIN_REQUEST);

export const AUTOSIGNIN = createRequestTypes('AUTOSIGNIN');
export const autoSignIn = {
    request: () => action(AUTOSIGNIN.REQUEST),
    success: (id, response) => action(AUTOSIGNIN.SUCCESS, {response}),
    failure: (id, error) => action(AUTOSIGNIN.FAILURE, {error})
}