import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const styles = theme => ({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    wrapper: {        
      position: 'relative',
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
});

class ChaptersListButton extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        theme: PropTypes.object.isRequired,
        isLoading: PropTypes.bool.isRequired,
        isLoadingSuccessful: PropTypes.bool.isRequired,
        chaptersList: PropTypes.array.isRequired,
        selectedChapterNumber: PropTypes.string.isRequired,
        onHandleChapterClick: PropTypes.func.isRequired,
        onHandleChapterClose: PropTypes.func.isRequired,
        onHandleChapterMenuItemClick: PropTypes.func.isRequired
    }
    render() {
        const { classes } = this.props;
        const { isLoading, isLoadingSuccessful, chaptersList, selectedChapterNumber } = this.props; 
        const { anchorChapterEl, onHandleChapterClick, onHandleChapterClose, onHandleChapterMenuItemClick } = this.props
        
        return (
            <div className={classes.wrapper}>
                <Button
                    aria-owns={anchorChapterEl ? 'chapters-menu' : undefined}
                    aria-haspopup="true"
                    color="inherit"
                    disabled={isLoading || !isLoadingSuccessful}                    
                    onClick={onHandleChapterClick}                                        
                >
                    {selectedChapterNumber}
                </Button>
                {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                <Menu
                    id="chapters-menu"
                    anchorEl={anchorChapterEl}
                    open={Boolean(anchorChapterEl)}
                    onClose={onHandleChapterClose}
                >
                    {chaptersList.map(chapter => (
                        <MenuItem key={chapter.chapterLangId} 
                            onClick={event => onHandleChapterMenuItemClick(event, chapter.chapterId)}>
                                {chapter.chapterNumber}
                        </MenuItem>
                        ))}                                       
                </Menu>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(ChaptersListButton);