import { take, put } from 'redux-saga/effects';
import { entityListActions } from '../../../common/entities/actions';
import * as entitiesNames from '../../../common/entities/constants';
import { verseOfTheDayActions } from '../actions';
import { getCurrentDate } from '../../../utils/dateHelper';

export const watchGetVerseOfTheDay = function* () {
    const { GET_ENTITY_LIST } = entityListActions;
    const { GET_PERSONAL_VERSEOFTHEDAY_FROM_STORAGE } = verseOfTheDayActions;    

    while(true) {
        const {bibleVersionId} = yield take(verseOfTheDayActions.GET_VERSEOFTHEDAY);
        yield put(entityListActions.getEntityList.request(entitiesNames.VERSEOFTHEDAY, {versionId: bibleVersionId}));                
        
        const action = yield take([GET_ENTITY_LIST(entitiesNames.VERSEOFTHEDAY).SUCCESS, GET_ENTITY_LIST(entitiesNames.VERSEOFTHEDAY).FAILURE]);
        if (action.type === GET_ENTITY_LIST(entitiesNames.VERSEOFTHEDAY).SUCCESS) {
            let currentDate = getCurrentDate();
            yield put(verseOfTheDayActions.loadPersonalVerseOfTheDayFromStorage(bibleVersionId, currentDate));

            const action = yield take([GET_PERSONAL_VERSEOFTHEDAY_FROM_STORAGE.SUCCESS, GET_PERSONAL_VERSEOFTHEDAY_FROM_STORAGE.FAILURE]);
            if (action.type === GET_PERSONAL_VERSEOFTHEDAY_FROM_STORAGE.FAILURE) {
                yield put(verseOfTheDayActions.generateVerseOfTheDay.request(currentDate));                
            }                       
        }
    }
}