import * as actions from '../../../../actions/actionRequestTypes';

const { createRequestTypes, action } = actions;

export const SUBMIT_LOGIN_REQUEST = 'SUBMIT_LOGIN_REQUEST';
export const submitLoginRequest = (encodedCredentials, shouldRememberUser) => action(SUBMIT_LOGIN_REQUEST, {encodedCredentials, shouldRememberUser});

export const LOGIN = createRequestTypes('LOGIN');
export const login = {
    request: () => action(LOGIN.REQUEST),
    success: (id, response) => action(LOGIN.SUCCESS, {response}),
    failure: (id, error) => action(LOGIN.FAILURE, {error})
}