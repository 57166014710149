import { indexedDb } from '../../../../services/indexedDb';
import _ from 'lodash';
import * as _saveViewFiltersBibleVersionToLocalStoreDb from './saveViewFiltersBibleVersionToLocalStoreDb';
import * as _fetchViewFiltersBibleVersionFromStorageService from './fetchViewFiltersBibleVersionFromStorageService';

export const saveViewFiltersBibleVersionToLocalStoreDb = _saveViewFiltersBibleVersionToLocalStoreDb;
export const fetchViewFiltersBibleVersionFromStorageService = _fetchViewFiltersBibleVersionFromStorageService;

export const addBibleVersionsToLocalStore = (bibleVersions) => {
    const bibleVersionsList = _.values(bibleVersions);
    indexedDb.bibleVersions.bulkPut(bibleVersionsList).then(function(lastKey) {
    });
}

export const loadBibleVersionsFromLocalStore = () => {
    return indexedDb.bibleVersions.toArray();
}