import { take, call, fork, put, select } from 'redux-saga/effects';
import { getBookChaptersFromStorageActions, bibleChaptersFromApiActions } from '../actions';
import { fetchChaptersByBookIdAndLangIdFromStorageService } from '../indexedDbStorage';
import { fetchEntity } from '../../../../sagas/sagaHelper';
import { DEFAULT_LANGID } from '../../Versions/constants';

const getBibleVersion = state => state.bibleVersion;

// bind Generators
const fetchBookChaptersFromStorage = fetchEntity.bind(null, getBookChaptersFromStorageActions.getBookChaptersFromStorage, fetchChaptersByBookIdAndLangIdFromStorageService.fetchChaptersByBookIdAndLangIdFromStorage);

function* loadBookChapters(bibleVersionId, bookId) {
    let bibleVersion = yield select(getBibleVersion);
    
    const langId = bibleVersion ? bibleVersion.langId : DEFAULT_LANGID;

    yield call(fetchBookChaptersFromStorage, langId, bookId);    
}

export const watchLoadChaptersFromStorage = function* () {
    const { GET_BOOK_CHAPTERS_FROM_STORAGE } = getBookChaptersFromStorageActions;
    
    while(true) {
        const {bibleVersionId, bookId} = yield take(getBookChaptersFromStorageActions.LOAD_BOOK_CHAPTERS_FROM_STORAGE);
        yield fork(loadBookChapters, bibleVersionId, bookId);
        
        const action = yield take([
            GET_BOOK_CHAPTERS_FROM_STORAGE.SUCCESS, 
            GET_BOOK_CHAPTERS_FROM_STORAGE.FAILURE
        ]);
        
        if (action.type === GET_BOOK_CHAPTERS_FROM_STORAGE.FAILURE) {
            yield put(bibleChaptersFromApiActions.loadBibleChaptersFromApi(bibleVersionId));
            //yield put(getBookChaptersFromApiActions.loadBookChaptersFromApi(bibleVersionId, bookId));
        }        
    }
}