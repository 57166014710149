import { take, call, fork } from 'redux-saga/effects';
import { updateUserInfoActions } from '../actions';
import { fetchSubmitUpdateUserInfoRequestApi } from '../api/updateUserInfoApi';
import { fetchEntity } from '../../../../sagas/sagaHelper';

// bind Generators
const fetchSubmitUpdateUserInfoRequest = fetchEntity.bind(null, updateUserInfoActions.updateUserInfo, fetchSubmitUpdateUserInfoRequestApi);

function* submitUpdateUserInfoRequest(userId, modifiedUser) {
    yield call(fetchSubmitUpdateUserInfoRequest, userId, modifiedUser);
}

export const watchSubmitUpdateUserInfoRequest = function* () {
    const { UPDATE_USER_INFO } = updateUserInfoActions;

    while(true) {
        const {userId, modifiedUser} = yield take(updateUserInfoActions.SUBMIT_UPDATE_USER_INFO_REQUEST);
        yield fork(submitUpdateUserInfoRequest, userId, modifiedUser);

        const action = yield take([UPDATE_USER_INFO.SUCCESS, UPDATE_USER_INFO.FAILURE]);
        if (action.type === UPDATE_USER_INFO.SUCCESS) {
            window.history.back();
        }
    }
}