import { createSelector } from 'reselect';
import { getVerseOfTheDayListState } from './getVerseOfTheDayListSelector';
import { getCurrentDate } from '../../../utils/dateHelper';

const getSelectedVerseOfTheDayState = state => state.verseOfTheDay;

export const getSelectedVerseOfTheDay = createSelector(
    [getVerseOfTheDayListState, getSelectedVerseOfTheDayState],
    (verseOfTheDayList, selectedVerseOfTheDay) => {
        var verseOfTheDay;
        let currentDate = getCurrentDate();

        if (selectedVerseOfTheDay && selectedVerseOfTheDay.date === currentDate) {
            const verseVersionId = selectedVerseOfTheDay.verseVersionId;
            verseOfTheDay = verseOfTheDayList.find(verse => `${verse.versionId}-${verse.verseId}` === verseVersionId);
        }
        
        return verseOfTheDay;
    }
)