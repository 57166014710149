import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Verse from './Verse';
import MessageBanner from '../../../common/components/MessageBanner';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '30px 20px',
    },
    wrapper: {        
      position: 'relative',
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
}));

const VersesPanel = props => {
    const classes = useStyles();

    const { shouldDisplayDownloadBibleOptions, shouldDisplayAddToVerseOfTheDayListIcon, shouldStyleVerseOfTheDay,
            isLoading, bibleVersionId, versesList, selectedBibleBookName, onHandleDownloadBookClick } = props
    const downloadBookButtonCaption = `Download ${selectedBibleBookName}`;
    //const downloadBibleButtonCaption = `Download Bible`;

    return (
        <div className={classes.wrapper} style={{ direction: ((bibleVersionId === 1) ? 'rtl' : 'ltr') }}>
            {(shouldDisplayDownloadBibleOptions
                ? <MessageBanner bannerMessage='Download bible book(s) for improved performance and offline experience.'
                    buttonCaption={downloadBookButtonCaption} onHandleClose={onHandleDownloadBookClick} />
                : <span />
            )}
            <Paper className={classes.root}>
                <Typography variant="body1" component="div">
                    {versesList.map(verse => (
                        <Verse 
                            key={verse.verseVersionId} 
                            verse={verse} 
                            shouldDisplayAddToVerseOfTheDayListIcon={shouldDisplayAddToVerseOfTheDayListIcon}
                            shouldStyleVerseOfTheDay={shouldStyleVerseOfTheDay}
                        />                        
                    ))}
                </Typography>                
            </Paper>
            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
    );
}

VersesPanel.propTypes = {
    shouldDisplayDownloadBibleOptions: PropTypes.bool.isRequired,
    shouldDisplayAddToVerseOfTheDayListIcon: PropTypes.bool.isRequired,
    shouldStyleVerseOfTheDay: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isLoadingSuccessful: PropTypes.bool.isRequired,
    versesList: PropTypes.array.isRequired,
    bibleVersionId: PropTypes.number.isRequired,
    selectedBibleBookName: PropTypes.string.isRequired,
    onHandleDownloadBookClick: PropTypes.func.isRequired,
    onHandleDownloadBibleClick: PropTypes.func.isRequired
}

export default VersesPanel;