import { indexedDb } from '../../../services/indexedDb';

const loadPersonalVerseOfTheDayFromLocalStore = (date) => {
    return indexedDb.personalVerseOfTheDay.get(date);
}

const loadVerseOfTheDay = (bibleVersionId, verseId) => {
    return indexedDb.verseOfTheDayList.get({verseId: verseId, versionId: bibleVersionId});
}

export const fetchPersonalVerseOfTheDayByDateFromStorage = (bibleVersionId, date) => {
    return loadPersonalVerseOfTheDayFromLocalStore(date)
        .then(personalVerseOfTheDay => {
            if (personalVerseOfTheDay) {
                return loadVerseOfTheDay(bibleVersionId, personalVerseOfTheDay.verseId)
            } else {
            }
        })
        .then(verseOfTheDay => {
            if (verseOfTheDay) {       
                const response = { verseOfTheDay };
                return { ...response, date }
            } else {
            }
        })
        .catch(error => {
        })
        .then(
            response => ({ response }),
            error => ({ error: error.message })
        );         
}