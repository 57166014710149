import { take, fork, call } from 'redux-saga/effects';
//import { entityByName } from '../metadata';
import { entityListStorageActions } from '../actions';
import { fetchEntityListFromStorageService } from '../indexedDbStorage';
import { fetchEntity } from '../../../sagas/sagaHelper';

// bind Generators
const fetchEntityListFromStorage = fetchEntity.bind(null, entityListStorageActions.getEntityListFromStorage, fetchEntityListFromStorageService.fetchEntityListFromStorage);

function* loadEntities(entityName, filter) {
    yield call(fetchEntityListFromStorage, entityName, filter);    
}

export const watchLoadEntityListFromStorage = function* (entityName) {
    while(true) {
        const {type, ...filter} = yield take(entityListStorageActions.LOAD_ENTITY_LIST_FROM_STORAGE(entityName));
        yield fork(loadEntities, entityName, filter);      
    }
}