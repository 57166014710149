import * as actions from '../../../actions/actionRequestTypes';

const { createRequestTypes, action } = actions;

export const GET_LASTADDED_ENTITY_ACTION_TYPE = entityName => `GET_LASTADDED_${entityName}`;
export const getLastAddedEntity = (entityName) => {
    return action(GET_LASTADDED_ENTITY_ACTION_TYPE(entityName))
};

export const GET_LASTADDED_ENTITY_FROM_STORAGE = entityName => createRequestTypes(`${GET_LASTADDED_ENTITY_ACTION_TYPE(entityName)}_FROM_STORAGE`);
export const getLastAddedEntityFromStorage = {
    request: (entityName) => action(GET_LASTADDED_ENTITY_FROM_STORAGE(entityName).REQUEST),
    success: (entityName, response) => action(GET_LASTADDED_ENTITY_FROM_STORAGE(entityName).SUCCESS, {response}),
    failure: (entityName, error) => action(GET_LASTADDED_ENTITY_FROM_STORAGE(entityName).FAILURE, {error})
}