import * as actions from '../../../../actions/actionRequestTypes';

const { createRequestTypes, action } = actions;

export const SUBMIT_CHANGE_PASSWORD_REQUEST = 'SUBMIT_CHANGE_PASSWORD_REQUEST';
export const submitChangePasswordRequest = encodedCredentials => action(SUBMIT_CHANGE_PASSWORD_REQUEST, {encodedCredentials});

export const CHANGE_PASSWORD = createRequestTypes('CHANGE_PASSWORD');
export const changePassword = {
    request: () => action(CHANGE_PASSWORD.REQUEST),
    success: (id, response) => action(CHANGE_PASSWORD.SUCCESS, {response}),
    failure: (id, error) => action(CHANGE_PASSWORD.FAILURE, {error})
}