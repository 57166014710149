import React from 'react';
import { Typography } from '@material-ui/core';

const LoginHeader = () => {
    return (
        <Typography variant="h6" color="inherit" >
            Forgot Password
        </Typography>
    );
}

export default LoginHeader;