import { take, call, fork } from 'redux-saga/effects';
import { UPDATE_APP_VERSION_STORAGE } from '../actions/appVersionFromStorageActions';
import { saveAppVersionToLocalStoreDb } from '../indexedDbStorage';

function* updateLocalStorage(appVersion) {
    yield call(saveAppVersionToLocalStoreDb.saveAppVersionToLocalStore, appVersion);
}

export const watchUpdateAppVersionLocalStorage = function* () {
    while(true) {
        const {appVersion} = yield take(UPDATE_APP_VERSION_STORAGE);
        yield fork(updateLocalStorage, appVersion);
    }
}