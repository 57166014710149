import { schema, normalize } from 'normalizr';
import merge from 'lodash/merge';
import _ from 'lodash';
import * as ActionTypes from '../actions';
import { bookVersesFromApiActions } from '../features/Bible/Verses/actions';
import { addEntityActions, entityListActions } from '../common/entities/actions';
import { HIGHLIGHTEDVERSES } from '../common/entities/constants';
import { combineReducers } from 'redux';
import { systemSettings } from '../features/Settings/System/reducer';
import { settings } from '../features/Version/reducer';
import { appStatus } from '../features/Home/reducer';
import { loggedinUser } from '../features/auth/reducer';
import { bibleVersion } from '../features/Bible/Versions/reducer';
import { bibleBook } from '../features/Bible/Books/reducer';
import { bookChapter } from '../features/Bible/Chapters/reducer';
import { chapterVerses } from '../features/Bible/Verses/reducer';
import { verseOfTheDay } from '../features/VerseOfTheDay/reducer';
import { bibleHistory } from '../features/BibleHistory/reducer';
import { ALERT_TYPE_SUCCESS, ALERT_TYPE_ERROR } from '../constants';
import { viewFilters } from './viewFilters';

const { ADD_ENTITY_STORAGE } = addEntityActions;
const { GET_ENTITY_LIST } = entityListActions;

// Updates an entity cache in response to any action with response.entities.
const entities= (state = { bibleVersions:{}, books: {}, chapters: {}, verses: {}, verseOfTheDay: {} }, action) => {
    if (action.response && action.response.entities) {
        if (action.response.entities.verseOfTheDay) {
            const verseOfTheDayEntities = _.values(action.response.entities.verseOfTheDay);
            let vodVerses = verseOfTheDayEntities.map( vodVrs => {
                let verse = {verseId: vodVrs.verseId, versionId: vodVrs.versionId, isVerseOfTheDay: true};
                if (vodVrs.verseText) {
                    verse = {...verse, verseOfTheDayText: vodVrs.verseText};
                }

                return verse;
            });

            const vodVerseSchema = new schema.Entity('verses', {}, {idAttribute: 'verseId'});
            const vodVerseSchemaArray = [ vodVerseSchema ];
            let normalizedvod = normalize(vodVerses, vodVerseSchemaArray);
            normalizedvod = {...normalizedvod};                        
            state = merge({}, state, normalizedvod.entities);

            return {
                ...state,
                verseOfTheDay: action.response.entities.verseOfTheDay
            }
        }
        else {
            return merge({}, state, action.response.entities);
        }        
    }

    const { type, response } = action;    

    switch (type) {
        case ADD_ENTITY_STORAGE(HIGHLIGHTEDVERSES).SUCCESS:            
            let verse = {verseId: response.verseId, versionId: response.versionId, isHighlighted: true};
            if (response.highlightedText) {
                verse = {...verse, highlightedText: response.highlightedText};                
            }
            
            let normalizedVerse = {[verse.verseId]: verse}
            const { verses } = state;                        
            const result = merge(verses, normalizedVerse)
            
            return {
                ...state,
                verses: result
            }
        case GET_ENTITY_LIST(HIGHLIGHTEDVERSES).SUCCESS:
            if (response && response.length > 0) {
                let highlightedVerses = response.map(highlightedVrs => {
                    let verse = { verseId: highlightedVrs.verseId, versionId: highlightedVrs.versionId, isHighlighted: true };
                    if (highlightedVrs.highlightedText) {
                        verse = { ...verse, highlightedText: highlightedVrs.highlightedText };
                    }

                    return verse;
                });

                const highlightedVerseSchema = new schema.Entity('verses', {}, { idAttribute: 'verseId' });
                const highlightedVerseSchemaArray = [highlightedVerseSchema];
                let normalized = normalize(highlightedVerses, highlightedVerseSchemaArray);
                normalized = { ...normalized };
                return merge({}, state, normalized.entities);
            } else return state;
        
        default:
            return state;
    }
}

const alertMessage = (state = null, action) => {    
    const { type, error } = action

    switch (type) {
        case ActionTypes.RESET_ALERT_MESSAGE:
            return null;
        case ActionTypes.SET_ALERT_MESSAGE:
            return {
                ...state,
                alertType: action.alertType,
                alertText: action.alertText
            }
        case bookVersesFromApiActions.GET_BOOK_VERSES_FROM_API.SUCCESS:
            return {
                ...state,
                alertType: ALERT_TYPE_SUCCESS,
                alertText: 'Verses saved to local storage successfully!'
            }        
        default:
            if (error) {
                let errorMessage = 'Error encountered while processing request';
                if (typeof error === 'string') {
                    errorMessage = error;
                }
                return {
                    ...state,
                    alertType: ALERT_TYPE_ERROR,
                    alertText: errorMessage
                }
            }

            return state;
    }
}

const logMessages = (state = [], action) => {
    const { type } = action;

    switch (type) {
        case ActionTypes.RESET_LOG_MESSAGES:
            return [];
        case ActionTypes.ADD_LOG_MESSAGE:
                return [
                    ...state, action.msg 
                  ];            
        default:
            return state;
    }
}

const rootReducer = combineReducers({
    systemSettings,
    settings,
    entities,
    appStatus,
    loggedinUser,
    viewFilters,
    bibleVersion,
    bibleBook,
    bookChapter,
    chapterVerses,
    verseOfTheDay,
    bibleHistory,    
    alertMessage,
    logMessages,
})

export default rootReducer