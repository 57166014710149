import { take, fork, select, put } from 'redux-saga/effects';
import _ from 'lodash';
import { LOAD_BIBLE_VERSIONS, getBibleVersionsFromStorageActions } from '../actions';

const getBibleVersions = (state) => state.entities.bibleVersions;

function* loadBibleVersions() {
    let bibleVersions = yield select(getBibleVersions);

    if (_.isEmpty(bibleVersions)) {                
        yield put(getBibleVersionsFromStorageActions.loadBibleVersionsFromStorage());
    }
}

export const watchLoadBibleVersions = function* () {
    while(true) {
        yield take(LOAD_BIBLE_VERSIONS);
        yield fork(loadBibleVersions);
    }
}