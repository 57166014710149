import { take, fork, call, put, select } from 'redux-saga/effects';
import { entityByName } from '../metadata';
import { lastUpdatedEntityListSettingStorageActions, entityListApiActions } from '../actions';
import { fetchLastUpdatedEntityListSettingFromStorageService } from '../indexedDbStorage';
import { fetchEntity } from '../../../sagas/sagaHelper';

// bind Generators
const fetchLastUpdatedEntityListSettingFromStorage = fetchEntity.bind(null, lastUpdatedEntityListSettingStorageActions.getLastUpdatedEntityListSettingFromStorage, fetchLastUpdatedEntityListSettingFromStorageService.fetchLastUpdatedEntityListSettingFromStorage);

const getVersionId = state => state.bibleVersion.id;

function* loadLastUpdatedEntityListSetting(entityName) {
    const entity = entityByName(entityName);
    let versionId = entity.filterByVersionId ? yield select(getVersionId) : null;
    yield call(fetchLastUpdatedEntityListSettingFromStorage, entityName, versionId);    
}

export const watchLoadLastUpdatedEntityListSettingFromStorage = function* (entityName) {
    const { GET_ENTITY_LIST_LASTUPDATED_SETTING_FROM_STORAGE } = lastUpdatedEntityListSettingStorageActions;

    while(true) {
        const {type, ...filter} = yield take(lastUpdatedEntityListSettingStorageActions.LOAD_ENTITY_LIST_LASTUPDATED_SETTING_FROM_STORAGE(entityName));
        yield fork(loadLastUpdatedEntityListSetting, entityName);
        
        const action = yield take([
            GET_ENTITY_LIST_LASTUPDATED_SETTING_FROM_STORAGE(entityName).SUCCESS, 
            GET_ENTITY_LIST_LASTUPDATED_SETTING_FROM_STORAGE(entityName).FAILURE
        ]);

        if (action.type === GET_ENTITY_LIST_LASTUPDATED_SETTING_FROM_STORAGE(entityName).SUCCESS) {
            const lastUpdatedEntityListSetting = action.response.lastUpdatedSetting;
            let lastUpdatedUtcTime = lastUpdatedEntityListSetting.lastUpdatedUtcTime;
            lastUpdatedUtcTime = lastUpdatedUtcTime.substr(0, 19);
            yield put(entityListApiActions.loadUpdatedEntityListFromApi(entityName, filter, lastUpdatedUtcTime));           
        } else if (action.type === GET_ENTITY_LIST_LASTUPDATED_SETTING_FROM_STORAGE(entityName).FAILURE) {
            yield put(entityListApiActions.loadEntityListFromApi(entityName, filter));            
        }
    }
}