import { take, call, fork } from 'redux-saga/effects';
import { signedInUsersStorageActions } from '../actions';
import { saveUserToStorageService } from '../indexedDbStorage';

function* updateStorage(userId, displayName, encodedToken) {
    yield call(saveUserToStorageService.saveUserToStorage, userId, displayName, encodedToken);
}

export const watchSaveUserToStorage = function* () {
    while(true) {
        const {loggedInUser} = yield take(signedInUsersStorageActions.SAVE_USER_TO_STORAGE);
        if (loggedInUser) {
            yield fork(updateStorage, loggedInUser.userId, loggedInUser.displayName, loggedInUser.encodedToken);
        }        
    }
}