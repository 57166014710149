import { take, call, fork, select, put } from 'redux-saga/effects';
import { getBooksFromApiActions, updateBooksStorage } from '../actions';
import { fetchBibleBooksApi } from '../api';
import { fetchEntity } from '../../../../sagas/sagaHelper';

// bind Generators
const fetchBibleBooks = fetchEntity.bind(null, getBooksFromApiActions.getBibleBooksFromApi, fetchBibleBooksApi);

const getApiUrl = state => state.systemSettings.apiUrl;

function* loadBibleBooks(bibleVersionId) {
    let apiUrl = yield select(getApiUrl);
    
    yield call(fetchBibleBooks, apiUrl, bibleVersionId);
}

export const watchLoadBibleBooksFromApi = function* () {
    const { GET_BIBLE_BOOKS_FROM_API } = getBooksFromApiActions;

    while(true) {
        const {bibleVersionId} = yield take(getBooksFromApiActions.LOAD_BIBLE_BOOKS_FROM_API);        
        yield fork(loadBibleBooks, bibleVersionId);

        const action = yield take([GET_BIBLE_BOOKS_FROM_API.SUCCESS, GET_BIBLE_BOOKS_FROM_API.FAILURE]);
        if (action.type === GET_BIBLE_BOOKS_FROM_API.SUCCESS) {
            const books = action.response.entities.books;
            yield put(updateBooksStorage(books));
        }
    }
}