import * as actions from '../../../actions/actionRequestTypes';

const { createRequestTypes, action } = actions;

export const ADD_ENTITY_ACTION_TYPE = entityName => `ADD_${entityName}`;
export const addEntity = (entityName, payload = {}) => {
    const obj = {}
    return action(ADD_ENTITY_ACTION_TYPE(entityName), {...obj, payload})
};

export const ADD_ENTITY_STORAGE = entityName => createRequestTypes(`${ADD_ENTITY_ACTION_TYPE(entityName)}_STORAGE`);
export const addEntityStorage = {
    request: (entityName, payload) => action(ADD_ENTITY_STORAGE(entityName).REQUEST, payload),
    success: (entityName, response) => action(ADD_ENTITY_STORAGE(entityName).SUCCESS, {response}),
    failure: (entityName, error) => action(ADD_ENTITY_STORAGE(entityName).FAILURE, {error})
}

export const ADD_ENTITY_API = entityName => createRequestTypes(`${ADD_ENTITY_ACTION_TYPE(entityName)}_API`);
export const addEntityApi = {
    request: (entityName, payload) => action(ADD_ENTITY_API(entityName).REQUEST, payload),
    success: (entityName, response) => action(ADD_ENTITY_API(entityName).SUCCESS, {response}),
    failure: (entityName, error) => action(ADD_ENTITY_API(entityName).FAILURE, {error})
}