import * as actions from '../../../actions/actionRequestTypes';
import { VERSEOFTHEDAY } from '../../../common/entities/constants';

const { createRequestTypes, action } = actions;

export const LOAD_VERSEOFTHEDAY_LIST_FROM_STORAGE = `LOAD_${VERSEOFTHEDAY}_LIST_FROM_STORAGE`;
export const loadVerseOfTheDayListFromStorage = (bibleVersionId) => action(LOAD_VERSEOFTHEDAY_LIST_FROM_STORAGE, {bibleVersionId});

export const GET_VERSEOFTHEDAY_LIST_FROM_STORAGE = createRequestTypes(`GET_${VERSEOFTHEDAY}_LIST_FROM_STORAGE`);
export const getVerseOfTheDayListFromStorage = {
    request: () => action(GET_VERSEOFTHEDAY_LIST_FROM_STORAGE.REQUEST),
    success: (id, response) => action(GET_VERSEOFTHEDAY_LIST_FROM_STORAGE.SUCCESS, {response}),
    failure: (id, error) => action(GET_VERSEOFTHEDAY_LIST_FROM_STORAGE.FAILURE, {error})
}

export const SAVE_VERSEOFTHEDAY_LIST_TO_STORAGE = `SAVE_${VERSEOFTHEDAY}_LIST_TO_STORAGE`;
export const saveVerseOfTheDayListToStorage = (verses, currentUtcTime) => action(SAVE_VERSEOFTHEDAY_LIST_TO_STORAGE, {verses, currentUtcTime});

export const GET_LASTUPDATED_VERSEOFTHEDAY_LIST_SETTING_FROM_STORAGE = createRequestTypes(`GET_LASTUPDATED_${VERSEOFTHEDAY}_LIST_SETTING_FROM_STORAGE`);

export const getLastUpdatedVerseOfTheDayListSettingFromStorage = {
    request: () => action(GET_LASTUPDATED_VERSEOFTHEDAY_LIST_SETTING_FROM_STORAGE.REQUEST),
    success: (id, response) => action(GET_LASTUPDATED_VERSEOFTHEDAY_LIST_SETTING_FROM_STORAGE.SUCCESS, {response}),
    failure: (id, error) => action(GET_LASTUPDATED_VERSEOFTHEDAY_LIST_SETTING_FROM_STORAGE.FAILURE, {error})
}