import { take, call, fork } from 'redux-saga/effects';
import { viewFiltersBibleBookFromStorageActions } from '../actions';
import { saveViewFiltersBibleBookToLocalStoreDb } from '../indexedDbStorage';

function* updateLocalStorage(bookId) {
    yield call(saveViewFiltersBibleBookToLocalStoreDb.saveViewFiltersBibleBookToLocalStore, bookId);
}

export const watchUpdateViewFiltersBibleBookLocalStorage = function* () {
    while(true) {
        const {bookId} = yield take(viewFiltersBibleBookFromStorageActions.UPDATE_VIEWFILTERS_BIBLEBOOK_STORAGE);
        yield fork(updateLocalStorage, bookId);
    }
}