import { fork, all } from 'redux-saga/effects';
import { watchLoadBibleBooks } from './getBooksSaga';
import { watchLoadBibleBooksFromStorage } from './getBooksFromStorageSaga';
import { watchLoadBibleBooksFromApi } from './getBooksFromApiSaga';
import { watchUpdateBibleBooksLocalStorage } from './updateBooksLocalStorageSaga';
import { watchSetBibleBook } from './setBibleBookSaga';
import { watchUpdateViewFiltersBibleBookLocalStorage } from './updateViewFiltersBibleBookLocalStoreSaga';
import { watchLoadViewFiltersBibleBookFromStorage } from './getViewFiltersBibleBookFromStorageSaga';
import { watchChaptersRequests } from '../../Chapters/sagas';
import { watchVersesRequests } from '../../Verses/sagas';

export const watchBibleBooksRequests = function*() {
    yield all([
        fork(watchLoadBibleBooks),
        fork(watchLoadBibleBooksFromStorage),
        fork(watchLoadBibleBooksFromApi),
        fork(watchUpdateBibleBooksLocalStorage),
        fork(watchSetBibleBook),
        fork(watchUpdateViewFiltersBibleBookLocalStorage),
        fork(watchLoadViewFiltersBibleBookFromStorage),
        fork(watchChaptersRequests),
        fork(watchVersesRequests)
    ])
}