import { createSelector } from 'reselect';
import _ from 'lodash';

const getBooks = state => _.values(state.entities.books);

export const getBibleBooks = createSelector(
    [getBooks],
    (bibleBooks) => {
        return bibleBooks;
    }
)