import React from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SvgIcon from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core/styles';
import * as entitiesNames from '../../../common/entities/constants';

const useStyles = makeStyles(theme => ({
    root: {
      '& svg': {
        margin: theme.spacing(0, 1),
      },      
    },
  }));

function AddToVerseOfTheDayListIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d='M19 19V8H5V19H19M16 1H18V3H19C20.11 3 21 3.9 21 5V19C21 20.11 20.11 21 19 21H5C3.89 21 3 20.1 3 19V5C3 3.89 3.89 3 5 3H6V1H8V3H16V1M11 9.5H13V12.5H16V14.5H13V17.5H11V14.5H8V12.5H11V9.5Z' />
        </SvgIcon>
    );
}

function AddToFavoriteListIcon(props) {
    return (
        <SvgIcon {...props}>
            <path fill='#ff0000' d='M10.5,2H13.5V8H19V11H13.5V22H10.5V11H5V8H10.5V2Z' />
        </SvgIcon>
    );
}

function HighlightIcon(props) {
    return (
        <SvgIcon {...props}>
            <path fill='#d4e83d' d='M18.5,1.15C17.97,1.15 17.46,1.34 17.07,1.73L11.26,7.55L16.91,13.2L22.73,7.39C23.5,6.61 23.5,5.35 22.73,4.56L19.89,1.73C19.5,1.34 19,1.15 18.5,1.15M10.3,8.5L4.34,14.46C3.56,15.24 3.56,16.5 4.36,17.31C3.14,18.54 1.9,19.77 0.67,21H6.33L7.19,20.14C7.97,20.9 9.22,20.89 10,20.12L15.95,14.16' />
        </SvgIcon>
    );
}

const VerseMenu = props => {
    const classes = useStyles();

    const { menuAnchorEl, shouldDisplayAddToVerseOfTheDayListIcon, userId, chapterId, verseId, versionId, selectedText, onAddEntity, onHandleMenuClose } = props;

    const menuOpen = Boolean(menuAnchorEl);
    const menuId = menuOpen ? 'verse-menu' : undefined;

    const menuCaption = selectedText !== '' ? `"${selectedText}"` : 'full verse';

    const handleAddToVerseOfTheDayList = () => {               
        let newFields = {chapterId: chapterId, verseId: verseId, versionId: versionId};
        
        if (selectedText !== "") {
            newFields = {...newFields, selectedText: selectedText}
        }
        
        onAddEntity(entitiesNames.VERSEOFTHEDAY, newFields);
        onHandleMenuClose();
    }

    const handleAddToFavoriteVerses = () => {
        let newFields = {VerseId: verseId};
        if (selectedText !== "") {
            switch (versionId) {
                case 1:
                    newFields = {...newFields, VersionId: versionId, UncVerseText: selectedText};
                    break;
                default:
                    newFields = {...newFields, VersionId: versionId, VerseText: selectedText}
            }            
        }
        onAddEntity(entitiesNames.FAVORITEVERSES, newFields);
        onHandleMenuClose();
    }

    const handleAddToHighlightedVerses = () => {
        let newFields = {chapterId: chapterId, verseId: verseId, versionId: versionId};

        if (selectedText !== "") {
            newFields = {...newFields, highlightedText: selectedText}
        }

        if (userId) {
            newFields = {...newFields, userId: userId}
        }

        onAddEntity(entitiesNames.HIGHLIGHTEDVERSES, newFields);
        onHandleMenuClose();
    }

    return (
        <Menu
            id={menuId}
            anchorEl={menuAnchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}          
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            open={menuOpen}
            onClose={onHandleMenuClose}
            className={classes.root}
        >
            {shouldDisplayAddToVerseOfTheDayListIcon ? <MenuItem onClick={handleAddToVerseOfTheDayList} style={{whiteSpace: 'normal'}}><AddToVerseOfTheDayListIcon />Add {menuCaption} to Verse of the Day list</MenuItem> : ' '}            
            {/* <MenuItem onClick={handleAddToFavoriteVerses} style={{whiteSpace: 'normal'}}><AddToFavoriteListIcon />Add {menuCaption} to Favorites</MenuItem> */} 
            <MenuItem onClick={handleAddToHighlightedVerses} style={{whiteSpace: 'normal'}}><HighlightIcon />Highlight&nbsp;<mark>{menuCaption}</mark></MenuItem>                   
        </Menu>
    );
}

VerseMenu.propTypes = {
    shouldDisplayAddToVerseOfTheDayListIcon: PropTypes.bool.isRequired,
    chapterId: PropTypes.number.isRequired,
    verseId: PropTypes.number.isRequired,
    versionId: PropTypes.number.isRequired,
    onAddEntity: PropTypes.func.isRequired,
    onHandleMenuClose: PropTypes.func.isRequired,    
}

export default VerseMenu;