import { createSelector } from 'reselect';
import _ from 'lodash';

const getVerseOfTheDayList = state => _.values(state.entities.verseOfTheDay);

export const getVerseOfTheDayListState = createSelector(
    [getVerseOfTheDayList],
    (verseOfTheDayList) => {
        return verseOfTheDayList;
    }
)