import { take, fork, call, put } from 'redux-saga/effects';
import _ from 'lodash';
import { autoSignInActions, signedInUsersStorageActions } from '../actions';
import { fetchSubmitAutoSignInRequestApi } from '../api/autoSignInApi';
import { deleteUserFromStorageService } from '../../SignOut/indexedDbStorage';
import { fetchEntity } from '../../../../sagas/sagaHelper';
import { setToken } from '../../../../services/api';
import { getCurrentDateTimeLt } from '../../../../utils/dateHelper';

// bind Generators
const fetchSubmitAutoSignInRequest = fetchEntity.bind(null, autoSignInActions.autoSignIn, fetchSubmitAutoSignInRequestApi);

const { AUTOSIGNIN } = autoSignInActions;

function* submitAutoSignInRequest(userId) {
    const loginDateTimeLt = getCurrentDateTimeLt();        
    yield fork(fetchSubmitAutoSignInRequest, JSON.stringify({currentDateTimeLt: loginDateTimeLt}));

    const action = yield take([AUTOSIGNIN.SUCCESS, AUTOSIGNIN.FAILURE]);
    if (action.type === AUTOSIGNIN.SUCCESS) {
        yield put(signedInUsersStorageActions.saveUserToStorage(action.response))
    } else if (action.type === AUTOSIGNIN.FAILURE) {
        yield call(deleteUserFromStorageService.deleteUserFromStorage, userId);
        setToken(null);                
    }
}

export const watchSubmitAutoSignInRequest = function* () {
    const { GET_SIGNEDIN_USERS_FROM_STORAGE } = signedInUsersStorageActions;

    while(true) {
        yield take(autoSignInActions.SUBMIT_AUTOSIGNIN_REQUEST);
        yield put(signedInUsersStorageActions.loadSignedInUsersFromStorage());

        const action = yield take([GET_SIGNEDIN_USERS_FROM_STORAGE.SUCCESS, GET_SIGNEDIN_USERS_FROM_STORAGE.FAILURE]);
        if (action.type === GET_SIGNEDIN_USERS_FROM_STORAGE.SUCCESS) {
            const signedInUsers = _.values(action.response);
            if (signedInUsers.length === 1) {
                const signedInUser = signedInUsers[0];
                setToken(signedInUser.token);
                yield fork(submitAutoSignInRequest, signedInUser.userId);
            } else {                
            }
        }
    }
}