import React from 'react';
import classNames from "classnames";
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from "@material-ui/core/Icon";
import styles from '../../assets/jss/components/drawerStyle';
import { activeRoute } from '../../utils/routesHelper';

const useStyles = makeStyles(styles);

const DrawerListItem = props => {
    const classes = useStyles();
    const { routePathname, itemCaption } = props;
    const ItemIcon = props.itemIcon;

    const activeMenuColor = 'green';

    return (
        <NavLink to={routePathname} className={classes.item} key={itemCaption} activeClassName="active">
            <ListItem button className={classNames(classes.itemLink, { [classes[activeMenuColor]]: activeRoute(routePathname) })}>
                <Icon className={classNames(classes.itemIcon, classes.whiteFont)}>
                    <ItemIcon />
                </Icon>
                <ListItemText
                    className={classNames(classes.itemText, classes.whiteFont)}
                    primary={itemCaption}
                    disableTypography={true}
                />
            </ListItem>
        </NavLink>
    )
}

export default DrawerListItem;