export const ROUTES_HOME_PATHNAME = '/';
export const ROUTES_LOGIN_PATHNAME = '/login';
export const ROUTES_REGISTER_PATHNAME = '/register';
export const ROUTES_UPDATE_USER_INFO_PATHNAME = '/updateUserInfo';
export const ROUTES_CHANGE_PASSWORD_PATHNAME = '/changePassword';
export const ROUTES_FORGOTPASSWORD_PATHNAME = '/forgotPassword';
export const ROUTES_VERSEOFTHEDAY_PATHNAME = '/verseoftheday';
export const ROUTES_BIBLE_PATHNAME = '/bible';
export const ROUTES_READINGHISTORY_PATHNAME = '/readingHistory';
export const ROUTES_FAVORITEVERSES_PATHNAME = '/favoriteVerses';
export const ROUTES_TOPICS_PATHNAME = '/topics';
export const ROUTES_SETTINGS_PATHNAME = '/settings';
export const ROUTES_FEEDBACK_PATHNAME = '/feedback';
export const ROUTES_REPORT_DEFECT_PATHNAME = '/reportDefect';
export const ROUTES_COPYRIGHTS_PATHNAME = '/copyrights';
export const ROUTES_RELEASENOTES_PATHNAME = '/releaseNotes';

export const ALERT_TYPE_ERROR = 'error';
export const ALERT_TYPE_INFO = 'info';
export const ALERT_TYPE_SUCCESS = 'success';
export const ALERT_TYPE_WARNING = 'warning';