import { createSelector } from 'reselect';
import { getChaptersByBookId } from './getChaptersByBookIdSelector';

const getSelectedBibleVersion = state => state.bibleVersion;

export const getChaptersByBookIdAndBibleVersion = createSelector(
    [getChaptersByBookId, getSelectedBibleVersion],
    (chaptersByBookId, selectedBibleVersion) => {
        const filteredChapters = chaptersByBookId.filter(chapter => chapter.langId === selectedBibleVersion.langId);        
        return filteredChapters;
    }
)