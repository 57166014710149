import { call  } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import * as entitiesNames from '../../common/entities/constants';
import { getVerseOfTheDayListFromApi } from './entity/getVerseOfTheDayListFromApi';
import { verseOfTheDayApiSchemaArray, verseOfTheDayStorageSchemaArray } from './schema';

export const verseOfTheDay = {
    entityName: entitiesNames.VERSEOFTHEDAY,
    storageTableName: 'verseOfTheDayList',
    apiTableName: 'VerseOfTheDay',
    filterByVersionId: true,    
    shouldNormalizeApiResponse: true,
    apiSchemaArray: verseOfTheDayApiSchemaArray,
    addApiData(payload) {
        return getApiDataToAdd(payload)
    },
    addToStoragePayload(payload) { 
        const verseVersionId = payload.versionId ? `${payload.versionId}-${payload.verseId}` : `0-${payload.verseId}`;
        return {...{'verseVersionId': verseVersionId}, ...payload}
    },
    getLoadedEntities(entities) {
        const normalized = normalize(entities, verseOfTheDayStorageSchemaArray);
        return {...normalized}
        //return entities;
    },
    getEntityListFilterForApi(filter, userId, lastUpdatedDateTimeUtc) {
        if (lastUpdatedDateTimeUtc) {
            filter = {...filter, modifiedDateTimeUtc: lastUpdatedDateTimeUtc }
        }
            
        return filter;
    },
    getEntityListFromApi: function*(filter) { yield call(getVerseOfTheDayListFromApi, filter) },
    getEntityListFromApiResponse(apiResponse) {
        return apiResponse.entities.verseOfTheDay;
    }     
}

const getApiDataToAdd = data => {
    let newFields = {verseId: data.verseId};
    if (data.selectedText) {
        switch (data.versionId) {
            case 1:
                newFields = { ...newFields, versionId: data.versionId, uncVerseText: data.selectedText };
                break;
            default:
                newFields = { ...newFields, versionId: data.versionId, verseText: data.selectedText }
        }
    }
    return newFields;
}