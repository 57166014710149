import React, { useState, useEffect } from 'react'
import Home from '../../features/Home'

const App = (props) => {
    const [pageLoaded, setPageLoaded] = useState(false);

    const initializeLoadingPage = () => {
        props.history.listen(() => {
            if (window.swUpdateReady) {
                window.swUpdateReady = false;
                console.log('swUpdateReady=false');
                window.stop();
                window.location.reload();
            }
        });

        setPageLoaded(true);
    }

    useEffect(() => {
        if (!pageLoaded) {
            initializeLoadingPage();            
        }
    })

    return (
        <div>
            <Home />
        </div>
    );
}

export default App