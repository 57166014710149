import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    root: {
      flexGrow: 1,
    },
    grow: {
      flexGrow: 1,
    },
  };

const TopicsHeader = (props) => {
    const { classes } = props;
    return (
        <div className={classes.root}>
            <Typography variant="h6" color="inherit" noWrap className={classes.grow}>
                Topics
          </Typography>
        </div>
    );
}

TopicsHeader.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withStyles(styles)(TopicsHeader);