import * as _saveVerseOfTheDayListToStorageService from './saveVerseOfTheDayListToStorageService';
import * as _fetchVerseOfTheDayListFromStorageService from './fetchVerseOfTheDayListFromStorageService';
import * as _fetchPersonalVerseOfTheDayByDateFromStorageService from './fetchPersonalVerseOfTheDayByDateFromStorageService';
import * as _savePersonalVerseOfTheDayToStorageService from './savePersonalVerseOfTheDayToStorageService';
import * as _fetchLastUpdatedVerseOfTheDayListSettingFromStorageService from './fetchLastUpdatedVerseOfTheDayListSettingFromStorageService';

export const saveVerseOfTheDayListToStorageService = _saveVerseOfTheDayListToStorageService;
export const fetchVerseOfTheDayListFromStorageService = _fetchVerseOfTheDayListFromStorageService;
export const fetchPersonalVerseOfTheDayByDateFromStorageService = _fetchPersonalVerseOfTheDayByDateFromStorageService;
export const savePersonalVerseOfTheDayToStorageService = _savePersonalVerseOfTheDayToStorageService;
export const fetchLastUpdatedVerseOfTheDayListSettingFromStorageService = _fetchLastUpdatedVerseOfTheDayListSettingFromStorageService;