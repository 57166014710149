import { fork, all, put } from 'redux-saga/effects';
import { watchSubmitLoginRequest } from '../Login/sagas/loginSaga';
import { watchSubmitAutoSignInRequest } from '../Login/sagas/autoSignInSaga';
import { watchLoadSignedInUsersFromStorage } from '../Login/sagas/getSignedInUsersFromStorageSaga';
import { watchSaveUserToStorage } from '../Login/sagas/saveUserToStorageSaga';
import { watchSubmitRegisterRequest } from '../Register/sagas/registerSaga';
import { watchSubmitUpdateUserInfoRequest } from '../UpdateUserInfo/sagas/updateUserInfoSaga';
import { watchSignOutRequest } from '../SignOut/sagas/signOutSaga';
import { watchSubmitChangePasswordRequest } from '../ChangePassword/sagas/changePasswordSaga';
import { watchSubmitForgotPasswordRequest } from '../ForgotPassword/sagas/forgotPasswordSaga';
import { autoSignInActions } from '../Login/actions';

const { submitAutoSignInRequest } = autoSignInActions;

export const watchAuthRequests = function*() {
    yield all([
        fork(watchSubmitLoginRequest),
        fork(watchSubmitAutoSignInRequest),
        fork(watchSubmitRegisterRequest),
        fork(watchSubmitUpdateUserInfoRequest), 
        fork(watchSignOutRequest),
        fork(watchLoadSignedInUsersFromStorage),
        fork(watchSaveUserToStorage),
        fork(watchSubmitChangePasswordRequest),
        fork(watchSubmitForgotPasswordRequest),
        put(submitAutoSignInRequest()), 
    ])
}