import { take, call, fork, select, put } from 'redux-saga/effects';
import { getBibleVersionsFromApiActions, updateBibleVersionsStorage } from '../actions';
import { fetchBibleVersionsApi } from '../api'
import { fetchEntity } from '../../../../sagas/sagaHelper';

// bind Generators
const fetchBibleVersions = fetchEntity.bind(null, getBibleVersionsFromApiActions.getBibleVersionsFromApi, fetchBibleVersionsApi);

const getApiUrl = state => state.systemSettings.apiUrl;

function* loadBibleVersions() {
    let apiUrl = yield select(getApiUrl);
    yield call(fetchBibleVersions, apiUrl);
}

export const watchLoadBibleVersionsFromApi = function* () {
    const { GET_BIBLE_VERSIONS_FROM_API } = getBibleVersionsFromApiActions;

    while(true) {
        yield take(getBibleVersionsFromApiActions.LOAD_BIBLE_VERSIONS_FROM_API);
        yield fork(loadBibleVersions);

        const action = yield take([GET_BIBLE_VERSIONS_FROM_API.SUCCESS, GET_BIBLE_VERSIONS_FROM_API.FAILURE]);
        if (action.type === GET_BIBLE_VERSIONS_FROM_API.SUCCESS) {
            const bibleVersions = action.response.entities.bibleVersions;
            yield put(updateBibleVersionsStorage(bibleVersions));
        }
    }
}