import { callApiNormalizedResponse } from '../../../services/api';
import { chaptersResourceUrl } from '../Chapters/bookChaptersApi';
import { verseApiSchemaArray } from './schema';

export const VERSES_RESOURCE = 'verses';
export const chapterVersesResourceUrl = (apiUrl, bookId, chapterId) => `${chaptersResourceUrl(apiUrl, bookId)}/${chapterId}/${VERSES_RESOURCE}`;
const resourceUrl = (apiUrl, bibleVersionId, bookId, chapterId) => { return `${chapterVersesResourceUrl(apiUrl, bookId, chapterId)}?versionId=${bibleVersionId}` }

// verses api services
export const fetchChapterVersesApi = (apiUrl, bibleVersionId, bookId, chapterId) => callApiNormalizedResponse(resourceUrl(apiUrl, bibleVersionId, bookId, chapterId), verseApiSchemaArray);
