import React from 'react';
import classNames from "classnames";
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
//import EventNoteIcon from '@material-ui/icons/EventNote';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import CopyrightIcon from '@material-ui/icons/Copyright';
import DescriptionIcon from '@material-ui/icons/Description';
//import AssignmentIcon from '@material-ui/icons/Assignment';
import * as Routes from '../../constants';
import styles from '../../assets/jss/components/drawerStyle';

import SvgIcon from '@material-ui/core/SvgIcon';
import DrawerListItem from './DrawerListItem';

function VerseOfTheDayIcon(props) {
    return (
        <SvgIcon {...props}>
            <path  d='M14,14H7V16H14M19,19H5V8H19M19,3H18V1H16V3H8V1H6V3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3M17,10H7V12H17V10Z' />
        </SvgIcon>
    );
}

function ReadingHistoryIcon(props) {
    return (
        <SvgIcon {...props}>
            <path  d='M19 1L14 6V17L19 12.5V1M21 5V18.5C19.9 18.15 18.7 18 17.5 18C15.8 18 13.35 18.65 12 19.5V6C10.55 4.9 8.45 4.5 6.5 4.5C4.55 4.5 2.45 4.9 1 6V20.65C1 20.9 1.25 21.15 1.5 21.15C1.6 21.15 1.65 21.1 1.75 21.1C3.1 20.45 5.05 20 6.5 20C8.45 20 10.55 20.4 12 21.5C13.35 20.65 15.8 20 17.5 20C19.15 20 20.85 20.3 22.25 21.05C22.35 21.1 22.4 21.1 22.5 21.1C22.75 21.1 23 20.85 23 20.6V6C22.4 5.55 21.75 5.25 21 5M10 18.41C8.75 18.09 7.5 18 6.5 18C5.44 18 4.18 18.19 3 18.5V7.13C3.91 6.73 5.14 6.5 6.5 6.5C7.86 6.5 9.09 6.73 10 7.13V18.41Z' />
        </SvgIcon>
    );
}

function FeedbackIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M9,22A1,1 0 0,1 8,21V18H4A2,2 0 0,1 2,16V4C2,2.89 2.9,2 4,2H20A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H13.9L10.2,21.71C10,21.9 9.75,22 9.5,22V22H9M10,16V19.08L13.08,16H20V4H4V16H10M6,7H18V9H6V7M6,11H15V13H6V11Z" />
        </SvgIcon>
    );
}

const useStyles = makeStyles(styles);

const DrawerList = props => {
    const classes = useStyles();
    const [selectedRoute, setSelectedRoute] = React.useState(null);

    const handleMenuItemClick = (event, path) => {
        setSelectedRoute(path);
    };

    /* const activeRoute = routeName => {
        return window.location.href.indexOf(routeName) > -1 ? true : false;
    } */

    const activeMenuColor = 'green';

    return (
        <List className={classes.list}>
            <DrawerListItem routePathname={Routes.ROUTES_VERSEOFTHEDAY_PATHNAME} itemCaption='Verse of the Day' itemIcon={VerseOfTheDayIcon} />
            <DrawerListItem routePathname={Routes.ROUTES_BIBLE_PATHNAME} itemCaption='Bible' itemIcon={MenuBookIcon} />
            <DrawerListItem routePathname={Routes.ROUTES_READINGHISTORY_PATHNAME} itemCaption='Reading History' itemIcon={ReadingHistoryIcon} />            
            <DrawerListItem routePathname={Routes.ROUTES_COPYRIGHTS_PATHNAME} itemCaption='Copyrights' itemIcon={CopyrightIcon} />
            <DrawerListItem routePathname={Routes.ROUTES_RELEASENOTES_PATHNAME} itemCaption='Release Notes' itemIcon={DescriptionIcon} />

            <ListItem className={classNames(classes.versionInfoItem, classes.item)}>
                <ListItemText className={classNames(classes.itemText, classes.whiteFont)} primary={`Version ${process.env.REACT_APP_VERSION}`} disableTypography={true} />
            </ListItem>

            {/* <ListItem button key='Settings' component={Link} to={Routes.ROUTES_SETTINGS_PATHNAME}>
                        <ListItemIcon><MailIcon /></ListItemIcon>
                        <ListItemText primary='Settings' />
                    </ListItem> */}
            {/* <ListItem button key='Feedback' component={Link} to={Routes.ROUTES_FEEDBACK_PATHNAME}>
                        <ListItemIcon><FeedbackIcon /></ListItemIcon>
                        <ListItemText primary='Feedback' />
                    </ListItem> */}
            {/* <ListItem button key='Report a defect' component={Link} to={Routes.ROUTES_REPORT_DEFECT_PATHNAME}>
                        <ListItemIcon><FeedbackIcon className={classes.defect} /></ListItemIcon>
                        <ListItemText primary='Report a defect' />
                    </ListItem> */}
        </List>
    )
}

export default DrawerList;