import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',   
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    }
}));

const ForgotPasswordPanel = props => {
    const classes = useStyles();
    const [ usernameFocused, setUsernameFocused] = useState(false);
    const { isProcessing, username } = props;
    const { onUsernameChange, onSubmitClick } = props;

    let usernameInput;

    const setFocus = () => {
        usernameInput.focus();
        setUsernameFocused(true);
    }

    useEffect(() => {
        if (!usernameFocused) {
            setFocus();
        }        
    })

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Password Recovery
                </Typography>
                <form className={classes.form} onSubmit={onSubmitClick} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="username-input"
                        label="Username"
                        name="username-input"
                        autoComplete="username-input"
                        autoFocus={true}
                        inputRef={ input => {usernameInput = input;} }                      
                        value={username}
                        onChange={onUsernameChange}
                    />                    
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={isProcessing}
                        type="submit" 
                    >
                        Submit
                    </Button>
                </form>
            </div>
            <Box mt={3}>
                <Typography variant="body2" color="textSecondary">
                    Please provide your username.<br /> 
                    An email with a link to recover your password will be sent to the email address registered in your account. If you can't find the email, please check your junk email folder.
                </Typography>                
            </Box>
        </Container>
    );
}

ForgotPasswordPanel.propTypes = {
    username: PropTypes.string.isRequired,
    onUsernameChange: PropTypes.func.isRequired,
    onSubmitClick: PropTypes.func.isRequired
}

export default ForgotPasswordPanel;