import * as actions from '../../../../actions/actionRequestTypes';

const { createRequestTypes, action } = actions;

export const SUBMIT_UPDATE_USER_INFO_REQUEST = 'SUBMIT_UPDATE_USER_INFO_REQUEST';
export const submitUpdateUserInfoRequest = (userId, modifiedUser) => action(SUBMIT_UPDATE_USER_INFO_REQUEST, {userId, modifiedUser});

export const UPDATE_USER_INFO = createRequestTypes('UPDATE_USER_INFO');
export const updateUserInfo = {
    request: () => action(UPDATE_USER_INFO.REQUEST),
    success: (id, response) => action(UPDATE_USER_INFO.SUCCESS, {response}),
    failure: (id, error) => action(UPDATE_USER_INFO.FAILURE, {error})
}