import { take, put, select } from 'redux-saga/effects';
import * as booksActions from '../../Books/actions';
import * as chaptersActions from '../actions';
import { getBibleBooks } from '../../Books/selectors/getBibleBooksSelector';
import { getChaptersByBookId } from '../selectors/getChaptersByBookIdSelector';

const getBibleBook = state => state.bibleBook;
const getBookChapter = state => state.bookChapter;
const getChaptersForCurrentBookId = state => getChaptersByBookId(state);
const getBooks = state => getBibleBooks(state);

export const watchRequestNextChapter = function* () {
    while(true) {
        yield take(chaptersActions.REQUEST_NEXT_CHAPTER);
        let bibleBook = yield select(getBibleBook);
        let bookChapter = yield select(getBookChapter);
        const bibleBooks = yield select(getBooks);

        let chaptersForCurrentBookId = yield select(getChaptersForCurrentBookId);
        const lastChapterForCurrentBookId = chaptersForCurrentBookId[chaptersForCurrentBookId.length - 1];

        let nextChapterId = bookChapter.id;

        if (bookChapter.id < lastChapterForCurrentBookId.chapterId) {
            nextChapterId = bookChapter.id + 1;
        } else if (bibleBook.id < bibleBooks[bibleBooks.length - 1].bookId) {
            yield put(booksActions.setBibleBook(bibleBook.id + 1));                        
        }
        
        if (nextChapterId !== bookChapter.id) {
            yield put(chaptersActions.setBookChapter(nextChapterId));
        }        
    }
}