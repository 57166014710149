import { normalize } from 'normalizr';
import { verseStorageSchemaArray } from '../schema';
import { loadVersesByChapterIdAndVersionIdFromLocalStore } from './loadVersesByChapterIdAndVersionIdFromLocalStoreDb';

export const fetchVersesByChapterIdAndVersionIdFromStorage = (versionId, chapterId) => {
    return loadVersesByChapterIdAndVersionIdFromLocalStore(versionId, chapterId)
        .then(verses => {        
            if (verses.length > 0) {
                const normalized = normalize(verses, verseStorageSchemaArray);
                return {...normalized}
            }
            else {
                //throw new Error('No verses found in local storage!');
            }
        })
        .catch(error => {
            //throw error;
        })
        .then(
            response => ({ response }),
            error => ({ error: error.message })
        );         
}