import * as actions from '../../../../actions/actionRequestTypes';

const { createRequestTypes, action } = actions;

export const SUBMIT_FORGOT_PASSWORD_REQUEST = 'SUBMIT_FORGOT_PASSWORD_REQUEST';
export const submitForgotPasswordRequest = (encodedCredentials) => action(SUBMIT_FORGOT_PASSWORD_REQUEST, {encodedCredentials});

export const FORGOT_PASSWORD = createRequestTypes('FORGOT_PASSWORD');
export const forgotPassword = {
    request: () => action(FORGOT_PASSWORD.REQUEST),
    success: (id, response) => action(FORGOT_PASSWORD.SUCCESS, {response}),
    failure: (id, error) => action(FORGOT_PASSWORD.FAILURE, {error})
}