import { take, call, fork, all, put, select } from 'redux-saga/effects';
import * as settingsSystemActions from './actions';
import { fetchSystemSettingsRequest } from './api';
import { fetchEntity } from '../../../sagas/sagaHelper';
import { setApiUrl } from '../../../services/apiResources';
import { watchAppVersionRequests } from '../../Version/sagas';
import { watchBibleVersionsRequests } from '../../Bible/Versions/sagas';
import { watchBibleBooksRequests } from '../../Bible/Books/sagas';
import { watchVerseOfTheDayRequests } from '../../VerseOfTheDay/sagas';
import { watchAuthRequests } from '../../auth/sagas';
import { watchEntitiesRequests } from '../../../common/entities/sagas';
import { watchBibleHistoryRequests } from '../../BibleHistory/sagas';
import * as appVersionActions from '../../Version/actions';
import * as bibleVersionsActions from '../../Bible/Versions/actions';
import * as bibleBooksActions from '../../Bible/Books/actions';
import * as bookChaptersActions from '../../Bible/Chapters/actions';
//import { verseOfTheDayActions } from '../../VerseOfTheDay/actions';
//import { ROUTES_BIBLE_PATHNAME } from '../../../constants';
import * as entitiesNames from '../../../common/entities/constants';
import { initializeEntities } from '../../../common/entities/metadata';

const { getViewFiltersBibleVersionFromStorageActions } = bibleVersionsActions;
const { viewFiltersBibleBookFromStorageActions } = bibleBooksActions;
const { viewFiltersBookChapterFromStorageActions } = bookChaptersActions;

const getBibleVersion = state => state.bibleVersion;

// bind Generators
const fetchSystemSettings = fetchEntity.bind(null, settingsSystemActions.getSettings, fetchSystemSettingsRequest);

function* loadSystemSettings() {
    initializeEntities();

    yield call(fetchSystemSettings);
}

function* setBibleVersion() {
    const { GET_BIBLE_VERSIONS_FROM_STORAGE } = bibleVersionsActions.getBibleVersionsFromStorageActions;
    const { GET_BIBLE_VERSIONS_FROM_API } = bibleVersionsActions.getBibleVersionsFromApiActions;

    const action = yield take([
        GET_BIBLE_VERSIONS_FROM_STORAGE.SUCCESS, 
        GET_BIBLE_VERSIONS_FROM_API.SUCCESS
    ]);

    if ((action.type === GET_BIBLE_VERSIONS_FROM_STORAGE.SUCCESS) || (action.type === GET_BIBLE_VERSIONS_FROM_API.SUCCESS)) {
        let bibleVersion = yield select(getBibleVersion);
        //if (window.location.pathname === ROUTES_BIBLE_PATHNAME) {        
        yield put(bibleVersionsActions.setBibleVersion(bibleVersion.id, bibleVersion.langId));
        //}                     
    }
}

export const watchLoadSystemSettingsRequest = function* () {
    while(true) {
        yield take(settingsSystemActions.LOAD_SETTINGS_REQUEST);
        yield fork(loadSystemSettings);        

        const action = yield take([settingsSystemActions.GET_SETTINGS.SUCCESS]); 
        if (action.type === settingsSystemActions.GET_SETTINGS.SUCCESS) {
            yield call(setApiUrl, action.response.apiUrl);
            yield all([
                fork(watchEntitiesRequests, entitiesNames.VERSEOFTHEDAY),
                fork(watchEntitiesRequests, entitiesNames.FAVORITEVERSES),
                fork(watchEntitiesRequests, entitiesNames.HIGHLIGHTEDVERSES),
                fork(watchEntitiesRequests, entitiesNames.BIBLEHISTORY),
                fork(watchAuthRequests),
                fork(watchAppVersionRequests),
                put(appVersionActions.validateAppVersion()),
                fork(watchBibleVersionsRequests),
                fork(watchBibleBooksRequests),
                fork(watchBibleHistoryRequests),
                put(getViewFiltersBibleVersionFromStorageActions.loadViewFiltersBibleVersionFromStorage()),
                put(viewFiltersBibleBookFromStorageActions.loadViewFiltersBibleBookFromStorage()),
                put(viewFiltersBookChapterFromStorageActions.loadViewFiltersBookChapterFromStorage()),
                put(bibleVersionsActions.loadBibleVersions()),
                fork(setBibleVersion),                
                fork(watchVerseOfTheDayRequests)                                
            ]);
        }
    }
}