import * as actions from '../../../../actions/actionRequestTypes';

const { createRequestTypes, action } = actions;

export const LOAD_VIEWFILTERS_BIBLEVERSION_FROM_STORAGE = 'LOAD_VIEWFILTERS_BIBLEVERSION_FROM_STORAGE';
export const loadViewFiltersBibleVersionFromStorage = () => action(LOAD_VIEWFILTERS_BIBLEVERSION_FROM_STORAGE);

export const GET_VIEWFILTERS_BIBLEVERSION_FROM_STORAGE = createRequestTypes('GET_VIEWFILTERS_BIBLEVERSION_FROM_STORAGE');
export const getViewFiltersBibleVersionFromStorage = {
    request: () => action(GET_VIEWFILTERS_BIBLEVERSION_FROM_STORAGE.REQUEST),
    success: (id, response) => action(GET_VIEWFILTERS_BIBLEVERSION_FROM_STORAGE.SUCCESS, {response}),
    failure: (id, error) => action(GET_VIEWFILTERS_BIBLEVERSION_FROM_STORAGE.FAILURE, {error})
}