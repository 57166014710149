import { take, call, fork } from 'redux-saga/effects';
import { viewFiltersBibleBookFromStorageActions } from '../actions';
import { fetchViewFiltersBibleBookFromStorageService } from '../indexedDbStorage';
import { fetchEntity } from '../../../../sagas/sagaHelper';

// bind Generators
const fetchViewFiltersBibleBookFromStorage = fetchEntity.bind(null, viewFiltersBibleBookFromStorageActions.getViewFiltersBibleBookFromStorage, fetchViewFiltersBibleBookFromStorageService.fetchViewFiltersBibleBookFromStorage);

function* loadViewFiltersBibleBook() {
    yield call(fetchViewFiltersBibleBookFromStorage);    
}

export const watchLoadViewFiltersBibleBookFromStorage = function* () {
    while(true) {
        yield take(viewFiltersBibleBookFromStorageActions.LOAD_VIEWFILTERS_BIBLEBOOK_FROM_STORAGE);
        yield fork(loadViewFiltersBibleBook); 
    }
}