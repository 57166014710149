import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import RegisterPanel from './Panel';
import { registerActions } from './actions';
import { setAlertMessage } from '../../../actions';
import { ALERT_TYPE_ERROR } from '../../../constants';
import { getCurrentDateTimeLt } from '../../../utils/dateHelper';

const { submitRegisterRequest } = registerActions;

const Register = (props) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [email, setEmail] = useState('');
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('');

    const { setAlertMessage, submitRegisterRequest } = props;

    const isFieldPopulated = (value) => {
        return value !== '';
    }
    
    const isMatchingPasswords = (value1, value2) => {
        if (value1 !== value2) {
            setPasswordErrorMessage('Password mismatch');
            return false;
        }

        return true;
    }

    const isFilledPassword = (value1) => {
        if (value1 === '') {
            setPasswordErrorMessage('Password cannot be empty');
            return false;
        }

        return true;
    }

    const validatePassword = (value1, value2) => {
        if (isFilledPassword(value1) && isMatchingPasswords(value1, value2)) {
            setPasswordErrorMessage('');
        }
    }

    const handleUsernameChange = event => {
        setUsername(event.target.value);
    };

    const handlePasswordChange = event => {
        setPassword(event.target.value);
        validatePassword(event.target.value, confirmPassword);
    };

    const handleConfirmPasswordChange = event => {
        setConfirmPassword(event.target.value);
        validatePassword(password, event.target.value);
    };

    const handleFirstnameChange = event => {
        setFirstname(event.target.value);
    };

    const handleLastnameChange = event => {
        setLastname(event.target.value);
    };

    const handleDisplayNameChange = event => {
        setDisplayName(event.target.value);
    };

    const handleEmailChange = event => {
        setEmail(event.target.value);
    };
    
    const handleSubmitRegisterClick = (event) => {
        event.preventDefault();
        if (isValidRequiredFields()) {
            let encodedCredentials = window.btoa(username + ':' + password);
            const currentDateTimeLt = getCurrentDateTimeLt();
            submitRegisterRequest(JSON.stringify({
                EncodedCredentials: encodedCredentials,
                CurrentDateTimeLt: currentDateTimeLt,
                Firstname: firstname,
                Lastname: lastname,
                DisplayName: displayName,
                Email: email
            }));
        }
    }

    const isValidPassword = () => {
        return (passwordErrorMessage === '');
    }

    const isValidUsername = () => {
        if (!isFieldPopulated(username)) {
            setAlertMessage(ALERT_TYPE_ERROR, 'Username cannot be empty');
            return false;
        }

        return true;
    }

    const isValidFirstname = () => {
        if (!isFieldPopulated(firstname)) {
            setAlertMessage(ALERT_TYPE_ERROR, 'First name cannot be empty');
            return false;
        }

        return true;
    }

    const isValidDisplayname = () => {
        if (!isFieldPopulated(displayName)) {
            setAlertMessage(ALERT_TYPE_ERROR, 'Display name cannot be empty');
            return false;
        }

        return true;
    }

    const isValidEmail = () => {        
        var mailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if (!email.match(mailFormat)) {
            setAlertMessage(ALERT_TYPE_ERROR, 'Invalid Email');
            return false;
        }

        return true;
    }

    const isValidRequiredFields = () => {        
        if (!isFilledPassword(password) || !isValidPassword()) {
            var errorMsg = passwordErrorMessage !== '' ? passwordErrorMessage : 'Password cannot be empty'
            setAlertMessage(ALERT_TYPE_ERROR, errorMsg);
            return false;
        }        

        return isValidUsername()
            && isValidFirstname()
            && isValidDisplayname()
            && isValidEmail();
    }

    return (
        <RegisterPanel isProcessing={props.isProcessing} 
            username={username}
            password={password}
            confirmPassword={confirmPassword}
            firstname={firstname}
            lastname={lastname}
            displayName={displayName}
            email={email}
            isValidPassword={isValidPassword}
            onUsernameChange={handleUsernameChange}
            onPasswordChange={handlePasswordChange}
            onConfirmPasswordChange={handleConfirmPasswordChange}
            onFirstnameChange={handleFirstnameChange}
            onLastnameChange={handleLastnameChange}
            onDisplayNameChange={handleDisplayNameChange}
            onEmailChange={handleEmailChange}
            onSubmitRegisterClick={handleSubmitRegisterClick} />
    );
}

Register.propTypes = {
    isProcessing: PropTypes.bool.isRequired,
    setAlertMessage: PropTypes.func.isRequired,
    submitRegisterRequest: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    const {
        appStatus 
    } = state;

    const isProcessing = appStatus.isProcessing;
    return { 
        isProcessing
    }
}

export default withRouter(connect(mapStateToProps, {
    setAlertMessage,
    submitRegisterRequest    
})(Register));