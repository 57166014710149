import { take, call, fork } from 'redux-saga/effects';
import { viewFiltersBookChapterFromStorageActions } from '../actions';
import { fetchViewFiltersBookChapterFromStorageService } from '../indexedDbStorage';
import { fetchEntity } from '../../../../sagas/sagaHelper';

// bind Generators
const fetchViewFiltersBookChapterFromStorage = fetchEntity.bind(null, 
    viewFiltersBookChapterFromStorageActions.getViewFiltersBookChapterFromStorage, 
    fetchViewFiltersBookChapterFromStorageService.fetchViewFiltersBookChapterFromStorage);

function* loadViewFiltersBookChapter() {
    yield call(fetchViewFiltersBookChapterFromStorage);    
}

export const watchLoadViewFiltersBookChapterFromStorage = function* () {
    while(true) {
        yield take(viewFiltersBookChapterFromStorageActions.LOAD_VIEWFILTERS_BOOKCHAPTER_FROM_STORAGE);
        yield fork(loadViewFiltersBookChapter); 
    }
}