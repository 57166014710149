import * as actions from '../../../../actions/actionRequestTypes';

const { createRequestTypes, action } = actions;

export const LOAD_SIGNEDIN_USERS_FROM_STORAGE = 'LOAD_SIGNEDIN_USERS_FROM_STORAGE';
export const loadSignedInUsersFromStorage = () => action(LOAD_SIGNEDIN_USERS_FROM_STORAGE);

export const GET_SIGNEDIN_USERS_FROM_STORAGE = createRequestTypes('GET_SIGNEDIN_USERS_FROM_STORAGE');
export const getSignedInUsersFromStorage = {
    request: () => action(GET_SIGNEDIN_USERS_FROM_STORAGE.REQUEST),
    success: (id, response) => action(GET_SIGNEDIN_USERS_FROM_STORAGE.SUCCESS, {response}),
    failure: (id, error) => action(GET_SIGNEDIN_USERS_FROM_STORAGE.FAILURE, {error})
}

export const SAVE_USER_TO_STORAGE = 'SAVE_USER_TO_STORAGE';
export const saveUserToStorage = (loggedInUser) => action(SAVE_USER_TO_STORAGE, {loggedInUser});