import { take, put, select } from 'redux-saga/effects';
import * as chaptersActions from '../actions';
//import * as versesActions from '../../Verses/actions';
import { chapterVersesActions } from '../../Verses/actions';

const getBibleVersion = state => state.bibleVersion;
const getBibleBook = state => state.bibleBook;
const getBookChapter = state => state.bookChapter;

export const watchSetChapter = function* () {
    while(true) {
        const {chapterId} = yield take(chaptersActions.SET_BOOK_CHAPTER);
        yield put(chaptersActions.viewFiltersBookChapterFromStorageActions.updateViewFiltersBookChapterStorage(chapterId));
        
        let bibleVersion = yield select(getBibleVersion);
        let bibleBook = yield select(getBibleBook);
        let bookChapter = yield select(getBookChapter);
        yield put(chapterVersesActions.getChapterVerses.request(bibleVersion.id, bibleBook.id, bookChapter.id));
    }
}