import { indexedDb } from '../../../../services/indexedDb';
import { VIEWFILTERS_BIBLEBOOK_NAME } from '../../constants';

const loadViewFiltersBibleBookFromLocalStore = () => {
    return indexedDb.viewFilters.get(VIEWFILTERS_BIBLEBOOK_NAME);        
}

export const fetchViewFiltersBibleBookFromStorage = () => {
    return loadViewFiltersBibleBookFromLocalStore()
        .then(bibleBook => {
            if (bibleBook) {       
                const response = {};
                return { ...response, bibleBook }
            } else {
            }
        })
        .catch(error => {
        })
        .then(
            response => ({ response }),
            error => ({ error: error.message })
        );         
}