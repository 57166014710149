import { indexedDb } from '../../../../services/indexedDb';
import { VIEWFILTERS_BIBLEVERSION_NAME } from '../../constants';

const loadViewFiltersBibleVersionFromLocalStore = () => {
    return indexedDb.viewFilters.get(VIEWFILTERS_BIBLEVERSION_NAME);        
}

export const fetchViewFiltersBibleVersionFromStorage = () => {
    return loadViewFiltersBibleVersionFromLocalStore()
        .then(bibleVersion => {
            if (bibleVersion) {        
                const response = {};
                return { ...response, bibleVersion }
            } else {
            }
        })
        .catch(error => {
        })
        .then(
            response => ({ response }),
            error => ({ error: error.message })
        );         
}