import { take, fork, call, put, select } from 'redux-saga/effects';
import { SYNC_ENTITY_LIST_FROM_THECLOUD, lastUpdatedEntityListSettingStorageActions, entityListApiActions } from '../actions';
import { entityByName } from '../metadata';
import { SETTINGS_LASTUPDATED_ENTITYLIST_NAME } from '../../../features/constants';
import { fetchEntitySettingFromStorageService } from '../indexedDbStorage';
import { fetchEntity } from '../../../sagas/sagaHelper';

// bind Generators
const fetchLastUpdatedEntityListSettingFromStorage = fetchEntity.bind(null, 
    lastUpdatedEntityListSettingStorageActions.getLastUpdatedEntityListSettingFromStorage, 
    fetchEntitySettingFromStorageService.fetchEntitySettingFromStorage);

const getLoggedinUser = state => state.loggedinUser;
const getVersionId = state => state.bibleVersion.id;

function* loadLastUpdatedEntityListSetting(entityName) {
    const entity = entityByName(entityName);
    let versionId = entity.filterByVersionId ? yield select(getVersionId) : null;
    yield call(fetchLastUpdatedEntityListSettingFromStorage, entityName, SETTINGS_LASTUPDATED_ENTITYLIST_NAME(entityName, versionId));    
}

export const watchSyncEntityListFromTheCloud = function* (entityName) {
    const GET_ENTITY_LIST_LASTUPDATED_SETTING_FROM_STORAGE = lastUpdatedEntityListSettingStorageActions.GET_ENTITY_LIST_LASTUPDATED_SETTING_FROM_STORAGE(entityName);
    const entity = entityByName(entityName);

    while(true) {
        const {type, ...filter} = yield take(SYNC_ENTITY_LIST_FROM_THECLOUD(entityName));
        yield fork(loadLastUpdatedEntityListSetting, entityName);

        const action = yield take([
            GET_ENTITY_LIST_LASTUPDATED_SETTING_FROM_STORAGE.SUCCESS, 
            GET_ENTITY_LIST_LASTUPDATED_SETTING_FROM_STORAGE.FAILURE
        ]);

        let loggedinUser = yield select(getLoggedinUser);
        let lastUpdatedDateTimeUtc = null;
        if (action.type === GET_ENTITY_LIST_LASTUPDATED_SETTING_FROM_STORAGE.SUCCESS) {
            lastUpdatedDateTimeUtc = action.response.entitySetting.lastUpdatedUtcTime;
            lastUpdatedDateTimeUtc = lastUpdatedDateTimeUtc.substr(0, 19);
        }
        let userId = loggedinUser ? loggedinUser.userId : null;
        yield put(entityListApiActions.loadEntityListFromApi(entityName, entity.getEntityListFilterForApi(filter, userId, lastUpdatedDateTimeUtc)));
    }
}