import { fork, all } from 'redux-saga/effects';
import { watchLoadPersonalVerseOfTheDayFromStorage } from './getPersonalVerseOfTheDayFromStorageSaga';
import { watchGetVerseOfTheDay } from './getVerseOfTheDaySaga';
//import { watchGetVerseOfTheDayList } from './getVerseOfTheDayListSaga';
//import { watchLoadVerseOfTheDayListFromStorage } from './getVerseOfTheDayListFromStorageSaga';
//import { watchLoadVerseOfTheDayListFromApi } from './getVerseOfTheDayListFromApiSaga';
//import { watchLoadUpdatedVerseOfTheDayListFromApi } from './getUpdatedVerseOfTheDayListFromApiSaga';
import { watchSaveVerseOfTheDayListToStorage } from './saveVerseOfTheDayListToStorageSaga';
import { watchGenerateVerseOfTheDay } from './generateVerseOfTheDaySaga';
import { watchSavePersonalVerseOfTheDayToStorage } from './savePersonalVerseOfTheDayToStorageSaga';
import { watchSetVerseOfTheDayVersion } from './setVerseOfTheDayVersionSaga';

export const watchVerseOfTheDayRequests = function*() {
    yield all([
        fork(watchGetVerseOfTheDay),
        //fork(watchGetVerseOfTheDayList),
        //fork(watchLoadVerseOfTheDayListFromStorage),
        //fork(watchLoadVerseOfTheDayListFromApi),
        //fork(watchLoadUpdatedVerseOfTheDayListFromApi),
        fork(watchLoadPersonalVerseOfTheDayFromStorage),
        fork(watchSaveVerseOfTheDayListToStorage),
        fork(watchGenerateVerseOfTheDay),
        fork(watchSavePersonalVerseOfTheDayToStorage),
        fork(watchSetVerseOfTheDayVersion)  
    ])
}