import * as actions from '../../../../actions/actionRequestTypes';

const { createRequestTypes, action } = actions;

export const LOAD_BOOK_VERSES_FROM_API = 'LOAD_BOOK_VERSES_FROM_API';
export const loadBookVersesFromApi = (bibleVersionId, bookId) => action(LOAD_BOOK_VERSES_FROM_API, {bibleVersionId, bookId});

export const GET_BOOK_VERSES_FROM_API = createRequestTypes('GET_BOOK_VERSES_FROM_API');

export const getBookVersesFromApi = {
    request: (bibleVersionId, bookId) => action(GET_BOOK_VERSES_FROM_API.REQUEST, {bibleVersionId, bookId}),
    success: (id, response) => action(GET_BOOK_VERSES_FROM_API.SUCCESS, {response}),
    failure: (id, error) => action(GET_BOOK_VERSES_FROM_API.FAILURE, {error})
}