import { normalize } from 'normalizr';
import { bibleBookStorageSchemaArray } from '../schema';
import { loadBooksFromLocalStore } from './index';

export const fetchBooksFromStorageService = (langId) => {
    return loadBooksFromLocalStore(langId)
        .then(books => {        
            if (books.length > 0) {
                const normalized = normalize(books, bibleBookStorageSchemaArray);
                return {...normalized}
            }
            else {
            }
        })
        .catch(error => {
        })
        .then(
            response => ({ response }),
            error => ({ error: error.message })
        );         
}