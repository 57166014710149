import * as actions from '../../../../actions/actionRequestTypes';

const { createRequestTypes, action } = actions;

export const LOAD_BIBLE_BOOKS_FROM_STORAGE = 'LOAD_BIBLE_BOOKS_FROM_STORAGE';
export const loadBibleBooksFromStorage = (bibleVersionId) => action(LOAD_BIBLE_BOOKS_FROM_STORAGE, {bibleVersionId});

export const GET_BIBLE_BOOKS_FROM_STORAGE = createRequestTypes('GET_BIBLE_BOOKS_FROM_STORAGE');
export const getBibleBooksFromStorage = {
    request: () => action(GET_BIBLE_BOOKS_FROM_STORAGE.REQUEST),
    success: (id, response) => action(GET_BIBLE_BOOKS_FROM_STORAGE.SUCCESS, {response}),
    failure: (id, error) => action(GET_BIBLE_BOOKS_FROM_STORAGE.FAILURE, {error})
}