import { take, call, fork } from 'redux-saga/effects';
import { UPDATE_VIEWFILTERS_BIBLEVERSION_STORAGE } from '../actions';
import { saveViewFiltersBibleVersionToLocalStoreDb } from '../indexedDbStorage';

function* updateLocalStorage(bibleVersionId, langId) {
    yield call(saveViewFiltersBibleVersionToLocalStoreDb.saveViewFiltersBibleVersionToLocalStore, bibleVersionId, langId);
}

export const watchUpdateViewFiltersBibleVersionLocalStorage = function* () {
    while(true) {
        const {bibleVersionId, langId} = yield take(UPDATE_VIEWFILTERS_BIBLEVERSION_STORAGE);
        yield fork(updateLocalStorage, bibleVersionId, langId);
    }
}