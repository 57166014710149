import * as actions from '../../../../actions/actionRequestTypes';

import * as _getBooksFromStorageActions from './getBooksFromStorageActions';
import * as _getBooksFromApiActions from './getBooksFromApiActions';
import * as _viewFiltersBibleBookFromStorageActions from './viewFiltersBibleBookFromStorageActions';

export const getBooksFromStorageActions = _getBooksFromStorageActions;
export const getBooksFromApiActions = _getBooksFromApiActions;
export const viewFiltersBibleBookFromStorageActions = _viewFiltersBibleBookFromStorageActions;

const { action } = actions;

export const LOAD_BIBLE_BOOKS = 'LOAD_BIBLE_BOOKS';
export const loadBibleBooks = (bibleVersionId) => action(LOAD_BIBLE_BOOKS, {bibleVersionId});

export const SET_BIBLE_BOOK = 'SET_BIBLE_BOOK';
export const setBibleBook = (bookId, skipSettingFirstChapter) => action(SET_BIBLE_BOOK, {bookId, skipSettingFirstChapter});

export const UPDATE_BOOKS_STORAGE = 'UPDATE_BOOKS_STORAGE';
export const updateBooksStorage = (books) => action(UPDATE_BOOKS_STORAGE, {books});