import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SignOutMenu from './Menu';
import { signOutActions } from '../SignOut/actions';

const { signOutUser } = signOutActions;

const SignOutAccountMenu = props => {
    const { signedInUserId, signOutAccountAnchorEl, onAccountMenuClose, signOutUser } = props;

    const handleSignOutMenuClick = () => {
        signOutUser(signedInUserId);
        onAccountMenuClose();
    }

    return (
        <SignOutMenu signOutAccountAnchorEl={signOutAccountAnchorEl}
            onAccountMenuClose={onAccountMenuClose}
            onSignOutMenuClick={handleSignOutMenuClick} />
    );
}

SignOutAccountMenu.propTypes = {
    signedInUserId: PropTypes.number.isRequired,
    onAccountMenuClose: PropTypes.func.isRequired,
    signOutUser: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    const {
        loggedinUser
    } = state;
    
    const signedInUserId = loggedinUser ? loggedinUser.userId : 0;

    return {
        signedInUserId
    }
}

export default connect(mapStateToProps, {
    signOutUser    
})(SignOutAccountMenu);