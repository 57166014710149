import { take, call, fork } from 'redux-saga/effects';
import { getViewFiltersBibleVersionFromStorageActions } from '../actions';
import { fetchViewFiltersBibleVersionFromStorageService } from '../indexedDbStorage';
import { fetchEntity } from '../../../../sagas/sagaHelper';

// bind Generators
const fetchViewFiltersBibleVersionFromStorage = fetchEntity.bind(null, getViewFiltersBibleVersionFromStorageActions.getViewFiltersBibleVersionFromStorage, fetchViewFiltersBibleVersionFromStorageService.fetchViewFiltersBibleVersionFromStorage);

function* loadViewFiltersBibleVersion() {
    yield call(fetchViewFiltersBibleVersionFromStorage);    
}

export const watchLoadViewFiltersBibleVersionFromStorage = function* () {
    while(true) {
        yield take(getViewFiltersBibleVersionFromStorageActions.LOAD_VIEWFILTERS_BIBLEVERSION_FROM_STORAGE);
        yield fork(loadViewFiltersBibleVersion); 
    }
}