import { take, put, fork, call, all, select } from 'redux-saga/effects';
import { chapterVersesActions } from '../actions';
import { getChapterVersesFromStorageActions } from '../actions';
import { addEntityActions, entityListActions, getEntityActions } from '../../../../common/entities/actions';
import * as entitiesNames from '../../../../common/entities/constants';
import { getCurrentDateTimeLtISO } from '../../../../utils/dateHelper';
import { activeRoute } from '../../../../utils/routesHelper';
import * as Routes from '../../../../constants';
import { fetchEntity } from '../../../../sagas/sagaHelper';
import { fetchLastAddedEntityFromStorageService } from '../../../../common/entities/indexedDbStorage';

// bind Generators
const fetchLastAddedEntityFromStorage = fetchEntity.bind(null, getEntityActions.getLastAddedEntityFromStorage, fetchLastAddedEntityFromStorageService.fetchLastAddedEntityFromStorage);

const getLoggedinUser = state => state.loggedinUser;

function* loadVerses(bibleVersionId, bookId, chapterId) {
    yield put(getChapterVersesFromStorageActions.loadChapterVersesFromStorage(bibleVersionId, bookId, chapterId));
}

function* shouldAddChapterToBibleHistory(chapterId) {
    const filterField = 'readDateTimeLt';
    yield call(fetchLastAddedEntityFromStorage, entitiesNames.BIBLEHISTORY, filterField);
}

function* addToBibleHistory(bookId, chapterId) {
    let newFields = { readDateTimeLt: getCurrentDateTimeLtISO(), bookId: bookId, chapterId: chapterId };

    let loggedinUser = yield select(getLoggedinUser);
    if (loggedinUser) {
        newFields = { ...newFields, userId: loggedinUser.userId }
    }
    yield put(addEntityActions.addEntity(entitiesNames.BIBLEHISTORY, newFields));
}

export const watchLoadChapterVerses = function* () {
    const { GET_CHAPTER_VERSES } = chapterVersesActions;
    const { GET_LASTADDED_ENTITY_FROM_STORAGE } = getEntityActions;

    while(true) {
        const {bibleVersionId, bookId, chapterId} = yield take(GET_CHAPTER_VERSES.REQUEST);        
        yield all([
            fork(loadVerses, bibleVersionId, bookId, chapterId),
            //put(entityListActions.getEntityList.request(entitiesNames.HIGHLIGHTEDVERSES, {chapterId: chapterId})),
            //put(entityListActions.getEntityList.request(entitiesNames.FAVORITEVERSES, {chapterId: chapterId})),
            put(entityListActions.getEntityList.request(entitiesNames.VERSEOFTHEDAY, {versionId: bibleVersionId}))
        ]); 
        
        const action = yield take([GET_CHAPTER_VERSES.SUCCESS, GET_CHAPTER_VERSES.FAILURE]);
        if (action.type === GET_CHAPTER_VERSES.SUCCESS) {
            if (activeRoute(Routes.ROUTES_BIBLE_PATHNAME)) {
                yield fork(shouldAddChapterToBibleHistory, chapterId);

                const action = yield take([
                    GET_LASTADDED_ENTITY_FROM_STORAGE(entitiesNames.BIBLEHISTORY).SUCCESS,
                    GET_LASTADDED_ENTITY_FROM_STORAGE(entitiesNames.BIBLEHISTORY).FAILURE
                ]);
            
                if (action.type === GET_LASTADDED_ENTITY_FROM_STORAGE(entitiesNames.BIBLEHISTORY).SUCCESS) {
                    if (action.response.chapterId && action.response.chapterId !== chapterId) {
                        yield fork(addToBibleHistory, bookId, chapterId);
                    }
                } else {
                    yield fork(addToBibleHistory, bookId, chapterId);
                }                                
            }
        }
    }
}