import React from 'react';
import Typography from '@material-ui/core/Typography';

const Copyrights = () => (
  <div>
    <Typography variant="h4" paragraph>
      New King James
    </Typography>    
    <Typography variant="h6" paragraph>
      Copyright and Usage Information
    </Typography>
    <Typography variant="body2" paragraph>
      The Holy Bible, New King James Version®. Copyright © 1982 by Thomas Nelson, Inc. All rights reserved.
    </Typography>
    <Typography variant="body2" paragraph>
      "Scripture taken from the New King James Version. Copyright © 1982 by Thomas Nelson, Inc. Used by permission. All rights reserved."
    </Typography>
    <Typography variant="body2" paragraph>
The text of the New King James Version® (NKJV®) may be quoted or reprinted without prior written permission with the following qualifications:<br />

(1) Up to and including 1,000 verses may be quoted in printed form as long as the verses quoted amount to less than 50% of a complete book of the Bible and make up less than 50% of the total work in which they are quoted; (2) all NKJV quotations must conform accurately to the NKJV text.
Any use of the NKJV text must include a proper acknowledgment as follows:
    </Typography>
  </div>
)

export default Copyrights