import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const styles = {
    root: {
        display: 'flex'                
      },
    grow: {
        flexGrow: 1,
      },
}

const useStyles = makeStyles(styles);

const BibleHistoryPanelHeader = props => {
    const classes = useStyles();

    return (        
        <div className={classes.root}>            
            <Typography variant="h6" color="inherit" className={classes.grow} >
                Reading History
            </Typography>
        </div>
    )
}

export default BibleHistoryPanelHeader;