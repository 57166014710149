import { indexedDb } from '../../../services/indexedDb';
import { entityByName } from '../metadata';

export const getTableForEntityName = (entityName) => {
    let table = null;
    
    const entity = entityByName(entityName);
    if (entity) {
        table = indexedDb.table(entity.storageTableName);
    }

    return table;
}