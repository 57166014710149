import * as actions from '../../../actions/actionRequestTypes';

import * as _appVersionFromStorageActions from './appVersionFromStorageActions';
import * as _appVersionFromApiActions from './appVersionFromApiActions';

export const appVersionFromStorageActions = _appVersionFromStorageActions;
export const appVersionFromApiActions = _appVersionFromApiActions;

const { action } = actions;

export const VALIDATE_APP_VERSION = 'VALIDATE_APP_VERSION';
export const validateAppVersion = () => action(VALIDATE_APP_VERSION);

export const FORCE_APP_RELOAD = 'FORCE_APP_RELOAD';
export const forceAppReload = () => action(FORCE_APP_RELOAD);