import { take, call, fork } from 'redux-saga/effects';
import { registerActions } from '../actions';
import { fetchSubmitRegisterRequestApi } from '../api/registerApi';
import { fetchEntity } from '../../../../sagas/sagaHelper';

// bind Generators
const fetchSubmitRegisterRequest = fetchEntity.bind(null, registerActions.register, fetchSubmitRegisterRequestApi);

function* submitRegisterRequest(newUser) {
    yield call(fetchSubmitRegisterRequest, newUser);
}

export const watchSubmitRegisterRequest = function* () {
    while(true) {
        const {newUser} = yield take(registerActions.SUBMIT_REGISTER_REQUEST);
        yield fork(submitRegisterRequest, newUser);

        const action = yield take([registerActions.REGISTER.SUCCESS, registerActions.REGISTER.FAILURE]);
        if (action.type === registerActions.REGISTER.SUCCESS) {
            window.history.back();
        }
    }
}