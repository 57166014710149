import React from 'react';
import _ from 'lodash';
import FavoriteWords from './FavoriteWords';
import HighlightedWords from './HighlightedWords';
import SearchWords from './SearchWords';

const VerseOfTheDayWords = ({ text = '', highlight = '', search = '', favorite = '', verseOfTheDay = '' }) => {
    const styledTagsList = [];
    
    const shouldProcessHighlightedText = highlight.trim() ? true : false;
    const shouldProcessFavoriteText = favorite.trim() ? true : false;    
    const shouldProcessSearchText = search.trim() ? true : false;

    const ProcessStylingVerse = (tag, textToStyle) => {               
        if (text.indexOf(textToStyle) >= 0) {
            const styleRegex = new RegExp(`(${_.escapeRegExp(textToStyle)})`, 'gi');
            const styleParts = text.split(styleRegex);

            styleParts.filter(part => part).map((part, i) => {
                var elementStart = text.indexOf(part);
                styleRegex.test(part) ? styledTagsList.push({element: tag, position: elementStart, count: i, text: part}) : styledTagsList.push({element: '<span>', position: elementStart, count: i, text: part})
            });
        }        
    }

    if (shouldProcessHighlightedText) {
        ProcessStylingVerse('<HighlightedWords>', highlight);        
    }

    if (shouldProcessFavoriteText) {
        ProcessStylingVerse('<FavoriteWords>', favorite);     
    }    

    if (shouldProcessSearchText) {
        ProcessStylingVerse('<SearchWords>', search);        
    }

    return (
        <span className='verseOfTheDayText'>
            {
                styledTagsList.length === 0 ? text
                : styledTagsList.map((tag, i) => {
                    switch (tag.element) {
                        case '<HighlightedWords>' : return <HighlightedWords text={tag.text} />
                        case '<FavoriteWords>' : return <FavoriteWords text={tag.text} />                        
                        case '<SearchWords>' : return <SearchWords text={tag.text} />
                        default: return <span>{tag.text}</span>
                    }
                })
            }
        </span>)
}

export default VerseOfTheDayWords;