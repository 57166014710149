import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import VerseBox from './VerseBox';
import VerseMenu from './VerseMenu';
import { addEntity } from '../../../common/entities/actions/addEntityActions';
import { getSelectedText } from '../../../utils/dataHelper';

const Verse = props => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedText, setSelectedText] = React.useState("");

    const { verse, shouldDisplayAddToVerseOfTheDayListIcon, shouldStyleVerseOfTheDay, userId, addEntity } = props;

    const handleMenuOpenClick = event => {        
        setAnchorEl(event.currentTarget);        
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuPointerDown = event => {
        setSelectedText(getSelectedText());
    }

    return (
        <div>
            <VerseBox 
                verse={verse}
                shouldStyleVerseOfTheDay={shouldStyleVerseOfTheDay}
                onHandleMenuOpenClick={handleMenuOpenClick}
                onHandleMenuPointerDown={handleMenuPointerDown} 
            />
            <VerseMenu
                menuAnchorEl={anchorEl}
                shouldDisplayAddToVerseOfTheDayListIcon={shouldDisplayAddToVerseOfTheDayListIcon}
                userId={userId}
                chapterId={verse.chapterId}
                verseId={verse.verseId}
                versionId={verse.versionId}
                selectedText={selectedText}
                onAddEntity={addEntity}
                onHandleMenuClose={handleMenuClose} />
        </div>
    )
}

Verse.propTypes = {
    verse: PropTypes.object.isRequired,
    shouldDisplayAddToVerseOfTheDayListIcon: PropTypes.bool.isRequired,
    shouldStyleVerseOfTheDay:  PropTypes.bool.isRequired,
    addEntity: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    const {
        loggedinUser,
        //appStatus 
    } = state;

    //const isProcessing = appStatus.isProcessing;
    const userId = loggedinUser ? loggedinUser.userId : null;
    return { 
        userId
    }
}

export default withRouter(connect(mapStateToProps, {
    addEntity,
    //submitChangePasswordRequest    
})(Verse));