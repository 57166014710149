import { take, call, fork, select, put } from 'redux-saga/effects';
import { getBookChaptersFromApiActions, updateChaptersStorage } from '../actions';
import { fetchChaptersApi } from '../bookChaptersApi';
import { fetchEntity } from '../../../../sagas/sagaHelper';

// bind Generators
const fetchBookChapters = fetchEntity.bind(null, getBookChaptersFromApiActions.getBookChaptersFromApi, fetchChaptersApi);

const getApiUrl = state => state.systemSettings.apiUrl;

function* loadBookChapters(bibleVersionId, bookId) {
    let apiUrl = yield select(getApiUrl);
    
    yield call(fetchBookChapters, apiUrl, bibleVersionId, bookId);
}

export const watchLoadChaptersFromApi = function* () {
    const { GET_BOOK_CHAPTERS_FROM_API } = getBookChaptersFromApiActions;

    while(true) {
        const {bibleVersionId, bookId} = yield take(getBookChaptersFromApiActions.LOAD_BOOK_CHAPTERS_FROM_API);        
        yield fork(loadBookChapters, bibleVersionId, bookId);

        const action = yield take([GET_BOOK_CHAPTERS_FROM_API.SUCCESS, GET_BOOK_CHAPTERS_FROM_API.FAILURE]);
        if (action.type === GET_BOOK_CHAPTERS_FROM_API.SUCCESS) {
            const chapters = action.response.entities.chapters;
            yield put(updateChaptersStorage(chapters));
        }
    }
}