import { createSelector } from 'reselect';
import _ from 'lodash';

const getVersions = state => _.values(state.entities.bibleVersions);

export const getBibleVersions = createSelector(
    [getVersions],
    (bibleVersions) => {
        return bibleVersions;
    }
)