import React from 'react';
import { Typography } from '@material-ui/core';

const ChangePasswordHeader = () => {
    return (
        <Typography variant="h6" color="inherit" >
            Change Password
        </Typography>
    );
}

export default ChangePasswordHeader;