import { createSelector } from 'reselect';
import _ from 'lodash';
import { getBibleVersionBySelectedVersionId } from '../../Versions/selectors/getBibleVersionBySelectedVersionIdSelector';

const getBibleChapters = state => _.values(state.entities.chapters);

export const getChaptersByBibleVersion = createSelector(
    [getBibleChapters, getBibleVersionBySelectedVersionId],
    (bibleChapters, bibleVersionBySelectedVersionId) => {
        const selectedLangId = bibleVersionBySelectedVersionId ? bibleVersionBySelectedVersionId.langId : 2;
        const filteredChapters = bibleChapters.filter(chapter => chapter.langId === selectedLangId);
        return filteredChapters;
    }
)