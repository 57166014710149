import { take, call, fork } from 'redux-saga/effects';
import { UPDATE_VERSES_STORAGE } from '../actions';
import { addVersesToLocalStoreDb } from '../indexedDbStorage';

function* updateLocalStorage(verses) {
    yield call(addVersesToLocalStoreDb.addVersesToLocalStore, verses);
}

export const watchUpdateVersesLocalStorage = function* () {
    while(true) {
        const {verses} = yield take(UPDATE_VERSES_STORAGE);
        yield fork(updateLocalStorage, verses);
    }
}