import { getTableForEntityName } from './entityHelper';

const loadLastAddedEntityFromLocalStore = (entityName, filter) => {
    let lastAddedEntity = null;
    let table = getTableForEntityName(entityName);
    if (table) {   
        lastAddedEntity = table.orderBy(filter).last();
    }

    return lastAddedEntity;    
}

export const fetchLastAddedEntityFromStorage = (entityName, filter) => {
    return loadLastAddedEntityFromLocalStore(entityName, filter)
        .then(entityItem => {        
            return entityItem;
        })
        .catch(error => {
            //throw error;
        })
        .then(
            response => ({ response }),
            error => ({ error: error.message })
        );
}