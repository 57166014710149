import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import UpdateUserInfoPanel from './Panel';
import { updateUserInfoActions } from './actions';
import { setAlertMessage } from '../../../actions';
import { ALERT_TYPE_ERROR } from '../../../constants';
import { compareChangedData, getData } from '../../../utils/dataHelper';
import { getCurrentDateTimeLt } from '../../../utils/dateHelper';

const { submitUpdateUserInfoRequest } = updateUserInfoActions;

const UpdateUserInfo = (props) => {
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [email, setEmail] = useState('');
    const [pageLoaded, setPageLoaded] = useState(false);

    const { isProcessing, loggedinUser, setAlertMessage, submitUpdateUserInfoRequest } = props;

    const initializeLoadingPage = () => {        
        if (loggedinUser) {
            setFirstname(loggedinUser.firstname);
            setLastname(loggedinUser.lastname);
            setDisplayName(loggedinUser.displayName);
            setEmail(loggedinUser.email);
        }
        setPageLoaded(true);
    }

    useEffect(() => {
        if (!pageLoaded) {
            initializeLoadingPage();            
        }
    })

    const isFieldPopulated = (value) => {
        return value !== '';
    }
    
    const handleFirstnameChange = event => {
        setFirstname(event.target.value);
    };

    const handleLastnameChange = event => {
        setLastname(event.target.value);
    };

    const handleDisplayNameChange = event => {
        setDisplayName(event.target.value);
    };

    const handleEmailChange = event => {
        setEmail(event.target.value);
    };

    const getChangedData = () => {
        let changedData = {};
        const currentDateTimeLt = getCurrentDateTimeLt();

        changedData = compareChangedData(changedData, 'Firstname', loggedinUser.firstname, firstname);
        changedData = compareChangedData(changedData, 'Lastname', loggedinUser.lastname, lastname);
        changedData = compareChangedData(changedData, 'DisplayName', loggedinUser.displayName, displayName);
        changedData = compareChangedData(changedData, 'Email', loggedinUser.email, email);
        changedData = getData(changedData, 'ModifiedDateTimeLt', currentDateTimeLt);

        return changedData;
    }
    
    const handleSubmitUpdateUserInfoClick = (event) => {
        event.preventDefault();

        if (isValidRequiredFields()) {
            submitUpdateUserInfoRequest(loggedinUser.userId, JSON.stringify(getChangedData()));
        }
    }

    const isValidFirstname = () => {
        if (!isFieldPopulated(firstname)) {
            setAlertMessage(ALERT_TYPE_ERROR, 'First name cannot be empty');
            return false;
        }

        return true;
    }

    const isValidDisplayname = () => {
        if (!isFieldPopulated(displayName)) {
            setAlertMessage(ALERT_TYPE_ERROR, 'Display name cannot be empty');
            return false;
        }

        return true;
    }

    const isValidEmail = () => {        
        var mailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if (!email.match(mailFormat)) {
            setAlertMessage(ALERT_TYPE_ERROR, 'Invalid Email');
            return false;
        }

        return true;
    }

    const isValidRequiredFields = () => {        
        return isValidFirstname()
            && isValidDisplayname()
            && isValidEmail();
    }

    return (
        <UpdateUserInfoPanel isProcessing={isProcessing} 
            firstname={firstname}
            lastname={lastname}
            displayName={displayName}
            email={email}
            onFirstnameChange={handleFirstnameChange}
            onLastnameChange={handleLastnameChange}
            onDisplayNameChange={handleDisplayNameChange}
            onEmailChange={handleEmailChange}
            onSubmitUpdateUserInfoClick={handleSubmitUpdateUserInfoClick} />
    );
}

UpdateUserInfo.propTypes = {
    isProcessing: PropTypes.bool.isRequired,
    loggedinUser: PropTypes.object.isRequired,
    setAlertMessage: PropTypes.func.isRequired,
    submitUpdateUserInfoRequest: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    const {
        appStatus,
        loggedinUser, 
    } = state;

    const isProcessing = appStatus.isProcessing;
    return { 
        isProcessing,
        loggedinUser
    }
}

export default withRouter(connect(mapStateToProps, {
    setAlertMessage,
    submitUpdateUserInfoRequest    
})(UpdateUserInfo));