import { indexedDb } from '../../../services/indexedDb';
import { SETTINGS_WEBAPPVERSION_NAME } from '../../constants';

const loadAppVersionFromLocalStore = () => {
    return indexedDb.settings.get(SETTINGS_WEBAPPVERSION_NAME);        
}

export const fetchAppVersionFromStorage = (latestAppVersion) => {
    return loadAppVersionFromLocalStore()
        .then(appVersion => {
            if (appVersion) {       
                if (appVersion.settingValue !== latestAppVersion) {
                } else {
                    const response = {};
                    return { ...response, appVersion }
                }                
            } else {
                //throw new Error('No webAppVersion found in local storage!');
            }
        })
        .catch(error => {
        })
        .then(
            response => ({ response }),
            error => ({ error: error.message })
        );         
}