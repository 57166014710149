import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import HomePageAppBar from './AppBar';
import HomePageDrawer from './Drawer';
import HomePageMain from './BaseMain';
import styles from '../../assets/jss/views/mainPageStyle';

const useStyles = makeStyles(styles);

const HomePage = props => {
    const classes = useStyles();

    const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
    const { isLoggedIn, userLoggingIn, userDisplayName, isProcessing, shouldDisplayNewVersionAvailable, shouldDisplayAlertMessage, 
        alertType, alertMessageText, onHandleMessageBoxClose, onHandleAppVersionUpdateClick } = props;
    const handleDrawerToggle = () => {
        setMobileDrawerOpen(!mobileDrawerOpen);
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <HomePageAppBar
                isLoggedIn={isLoggedIn}
                userLoggingIn={userLoggingIn}
                userDisplayName={userDisplayName}
                isProcessing={isProcessing}
                onHandleDrawerToggle={handleDrawerToggle} />
            <HomePageDrawer
                isMobileDrawerOpen={mobileDrawerOpen}
                onHandleDrawerToggle={handleDrawerToggle} />
            <main className={classes.mainPanel}>
                <HomePageMain shouldDisplayNewVersionAvailable={shouldDisplayNewVersionAvailable}
                    shouldDisplayAlertMessage={shouldDisplayAlertMessage}
                    alertType={alertType}
                    alertMessageText={alertMessageText}
                    onHandleMessageBoxClose={onHandleMessageBoxClose}
                    onHandleAppVersionUpdateClick={onHandleAppVersionUpdateClick} />
            </main>
        </div>
    )
}

HomePage.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    userLoggingIn: PropTypes.bool.isRequired,
    userDisplayName: PropTypes.string.isRequired,
    isProcessing: PropTypes.bool.isRequired,
    shouldDisplayNewVersionAvailable: PropTypes.bool.isRequired,
    shouldDisplayAlertMessage: PropTypes.bool.isRequired,
    alertType: PropTypes.string.isRequired,
    alertMessageText: PropTypes.string.isRequired,
    onHandleMessageBoxClose: PropTypes.func.isRequired,
    onHandleAppVersionUpdateClick: PropTypes.func.isRequired
}

export default HomePage;