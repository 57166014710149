import { take, call, fork, put,  } from 'redux-saga/effects';
import { entityByName, apiEntityResourceName } from '../metadata';
import { entityListActions, entityListApiActions, entityListStorageActions } from '../actions';
import { fetchEntityListApi, fetchNormalizedEntityListApi } from '../api/entitiesApi';
import { fetchEntity } from '../../../sagas/sagaHelper';

// bind Generators
const fetchEntityList = fetchEntity.bind(null, entityListApiActions.getEntityListFromApi, fetchEntityListApi);
const fetchNormalizedEntityList = fetchEntity.bind(null, entityListApiActions.getEntityListFromApi, fetchNormalizedEntityListApi);

function* loadEntities(entityName, filter) {
    const apiResourceName = apiEntityResourceName(entityName);
    const entity = entityByName(entityName);
        
    entity.getEntityListFromApi
        ? yield call(entity.getEntityListFromApi, filter)
        : entity.shouldNormalizeApiResponse
            ? yield call(fetchNormalizedEntityList, apiResourceName, filter, entity.apiSchemaArray)
            : yield call(fetchEntityList, apiResourceName, filter);
}

function* saveEntitiesToStorage(entityName, apiResponse) {
    const currentUtcTime = apiResponse.result ? apiResponse.result.currentUtcTime : apiResponse.currentUtcTime;
    const entity = entityByName(entityName);
    let entityList = null;
    if (entity) {
        entityList = entity.getEntityListFromApiResponse(apiResponse);
    }    
    if (entityList) {
        yield put(entityListStorageActions.saveEntityListToStorage(entityName, entityList, currentUtcTime));
    }
}

export const watchLoadEntityListFromApi = function* (entityName) {
    const apiResourceName = apiEntityResourceName(entityName);
    const GET_ENTITY_LIST_FROM_API = entityListApiActions.GET_ENTITY_LIST_FROM_API(apiResourceName);

    while(true) {
        const {type, ...filter} = yield take(entityListApiActions.LOAD_ENTITY_LIST_FROM_API(entityName));
        yield fork(loadEntities, entityName, filter);

        const action = yield take([
            GET_ENTITY_LIST_FROM_API.SUCCESS, 
            GET_ENTITY_LIST_FROM_API.FAILURE
        ]);
        if (action.type === GET_ENTITY_LIST_FROM_API.SUCCESS) {
            yield put(entityListActions.getEntityList.success(entityName, action.response));
            yield fork(saveEntitiesToStorage, entityName, action.response);
        } 
        else if (action.type === GET_ENTITY_LIST_FROM_API.FAILURE) {
            yield put(entityListActions.getEntityList.failure(entityName, action.error));
        }
    }
}