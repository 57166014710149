import { take, call, fork, select, put } from 'redux-saga/effects';
import { addEntityActions, syncEntityListToTheCloud } from '../actions';
import { entityByName } from '../metadata';
import { fetchAddEntityToStorage } from '../indexedDbStorage/fetchAddEntityToStorageService';
import { fetchSubmitAddEntityRequestApi } from '../api/entitiesApi';
import { fetchEntity } from '../../../sagas/sagaHelper';

// bind Generators
const fetchSubmitAddEntityToStorageRequest = fetchEntity.bind(null, addEntityActions.addEntityStorage, fetchAddEntityToStorage);
const fetchSubmitAddEntityApiRequest = fetchEntity.bind(null, addEntityActions.addEntityApi, fetchSubmitAddEntityRequestApi);

const getLoggedinUser = state => state.loggedinUser;

function* submitAddEntityApiRequest(entityName, payload) {
    yield call(fetchSubmitAddEntityApiRequest, entityName, JSON.stringify(payload));
}

function* submitAddEntityStorageRequest(entityName, payload) {
    yield call(fetchSubmitAddEntityToStorageRequest, entityName, payload);
}

export const watchAddEntity = function* (entityName) {
    const ADD_ENTITY_STORAGE = addEntityActions.ADD_ENTITY_STORAGE(entityName);
    const ADD_ENTITY_API = addEntityActions.ADD_ENTITY_API(entityName);         

    while(true) {
        const {payload} = yield take(addEntityActions.ADD_ENTITY_ACTION_TYPE(entityName));

        let loggedinUser = yield select(getLoggedinUser);
        const entity = entityByName(entityName);

        var entityData = payload;

/*         if (loggedinUser) {
            yield fork(submitAddEntityApiRequest, entityName, entity.addApiData(payload));

            const action = yield take([ADD_ENTITY_API.SUCCESS, ADD_ENTITY_API.FAILURE]);
            if (action.type === ADD_ENTITY_API.SUCCESS) {
                entityData = {...entityData, ...action.response};
            }
        }  */       

        yield fork(submitAddEntityStorageRequest, entityName, entityData);

        const action = yield take([ADD_ENTITY_STORAGE.SUCCESS, ADD_ENTITY_STORAGE.FAILURE]);
        if (action.type === ADD_ENTITY_STORAGE.SUCCESS && loggedinUser) {
            yield put(syncEntityListToTheCloud(entityName));
        }
        
        //yield put(addEntityActions.addEntityApi.request(entityName, payload));        

         // save to storage using service or saga - ensure it includes datetime
         // put api request
         // it's possible api would fail and it would resync later

        // yield put(appVersionActions.validateAppVersion());
        // yield put(verseOfTheDayListActions.getVerseOfTheDayList.request(bibleVersionId));        
        
        // const action = yield take([GET_VERSEOFTHEDAY_LIST.SUCCESS, GET_VERSEOFTHEDAY_LIST.FAILURE]);
        // if (action.type === GET_VERSEOFTHEDAY_LIST.SUCCESS) {
        //     let currentDate = getCurrentDate();
        //     yield put(verseOfTheDayActions.loadPersonalVerseOfTheDayFromStorage(bibleVersionId, currentDate));

        //     const action = yield take([GET_PERSONAL_VERSEOFTHEDAY_FROM_STORAGE.SUCCESS, GET_PERSONAL_VERSEOFTHEDAY_FROM_STORAGE.FAILURE]);
        //     if (action.type === GET_PERSONAL_VERSEOFTHEDAY_FROM_STORAGE.FAILURE) {
        //         yield put(verseOfTheDayActions.generateVerseOfTheDay.request(currentDate));                
        //     }                       
        // }
    }
}
