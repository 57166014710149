import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BibleHistoryItemPanel from './Panel';
import { setBibleBook } from '../../Bible/Books/actions';
import { setBookChapter } from '../../Bible/Chapters/actions';
import { getBookByBibleVersionAndBookId } from '../../Bible/Books/selectors/getBookByBibleVersionAndBookIdSelector';
import { getChapterByBibleVersionAndChapterId } from '../../Bible/Chapters/selectors/getChapterByBibleVersionAndChapterIdSelector';
import { getDateCaptionFromDateTime, getTimeCaptionFromDateTime } from '../../../utils/dateHelper';

const BibleHistoryItem = props => {
    const { day, time, bookName, bookId, chapterId, chapterNumber, setBibleBook, setBookChapter } = props;

    const handleReadChapterClick = () => {
        setBibleBook(bookId, true);
        setBookChapter(chapterId);
    }

    return (
        <BibleHistoryItemPanel day={day}
            time={time}
            bookName={bookName}
            chapterId={chapterId}
            chapterNumber={chapterNumber}
            onHandleReadChapterClick={handleReadChapterClick} />
    )
}

BibleHistoryItem.propTypes = {
    day: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    bookName: PropTypes.string.isRequired,
    bookId: PropTypes.number.isRequired,
    chapterId: PropTypes.number.isRequired,
    chapterNumber: PropTypes.string.isRequired,
    setBibleBook: PropTypes.func.isRequired,
    setBookChapter: PropTypes.func.isRequired,
}

const mapStateToProps = (state, props) => {
    const { historyItem } = props

    const day = getDateCaptionFromDateTime(historyItem.readDateTimeLt);
    const time = getTimeCaptionFromDateTime(historyItem.readDateTimeLt);
    const book = getBookByBibleVersionAndBookId(state, historyItem.bookId);
    const bookId = historyItem.bookId;
    const bookName = book ? book.bookName : '';
    const chapterId = historyItem.chapterId;
    const chapter = getChapterByBibleVersionAndChapterId(state, historyItem.chapterId);
    const chapterNumber = chapter ? chapter.chapterNumber : '';

    return {
        day,
        time,
        bookName,
        bookId,
        chapterId,
        chapterNumber
    }
}

export default connect(mapStateToProps, {
    setBibleBook,
    setBookChapter    
})(BibleHistoryItem);