import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { ROUTES_FORGOTPASSWORD_PATHNAME, ROUTES_REGISTER_PATHNAME } from '../../../constants';

function Copyright() {
    return (        
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://ChristTheBeloved.com/">
                Christ The Beloved Bible App
        </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',   
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    }
}));

const ForgotPasswordLink = React.forwardRef((props, ref) => <RouterLink innerRef={ref} {...props} />);
const RegisterLink = React.forwardRef((props, ref) => <RouterLink innerRef={ref} {...props} />);

const LoginPanel = props => {
    const classes = useStyles();
    const [ usernameFocused, setUsernameFocused] = useState(false);
    const { isProcessing, username, password, rememberMe } = props;
    const { onUsernameChange, onPasswordChange, onRememberMeChange, onSubmitLoginClick } = props;

    let usernameInput;

    const setFocus = () => {
        usernameInput.focus();
        setUsernameFocused(true);
    }

    useEffect(() => {
        if (!usernameFocused) {
            setFocus();
        }        
    })

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <form className={classes.form} onSubmit={onSubmitLoginClick} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="username-input"
                        label="Username"
                        name="username-input"
                        autoComplete="username-input"
                        autoFocus={true}
                        inputRef={ input => {usernameInput = input;} }                      
                        value={username}
                        onChange={onUsernameChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        value={password}
                        onChange={onPasswordChange}                        
                    />
                    <FormControlLabel
                        control={<Checkbox color="primary" checked={rememberMe} onChange={onRememberMeChange} />}
                        label="Remeber my signin on this device"
                    />
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={isProcessing}
                        type="submit" 
                    >
                        Sign in
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link component={ForgotPasswordLink} to={ROUTES_FORGOTPASSWORD_PATHNAME} variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link component={RegisterLink} to={ROUTES_REGISTER_PATHNAME} variant="body2">
                                {"Don't have an account? Register"}
                            </Link>
                        </Grid>                        
                    </Grid>
                </form>
            </div>
            <Box mt={3}>
                <Typography variant="body2" color="textSecondary">
                    Please sign in to ensure your data is synced to the cloud. This allows to protect against data loss and to have same user experience and data across devices you use.
                </Typography>
                <br />
                <Copyright />
            </Box>
        </Container>
    );
}

LoginPanel.propTypes = {
    username: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    rememberMe: PropTypes.bool.isRequired,
    onUsernameChange: PropTypes.func.isRequired,
    onPasswordChange: PropTypes.func.isRequired,
    onRememberMeChange: PropTypes.func.isRequired,
    onSubmitLoginClick: PropTypes.func.isRequired
}

export default LoginPanel;