import { take, call, fork, put, select } from 'redux-saga/effects';
import { getBooksFromStorageActions, getBooksFromApiActions } from '../actions';
import { fetchBooksFromStorageService } from '../indexedDbStorage/service';
import { fetchEntity } from '../../../../sagas/sagaHelper';
import { DEFAULT_LANGID } from '../../Versions/constants'

const getBibleVersion = state => state.bibleVersion;

// bind Generators
const fetchBooksFromStorage = fetchEntity.bind(null, getBooksFromStorageActions.getBibleBooksFromStorage, fetchBooksFromStorageService);

function* loadBibleBooks(bibleVersionId) {
    let bibleVersion = yield select(getBibleVersion);
    
    const langId = bibleVersion ? bibleVersion.langId : DEFAULT_LANGID;

    yield call(fetchBooksFromStorage, langId);    
}

export const watchLoadBibleBooksFromStorage = function* () {
    const { GET_BIBLE_BOOKS_FROM_STORAGE } = getBooksFromStorageActions;
    
    while(true) {
        const {bibleVersionId} = yield take(getBooksFromStorageActions.LOAD_BIBLE_BOOKS_FROM_STORAGE);
        yield fork(loadBibleBooks, bibleVersionId);
        
        const action = yield take([
            GET_BIBLE_BOOKS_FROM_STORAGE.SUCCESS, 
            GET_BIBLE_BOOKS_FROM_STORAGE.FAILURE
        ]);
        
        if (action.type === GET_BIBLE_BOOKS_FROM_STORAGE.FAILURE) {
            yield put(getBooksFromApiActions.loadBibleBooksFromApi(bibleVersionId));
        }        
    }
}