import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
//import Swipe from 'react-easy-swipe';
import { Swipeable } from 'react-swipeable';
import VersesPanel from './Panel';
import { getVersesByChapterIdAndBibleVersion } from './selector';
import { requestNextChapter, requestPrevChapter } from '../Chapters/actions';
import { chapterVersesActions, bookVersesFromApiActions, bibleVersesFromApiActions } from './actions';
import {  getBookByBibleVersionAndSelectedBookId } from '../Books/selectors/getBookByBibleVersionAndBookIdSelector';

const { request: getChapterVerses } = chapterVersesActions.getChapterVerses;
const { loadBookVersesFromApi } = bookVersesFromApiActions;
const { loadBibleVersesFromApi } = bibleVersesFromApiActions;

class Verses extends Component {
    static propTpes = {
        versesList: PropTypes.array.isRequired,
        shouldDisplayAddToVerseOfTheDayListIcon: PropTypes.bool.isRequired,
        shouldStyleVerseOfTheDay: PropTypes.bool.isRequired,
        isLoading: PropTypes.bool.isRequired,
        isLoadingSuccessful: PropTypes.bool.isRequired,
        bibleVersionId: PropTypes.number.isRequired,
        bookId: PropTypes.number.isRequired,
        selectedBibleBook: PropTypes.object.isRequired,
        chapterId: PropTypes.number.isRequired,
        requestNextChapter: PropTypes.func.isRequired,
        requestPrevChapter: PropTypes.func.isRequired,
        getChapterVerses: PropTypes.func.isRequired,
        loadBookVersesFromApi: PropTypes.func.isRequired,
        loadBibleVersesFromApi: PropTypes.func.isRequired
    }

    componentDidMount() {
        const { isLoading, bibleVersionId, bookId, chapterId } = this.props;
        if ((!isLoading) && (bookId !== 0) && (chapterId !== 0)) {            
            this.props.getChapterVerses(bibleVersionId, bookId, chapterId);
        }                
    }
    
    handleSwipingLeft = event => {
        const { bibleVersionId, requestPrevChapter, requestNextChapter } = this.props;

         if (bibleVersionId === 1) {
            requestPrevChapter();
        } else {
            requestNextChapter();
        } 
    };

    handleSwipingRight = event => {
        const { bibleVersionId, requestPrevChapter, requestNextChapter } = this.props;
        
         if (bibleVersionId === 1) {
            requestNextChapter();
        } else {
            requestPrevChapter();
        } 
    };

    handleDownloadBookClick = (event) => {
        const { bibleVersionId, bookId } = this.props;
        if (bookId !== 0) {            
            this.props.loadBookVersesFromApi(bibleVersionId, bookId);
        }
    }

    handleDownloadBibleClick = (event) => {
        const { bibleVersionId } = this.props;
        this.props.loadBibleVersesFromApi(bibleVersionId);
    }

    render() {
        const { shouldDisplayDownloadBibleOptions, shouldDisplayAddToVerseOfTheDayListIcon, shouldStyleVerseOfTheDay,
                isLoading, isLoadingSuccessful, selectedBibleBook } = this.props;
        let bibleBookName = "";

        if (isLoadingSuccessful) {
            bibleBookName = selectedBibleBook ? selectedBibleBook.bookName : "Book";
        } else {
            bibleBookName = "Book";
        }        

        return (
            <div>
                <Swipeable
                    onSwipedLeft={this.handleSwipingLeft}
                    onSwipedRight={this.handleSwipingRight}>                    
                    <VersesPanel shouldDisplayDownloadBibleOptions={shouldDisplayDownloadBibleOptions}
                        shouldDisplayAddToVerseOfTheDayListIcon={shouldDisplayAddToVerseOfTheDayListIcon}
                        shouldStyleVerseOfTheDay={shouldStyleVerseOfTheDay}
                        isLoading={isLoading}
                        isLoadingSuccessful={isLoadingSuccessful}
                        versesList={this.props.versesList}
                        bibleVersionId={this.props.bibleVersionId}
                        selectedBibleBookName={bibleBookName}
                        onHandleDownloadBookClick={this.handleDownloadBookClick}
                        onHandleDownloadBibleClick={this.handleDownloadBibleClick} />
                </Swipeable>                
            </div>
        );        
    }
}

const mapStateToProps = state => {
    const {
        bibleVersion,
        bibleBook,
        bookChapter,
        chapterVerses,
        loggedinUser,
    } = state
    
    const bibleVersionId = bibleVersion.id;
    const bookId = bibleBook.id;
    const chapterId = bookChapter.id;
    const versesList = getVersesByChapterIdAndBibleVersion(state);
    const isLoading = chapterVerses.isLoading;
    const isLoadingSuccessful = chapterVerses.isLoadingSuccessful;
    const shouldDisplayDownloadBibleOptions = chapterVerses.canDisplayDownloadBibleOptions;
    const selectedBibleBook = getBookByBibleVersionAndSelectedBookId(state);
    const shouldDisplayAddToVerseOfTheDayListIcon = loggedinUser && loggedinUser.profileName === "Admin" ? true : false;
    const shouldStyleVerseOfTheDay = loggedinUser && loggedinUser.profileName === "Admin" ? true : false;
    
    return {
        bibleVersionId,
        bookId,
        chapterId,
        versesList,
        isLoading,
        isLoadingSuccessful,        
        shouldDisplayDownloadBibleOptions,
        shouldDisplayAddToVerseOfTheDayListIcon,
        shouldStyleVerseOfTheDay,
        selectedBibleBook
    }
}

export default withRouter(connect(mapStateToProps, {
    requestNextChapter,
    requestPrevChapter,
    getChapterVerses,
    loadBookVersesFromApi,
    loadBibleVersesFromApi
})(Verses));