import * as actions from '../../../actions/actionRequestTypes';

const { createRequestTypes, action } = actions;

export const GET_ENTITY_LIST_ACTION_TYPE = entityName => `GET_${entityName}_LIST`;

export const GET_ENTITY_LIST = entityName => createRequestTypes(GET_ENTITY_LIST_ACTION_TYPE(entityName));
export const getEntityList = {
    request: (entityName, filter) => action(GET_ENTITY_LIST(entityName).REQUEST, filter),
    success: (entityName, response) => action(GET_ENTITY_LIST(entityName).SUCCESS, {response}),
    failure: (entityName, error) => action(GET_ENTITY_LIST(entityName).FAILURE, {error})
}