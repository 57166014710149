import * as actions from '../../../actions/actionRequestTypes';

import * as _addEntityActions from './addEntityActions';
import * as _entityListActions from './entityListActions';
import * as _entityListStorageActions from './entityListStorageActions';
import * as _lastUpdatedEntityListSettingStorageActions from './lastUpdatedEntityListSettingStorageActions';
import * as _lastUploadedEntityListSettingStorageActions from './lastUploadedEntityListSettingStorageActions';
import * as _entityListApiActions from './entityListApiActions';
import * as _getEntityActions from './getEntityActions';

export const addEntityActions = _addEntityActions;
export const entityListActions = _entityListActions;
export const entityListStorageActions = _entityListStorageActions;
export const lastUpdatedEntityListSettingStorageActions = _lastUpdatedEntityListSettingStorageActions;
export const lastUploadedEntityListSettingStorageActions = _lastUploadedEntityListSettingStorageActions;
export const entityListApiActions = _entityListApiActions;
export const getEntityActions = _getEntityActions;

const { action } = actions;

export const SYNC_ENTITY_LIST_TO_THECLOUD = entityName => `SYNC_${entityName}_LIST_TO_THECLOUD`;
export const syncEntityListToTheCloud = entityName => action(SYNC_ENTITY_LIST_TO_THECLOUD(entityName));

export const SYNC_ENTITY_LIST_FROM_THECLOUD = entityName => `SYNC_${entityName}_LIST_FROM_THECLOUD`;
export const syncEntityListFromTheCloud = (entityName, filter) => action(SYNC_ENTITY_LIST_FROM_THECLOUD(entityName), filter);