import { indexedDb } from '../../../services/indexedDb';
import { SETTINGS_LASTUPDATED_ENTITYLIST_NAME } from '../../../features/constants';

const loadLastUpdatedEntityListSettingFromLocalStore = (entityName, versionId) => {
    return indexedDb.settings.get(SETTINGS_LASTUPDATED_ENTITYLIST_NAME(entityName, versionId));
}

export const fetchLastUpdatedEntityListSettingFromStorage = (entityName, versionId) => {
    return loadLastUpdatedEntityListSettingFromLocalStore(entityName, versionId)
        .then(lastUpdatedSetting => {
            if (lastUpdatedSetting) {       
                const response = {};
                return { ...response, lastUpdatedSetting }
            } else {
            }
        })
        .catch(error => {
        })
        .then(
            response => ({ response }),
            error => ({ error: error.message })
        );         
}