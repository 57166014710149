import { createSelector } from 'reselect';
import _ from 'lodash';

const getVerses = state => _.values(state.entities.verses);
const getBibleVersion = state => state.bibleVersion;
const getSelectedChapter = state => state.bookChapter;

export const getVersesByChapterId = createSelector(
    [getVerses, getSelectedChapter],
    (verses, selectedChapter) => {
        const filteredVerses = verses.filter(verse => verse.chapterId === selectedChapter.id);
        return filteredVerses;
    }
)

export const getVersesByChapterIdAndBibleVersion = createSelector(
    [getVersesByChapterId, getBibleVersion],
    (versesByChapterId, bibleVersion) => {
        const filteredVerses = versesByChapterId.filter(verse => verse.versionId === bibleVersion.id);
        return filteredVerses;
    }
)