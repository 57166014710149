import { GET_ENTITY_LIST } from "../../common/entities/actions/entityListActions";
import { BIBLEHISTORY } from '../../common/entities/constants';


const initialState = {isLoading: false, isLoadingSuccessful: false, items: {}};
export const bibleHistory = (state = initialState, action) => {
    const { type } = action;

    switch (type) {
        case GET_ENTITY_LIST(BIBLEHISTORY).SUCCESS:
            if (action.response && action.response.length > 0)
                return {
                    ...state,
                    items: action.response
                }
            else
                return state;
        default:
            return state;
    }
}