import * as actions from '../../../actions/actionRequestTypes';

const { createRequestTypes, action } = actions;

export const LOAD_PERSONAL_VERSEOFTHEDAY_FROM_STORAGE = 'LOAD_PERSONAL_VERSEOFTHEDAY_FROM_STORAGE';
export const loadPersonalVerseOfTheDayFromStorage = (bibleVersionId, date) => action(LOAD_PERSONAL_VERSEOFTHEDAY_FROM_STORAGE, {bibleVersionId, date});

export const GET_PERSONAL_VERSEOFTHEDAY_FROM_STORAGE = createRequestTypes('GET_PERSONAL_VERSEOFTHEDAY_FROM_STORAGE');

export const getPersonalVerseOfTheDayFromStorage = {
    request: () => action(GET_PERSONAL_VERSEOFTHEDAY_FROM_STORAGE.REQUEST),
    success: (id, response) => action(GET_PERSONAL_VERSEOFTHEDAY_FROM_STORAGE.SUCCESS, {response}),
    failure: (id, error) => action(GET_PERSONAL_VERSEOFTHEDAY_FROM_STORAGE.FAILURE, {error})
}

export const SAVE_PERSONAL_VERSEOFTHEDAY_TO_STORAGE = 'SAVE_PERSONAL_VERSEOFTHEDAY_TO_STORAGE';
export const savePersonalVerseOfTheDayToStorage = (date, verseId) => action(SAVE_PERSONAL_VERSEOFTHEDAY_TO_STORAGE, {date, verseId});

export const GET_VERSEOFTHEDAY = 'GET_VERSEOFTHEDAY';
export const getVerseOfTheDay = (bibleVersionId) => action(GET_VERSEOFTHEDAY, {bibleVersionId});

export const GENERATE_VERSEOFTHEDAY = createRequestTypes('GENERATE_VERSEOFTHEDAY');
export const generateVerseOfTheDay = {
    request: (date) => action(GENERATE_VERSEOFTHEDAY.REQUEST, {date}),
    success: (id, response) => action(GENERATE_VERSEOFTHEDAY.SUCCESS, {response}),
    failure: (id, error) => action(GENERATE_VERSEOFTHEDAY.FAILURE, {error})
}