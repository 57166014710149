import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LoginPanel from './Panel';
import { loginActions } from './actions';
import { ROUTES_HOME_PATHNAME } from '../../../constants';
import { getCurrentDateTimeLt } from '../../../utils/dateHelper';

const { submitLoginRequest } = loginActions;

const Login = (props) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);

    const { isProcessing, loggedinUser } = props;

    useEffect(() => {
        if (loggedinUser) {
            props.history.push(ROUTES_HOME_PATHNAME);
        }   
    })

    const handleUsernameChange = event => {
        setUsername(event.target.value);
    };

    const handlePasswordChange = event => {
        setPassword(event.target.value);
    };

    const handleRememberMeChange = event => {
        setRememberMe(event.target.checked);
    }    

    const handleSubmitLoginClick = event => {
        event.preventDefault();
        let encodedCredentials = window.btoa(username + ':' + password);
        const loginDateTimeLt = getCurrentDateTimeLt();
        props.submitLoginRequest(JSON.stringify({EncodedCredentials: encodedCredentials, currentDateTimeLt: loginDateTimeLt}), rememberMe);
    }

    return (
        <LoginPanel isProcessing={isProcessing}
            username={username}
            password={password}
            rememberMe={rememberMe}
            onUsernameChange={handleUsernameChange}
            onPasswordChange={handlePasswordChange}
            onRememberMeChange={handleRememberMeChange}
            onSubmitLoginClick={handleSubmitLoginClick} />
    );
}

Login.propTypes = {
    isProcessing: PropTypes.bool.isRequired,
    submitLoginRequest: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    const {
        appStatus,
        loggedinUser 
    } = state;

    const isProcessing = appStatus.isProcessing;
    return { 
        isProcessing,
        loggedinUser
    }
}

export default withRouter(connect(mapStateToProps, {
    submitLoginRequest    
})(Login));