import { createSelector } from 'reselect';
import { getBooksByBibleVersion } from './getBooksByBibleVersionSelector';

const getSelectedBibleBook = state => state.bibleBook;

export const getBookByBibleVersionAndSelectedBookId = createSelector(
    [getBooksByBibleVersion, getSelectedBibleBook],
    (booksByBibleVersion, selectedBibleBook) => {
        const bibleBooks = booksByBibleVersion.filter(book => book.bookId === selectedBibleBook.id);
        return bibleBooks[0];
    }
)

const getBookId = (state, bookId) => bookId;

export const getBookByBibleVersionAndBookId = createSelector(
    [getBooksByBibleVersion, getBookId],
    (booksByBibleVersion, bookId) => {
        return booksByBibleVersion.find(book => book.bookId === bookId);
    }
)