import { take, call, fork } from 'redux-saga/effects';
import { UPDATE_CHAPTERS_STORAGE } from '../actions';
import { addChaptersToLocalStoreDb } from '../indexedDbStorage';

function* updateLocalStorage(chapters) {
    yield call(addChaptersToLocalStoreDb.addChaptersToLocalStore, chapters);
}

export const watchUpdateChaptersLocalStorage = function* () {
    while(true) {
        const {chapters} = yield take(UPDATE_CHAPTERS_STORAGE);
        yield fork(updateLocalStorage, chapters);
    }
}