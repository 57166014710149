import { indexedDb } from '../../../services/indexedDb';
import { SETTINGS_LASTUPDATED_VERSEOFTHEDAYLIST_NAME } from '../../constants';

const loadLastUpdatedVerseOfTheDayListSettingFromLocalStore = () => {
    return indexedDb.settings.get(SETTINGS_LASTUPDATED_VERSEOFTHEDAYLIST_NAME);
}

export const fetchLastUpdatedVerseOfTheDayListSettingFromStorage = () => {
    return loadLastUpdatedVerseOfTheDayListSettingFromLocalStore()
        .then(lastUpdatedSetting => {
            if (lastUpdatedSetting) {       
                const response = {};
                return { ...response, lastUpdatedSetting }
            } else {
            }
        })
        .catch(error => {
        })
        .then(
            response => ({ response }),
            error => ({ error: error.message })
        );         
}