import { createSelector } from 'reselect';
import { getBibleBooks } from './getBibleBooksSelector';
import { getBibleVersionBySelectedVersionId } from '../../Versions/selectors/getBibleVersionBySelectedVersionIdSelector';

export const getBooksByBibleVersion = createSelector(
    [getBibleBooks, getBibleVersionBySelectedVersionId],
    (bibleBooks, bibleVersionBySelectedVersionId) => {
        const selectedLangId = bibleVersionBySelectedVersionId ? bibleVersionBySelectedVersionId.langId : 2;
        const filteredBooks = bibleBooks.filter(book => book.langId === selectedLangId);
        return filteredBooks;
    }
)