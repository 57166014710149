import { indexedDb } from '../../../services/indexedDb';
import { entityByName } from '../metadata';

export const fetchAddEntityToStorage = (entityName, payload) => {
    var table;
    const entity = entityByName(entityName);
    if (entity) {
        table = indexedDb.table(entity.storageTableName);

        return table.put(entity.addToStoragePayload(payload))
            .then(() => {
                return { ...payload }
            })
            .catch(error => {
                //throw error;
            })
            .then(
                response => ({ response }),
                error => ({ error: error.message })
            );
    } else {
        let error = 'Error adding entity to storage! Undefined entity';
        throw error;
    }
}