import * as actions from '../../../actions/actionRequestTypes';

const { createRequestTypes, action } = actions;

export const LOAD_APP_VERSION_FROM_STORAGE = 'LOAD_APP_VERSION_FROM_STORAGE';
export const loadAppVersionFromStorage = (appVersion) => action(LOAD_APP_VERSION_FROM_STORAGE, {appVersion});

export const GET_APP_VERSION_FROM_STORAGE = createRequestTypes('GET_APP_VERSION_FROM_STORAGE');
export const getAppVersionFromStorage = {
    request: () => action(GET_APP_VERSION_FROM_STORAGE.REQUEST),
    success: (id, response) => action(GET_APP_VERSION_FROM_STORAGE.SUCCESS, {response}),
    failure: (id, error) => action(GET_APP_VERSION_FROM_STORAGE.FAILURE, {error})
}

export const UPDATE_APP_VERSION_STORAGE = 'UPDATE_APP_VERSION_STORAGE';
export const updateAppVersionStorage = (appVersion) => action(UPDATE_APP_VERSION_STORAGE, {appVersion});