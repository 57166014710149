import { take, call } from 'redux-saga/effects';
import { signedInUsersStorageActions } from '../actions';
import { fetchSignedInUsersFromStorageService } from '../indexedDbStorage';
import { fetchEntity } from '../../../../sagas/sagaHelper';

// bind Generators
const fetchSignedInUsersFromStorage = fetchEntity.bind(null, signedInUsersStorageActions.getSignedInUsersFromStorage, fetchSignedInUsersFromStorageService.fetchSignedInUsersFromStorage);

export const watchLoadSignedInUsersFromStorage = function* () {
    while(true) {
        yield take(signedInUsersStorageActions.LOAD_SIGNEDIN_USERS_FROM_STORAGE);
        yield call(fetchSignedInUsersFromStorage);
    }
}