import { createSelector } from 'reselect';
import { getBibleVersions } from './getBibleVersionsSelector';

const getSelectedBibleVersion = state => state.bibleVersion;

export const getBibleVersionBySelectedVersionId = createSelector(
    [getBibleVersions, getSelectedBibleVersion],
    (bibleVersions, selectedBibleVersion) => {
        const filteredBibleVersions = bibleVersions.filter(bibleVersion => bibleVersion.versionId === selectedBibleVersion.id);
        return filteredBibleVersions[0];
    }
)