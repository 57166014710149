import { changePasswordActions } from '../auth/ChangePassword/actions';
import { forgotPasswordActions } from '../auth/ForgotPassword/actions';
import { loginActions } from '../auth/Login/actions';
import { registerActions } from '../auth/Register/actions';
import { updateUserInfoActions } from '../auth/UpdateUserInfo/actions';

const { CHANGE_PASSWORD } = changePasswordActions;
const { FORGOT_PASSWORD } = forgotPasswordActions;
const { LOGIN } = loginActions;
const { REGISTER } = registerActions;
const { UPDATE_USER_INFO } = updateUserInfoActions;

const initialState = {isProcessing: false};
export const appStatus = (state = initialState, action) => {
    const { type } = action;

    switch(type) {
        case LOGIN.REQUEST:
        case REGISTER.REQUEST:
        case UPDATE_USER_INFO.REQUEST:
        case CHANGE_PASSWORD.REQUEST:
        case FORGOT_PASSWORD.REQUEST:
            return {
                ...state,
                isProcessing: true
            }
        case LOGIN.SUCCESS:
        case LOGIN.FAILURE:
        case REGISTER.SUCCESS:
        case REGISTER.FAILURE:
        case UPDATE_USER_INFO.SUCCESS:
        case UPDATE_USER_INFO.FAILURE:
        case CHANGE_PASSWORD.SUCCESS:
        case CHANGE_PASSWORD.FAILURE:
        case FORGOT_PASSWORD.SUCCESS:
        case FORGOT_PASSWORD.FAILURE:
            return {
                ...state,
                isProcessing: false
            }
        default:
            return state;
    }
}