import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const UpdateUserInfoPanel = props => {
    const classes = useStyles();
    const [ firstnameFocused, setFirstnameFocused] = useState(false);

    const { isProcessing, firstname, lastname, displayName, email } = props;

    const { onFirstnameChange, onLastnameChange, onDisplayNameChange, 
        onEmailChange, onSubmitUpdateUserInfoClick } = props;

    let firstnameInput;

    const setFocus = () => {
        firstnameInput.focus();
        setFirstnameFocused(true);
    }

    useEffect(() => {
        if (!firstnameFocused) {
            setFocus();
        }        
    })

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <form className={classes.form} onSubmit={onSubmitUpdateUserInfoClick} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="firstname-input"
                        label="First name"
                        name="firstname-input"
                        autoComplete="firstname-input"
                        autoFocus={true}
                        inputRef={ input => {firstnameInput = input;} }                      
                        value={firstname}
                        onChange={onFirstnameChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="lastname-input"
                        label="Last name"
                        name="lastname-input"
                        autoComplete="lastname-input"                      
                        value={lastname}
                        onChange={onLastnameChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="displayname-input"
                        label="Display name"
                        name="displayname-input"
                        autoComplete="displayname-input"
                        helperText="Name displayed in app"                                              
                        value={displayName}
                        onChange={onDisplayNameChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email-input"
                        label="Email"
                        name="email-input"
                        autoComplete="email-input"
                        helperText="To use when changing forgotten password, ..."                      
                        value={email}
                        onChange={onEmailChange}
                    />                    
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={isProcessing} 
                        type="submit"
                    >
                        Update
                    </Button>                    
                </form>
            </div>
        </Container>
    );
}

UpdateUserInfoPanel.propTypes = {
    isProcessing: PropTypes.bool.isRequired,
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    onFirstnameChange: PropTypes.func.isRequired,
    onLastnameChange: PropTypes.func.isRequired,
    onDisplayNameChange: PropTypes.func.isRequired,
    onEmailChange: PropTypes.func.isRequired,
    onSubmitUpdateUserInfoClick: PropTypes.func.isRequired
}

export default UpdateUserInfoPanel;