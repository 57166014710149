import React from 'react';
import Typography from '@material-ui/core/Typography';

const Settings = () => (
  <div>
    <Typography paragraph>
      Coming soon :)
    </Typography>    
  </div>
)

export default Settings