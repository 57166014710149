import React from 'react';
import PropTypes from 'prop-types';
import BibleHistoryItem from './Item';

const BibleHistoryPanel = props => {
    const { historyList } = props;

    return (
        <div>
            {historyList.map(historyItem => (<BibleHistoryItem historyItem={historyItem} key={historyItem.readDateTimeLt} />))}
        </div>
    )
}

BibleHistoryPanel.propTypes = {
    historyList: PropTypes.array.isRequired,
}

export default BibleHistoryPanel;
