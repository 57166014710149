import * as ActionTypes from './actions';
import { DEFAULT_VERSIONID, DEFAULT_LANGID } from './constants';

const { GET_BIBLE_VERSIONS_FROM_STORAGE } = ActionTypes.getBibleVersionsFromStorageActions;
const { GET_BIBLE_VERSIONS_FROM_API } = ActionTypes.getBibleVersionsFromApiActions;
const { GET_VIEWFILTERS_BIBLEVERSION_FROM_STORAGE } = ActionTypes.getViewFiltersBibleVersionFromStorageActions;

export const bibleVersion = (state = {id: DEFAULT_VERSIONID, langId: DEFAULT_LANGID, isLoading: false, isLoadingSuccessful: false}, action) => {    
    const { type } = action

    if (type === ActionTypes.SET_BIBLE_VERSION) {
        return {
            ...state,
            id: action.versionId,
            langId: action.langId
        }
    } else if (type === GET_VIEWFILTERS_BIBLEVERSION_FROM_STORAGE.SUCCESS) {
        return {
            ...state,
            id: action.response.bibleVersion.versionId,
            langId: action.response.bibleVersion.langId
        }
    }  else if ((type === GET_BIBLE_VERSIONS_FROM_STORAGE.REQUEST) || (type === GET_BIBLE_VERSIONS_FROM_API.REQUEST)) {
        return {
            ...state,
            isLoading: true
        }
    } else if ((type === GET_BIBLE_VERSIONS_FROM_STORAGE.SUCCESS) || (type === GET_BIBLE_VERSIONS_FROM_API.SUCCESS)) {
        return {
            ...state,
            isLoading: false,
            isLoadingSuccessful: true
        }
    } else if ((type === GET_BIBLE_VERSIONS_FROM_STORAGE.FAILURE) || (type === GET_BIBLE_VERSIONS_FROM_API.FAILURE)) {
        return {
            ...state,
            isLoading: false,
            isLoadingSuccessful: false
        }
    }

    return state
}