import React from 'react';
import HighlightedWords from './HighlightedWords';
import FavoriteWords from './FavoriteWords';
import VerseOfTheDayWords from './VerseOfTheDayWords';
import SearchWords from './SearchWords';

const StyledVerse = ({ text = '', highlight = '', search = '', favorite = '', verseOfTheDay = '' }) => {
    const styledTagsList = [];
    const tagsList = [];
    const shouldProcessHighlightedText = highlight.trim() ? true : false;
    const shouldProcessFavoriteText = favorite.trim() ? true : false;
    const shouldProcessVerseOfTheDayText = verseOfTheDay.trim() ? true : false;
    const shouldProcessSearchText = search.trim() ? true : false;

    const AddStyledElementToTagsList = (tag, textToStyle) => {
        var elementStart = text.indexOf(textToStyle);
        var elementEnd = elementStart + textToStyle.length;

        if (elementStart >= 0)
          tagsList.push({element: tag, start: elementStart, end: elementEnd, text: textToStyle});
    }

    const PopulateTagsListWithStyledElements = () => {
        if (shouldProcessHighlightedText) 
            AddStyledElementToTagsList('<HighlightedWords>', highlight);
    
        if (shouldProcessVerseOfTheDayText) 
            AddStyledElementToTagsList('<VerseOfTheDayWords>', verseOfTheDay);
    }

    const SortTagsList = () => {
        tagsList.sort((a, b) => {
            let comparison = 0;
            if (a.start > b.start)        
                comparison = 1
            else if (a.start < b.start)
                comparison = -1;
    
            return comparison;
        })
    }

    const PopulateStyledTagsList = () => {
        var lastStyledTagEnd = 0;
        tagsList.forEach((tag, i) => {
            if (tag.start > lastStyledTagEnd) {
                styledTagsList.push({ element: '<span>', start: lastStyledTagEnd, end: tag.start, text: text.substring(lastStyledTagEnd, tag.start) })
            }

            if (tag.end > lastStyledTagEnd) {
                var highlightedText = '';
                var favoriteText = '';
                var verseOfTheDayText = '';
                var searchedText = '';
                var j = i + 1;
                
                while ((j < tagsList.length) && (tagsList[j].start < tag.end)) {
                    var internalElementEnd = tagsList[j].end < tag.end ? tagsList[j].end : tag.end
                    switch (tagsList[j].element) {
                        case '<HighlightedWords>': { highlightedText = text.substring(tagsList[j].start, internalElementEnd) }
                        case '<FavoriteWords>': { }
                        case '<VerseOfTheDayWords>': { verseOfTheDayText = text.substring(tagsList[j].start, internalElementEnd) }
                        case '<SearchWords>': {}
                    }

                    j++;
                }

                var styledTagStart = tag.start < lastStyledTagEnd ? lastStyledTagEnd : tag.start; 
                styledTagsList.push({ 
                    element: tag.element, 
                    start: styledTagStart, 
                    end: tag.end, 
                    text: text.substring(styledTagStart, tag.end),
                    highlight: highlightedText, 
                    verseOfTheDay: verseOfTheDayText 
                });

                lastStyledTagEnd = tag.end;
            }            
        })

        if (lastStyledTagEnd > 0 && lastStyledTagEnd < text.length)
            styledTagsList.push({ 
                element: '<span>', 
                start: lastStyledTagEnd, 
                end: text.length, 
                text: text.substring(lastStyledTagEnd, text.length) });
    }

    PopulateTagsListWithStyledElements(); 
    SortTagsList(); 
    PopulateStyledTagsList();  
    
    //console.log('tagsList:');
    //console.log(tagsList);
  
    //console.log('styledTagsList:');
    //console.log(styledTagsList);

    return (
        <span>
            {
                styledTagsList.length === 0 ? text
                : styledTagsList.map((tag, i) => {
                    switch (tag.element) {
                        case '<HighlightedWords>' : return <HighlightedWords key={i} text={tag.text} verseOfTheDay={tag.verseOfTheDay} />
                        case '<FavoriteWords>' : return <FavoriteWords key={i} text={tag.text} highlight={tag.highlight} verseOfTheDay={tag.verseOfTheDay} />
                        case '<VerseOfTheDayWords>' : return <VerseOfTheDayWords key={i} text={tag.text} highlight={tag.highlight} />
                        case '<SearchWords>' : return <SearchWords key={i} text={tag.text} highlight={tag.highlight} verseOfTheDay={tag.verseOfTheDay} />
                        default: return <span key={i}>{tag.text}</span>
                    }
                })
            }
        </span>
    )
}

export default StyledVerse;