import { call  } from 'redux-saga/effects';
import { verseOfTheDayListFromApiActions } from '../actions';
import { fetchVerseOfTheDayListApi, fetchUpdatedVerseOfTheDayListApi } from '../api/verseOfTheDayListApi';
import { fetchEntity } from '../../../sagas/sagaHelper';

// bind Generators
const fetchVerseOfTheDayList = fetchEntity.bind(null, verseOfTheDayListFromApiActions.getVerseOfTheDayListFromApi, fetchVerseOfTheDayListApi);
const fetchUpdatedVerseOfTheDayList = fetchEntity.bind(null, verseOfTheDayListFromApiActions.getVerseOfTheDayListFromApi, fetchUpdatedVerseOfTheDayListApi);

export const getVerseOfTheDayListFromApi = function* (filter) {
    let bibleVersionId = filter['versionId'];
    let modifiedDateTimeUtc = filter['modifiedDateTimeUtc'];

    if (modifiedDateTimeUtc) {
        yield call(fetchUpdatedVerseOfTheDayList, bibleVersionId, modifiedDateTimeUtc);
    }
    else {
        yield call(fetchVerseOfTheDayList, bibleVersionId);
    }    
}