import * as actions from '../../../../actions/actionRequestTypes';

import * as _bibleChaptersFromApiActions from './bibleChaptersFromApiActions';
import * as _getBookChaptersFromStorageActions from './getBookChaptersFromStorageActions';
import * as _getBookChaptersFromApiActions from './getBookChaptersFromApiActions';
import * as _viewFiltersBookChapterFromStorageActions from './viewFiltersBookChapterFromStorageActions';

export const bibleChaptersFromApiActions = _bibleChaptersFromApiActions;
export const getBookChaptersFromStorageActions = _getBookChaptersFromStorageActions;
export const getBookChaptersFromApiActions = _getBookChaptersFromApiActions;
export const viewFiltersBookChapterFromStorageActions = _viewFiltersBookChapterFromStorageActions;

const { action } = actions;

export const LOAD_BOOK_CHAPTERS = 'LOAD_BOOK_CHAPTERS';
export const loadBookChapters = (bibleVersionId, bookId) => action(LOAD_BOOK_CHAPTERS, {bibleVersionId, bookId});

export const SET_BOOK_CHAPTER = 'SET_BOOK_CHAPTER';
export const setBookChapter = chapterId => action(SET_BOOK_CHAPTER, {chapterId});

export const REQUEST_NEXT_CHAPTER = 'REQUEST_NEXT_CHAPTER';
export const requestNextChapter = () => action(REQUEST_NEXT_CHAPTER);

export const REQUEST_PREV_CHAPTER = 'REQUEST_PREV_CHAPTER';
export const requestPrevChapter = () => action(REQUEST_PREV_CHAPTER);

export const UPDATE_CHAPTERS_STORAGE = 'UPDATE_CHAPTERS_STORAGE';
export const updateChaptersStorage = (chapters) => action(UPDATE_CHAPTERS_STORAGE, {chapters});