import { take, put, select, all } from 'redux-saga/effects';
import * as bibleVersionsActions from '../actions';
import * as bibleBooksActions from '../../Books/actions';
import * as chaptersActions from '../../Chapters/actions';
import { chapterVersesActions } from '../../Verses/actions';

const getBibleVersion = state => state.bibleVersion;
const getBibleBook = state => state.bibleBook;
const getBookChapter = state => state.bookChapter;

export const watchSetBibleVersion = function* () {
    while(true) {
        const {versionId, langId} = yield take(bibleVersionsActions.SET_BIBLE_VERSION);
        yield put(bibleVersionsActions.updateViewFiltersBibleVersionStorage(versionId, langId));

        let bibleVersion = yield select(getBibleVersion);
        let bibleBook = yield select(getBibleBook);
        let bookChapter = yield select(getBookChapter);
        
        yield all ([
            put(bibleBooksActions.loadBibleBooks(bibleVersion.id)),
            put(chaptersActions.loadBookChapters(bibleVersion.id, bibleBook.id)),
            put(chapterVersesActions.getChapterVerses.request(bibleVersion.id, bibleBook.id, bookChapter.id))
        ]);
    }
}