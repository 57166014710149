import * as actions from '../../../../actions/actionRequestTypes';

const { createRequestTypes, action } = actions;

export const LOAD_CHAPTER_VERSES_FROM_STORAGE = 'LOAD_CHAPTER_VERSES_FROM_STORAGE';
export const loadChapterVersesFromStorage = (bibleVersionId, bookId, chapterId) => action(LOAD_CHAPTER_VERSES_FROM_STORAGE, {bibleVersionId, bookId, chapterId});

export const GET_CHAPTER_VERSES_FROM_STORAGE = createRequestTypes('GET_CHAPTER_VERSES_FROM_STORAGE');

export const getChapterVersesFromStorage = {
    request: () => action(GET_CHAPTER_VERSES_FROM_STORAGE.REQUEST),
    success: (id, response) => action(GET_CHAPTER_VERSES_FROM_STORAGE.SUCCESS, {response}),
    failure: (id, error) => action(GET_CHAPTER_VERSES_FROM_STORAGE.FAILURE, {error})
}