import { take, fork, call, put } from 'redux-saga/effects';
import { forgotPasswordActions } from '../actions';
import { fetchSubmitForgotPasswordRequestApi } from '../api/forgotPasswordApi';
import { fetchEntity } from '../../../../sagas/sagaHelper';
import { setAlertMessage } from '../../../../actions';
import { ALERT_TYPE_SUCCESS } from '../../../../constants';

// bind Generators
const fetchSubmitForgotPasswordRequest = fetchEntity.bind(null, forgotPasswordActions.forgotPassword, fetchSubmitForgotPasswordRequestApi);

function* submitForgotPassword(encodedCredentials) {
    yield call(fetchSubmitForgotPasswordRequest, encodedCredentials);
}

export const watchSubmitForgotPasswordRequest = function* () {
    const { FORGOT_PASSWORD } = forgotPasswordActions;

    while(true) {
        const {encodedCredentials} = yield take(forgotPasswordActions.SUBMIT_FORGOT_PASSWORD_REQUEST);
        yield fork(submitForgotPassword, encodedCredentials);

        const action = yield take([FORGOT_PASSWORD.SUCCESS, FORGOT_PASSWORD.FAILURE]);
        if (action.type === FORGOT_PASSWORD.SUCCESS) {     
            yield put(setAlertMessage(ALERT_TYPE_SUCCESS, 'An email has been sent to email account on file!'));     
            window.history.back();             
        }
    }
}