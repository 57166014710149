import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Swipeable } from 'react-swipeable';
import VerseOfTheDayPanel from './Panel';
import { verseOfTheDayActions } from './actions';
import { getSelectedVerseOfTheDay } from './selectors/getVerseOfTheDaySelector';
//import { requestNextChapter, requestPrevChapter } from '../Chapters/actions';

const { getVerseOfTheDay } = verseOfTheDayActions;

class VerseOfTheDay extends Component {
    static propTpes = {
        isLoading: PropTypes.bool.isRequired,
        isLoadingSuccessful: PropTypes.bool.isRequired,
        bibleVersionId: PropTypes.number.isRequired,
        bookName: PropTypes.string.isRequired,
        chapterNumber: PropTypes.string.isRequired,
        verseNumber: PropTypes.string.isRequired,
        verseText: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        //requestNextChapter: PropTypes.func.isRequired,
        //requestPrevChapter: PropTypes.func.isRequired,
        getVerseOfTheDay: PropTypes.func.isRequired
    }

    componentDidMount() {
        const { bibleVersionId, date, getVerseOfTheDay } = this.props;
        getVerseOfTheDay(bibleVersionId);
    }
    
    handleSwipingLeft = event => {
        /* const { bibleVersionId, requestPrevChapter, requestNextChapter } = this.props;

         if (bibleVersionId === 1) {
            requestPrevChapter();
        } else {
            requestNextChapter();
        }  */
    };

    handleSwipingRight = event => {
        /* const { bibleVersionId, requestPrevChapter, requestNextChapter } = this.props;
        
         if (bibleVersionId === 1) {
            requestNextChapter();
        } else {
            requestPrevChapter();
        }  */
    };

    render() {
        const { isLoading, isLoadingSuccessful, bibleVersionId, verseText, bookName, chapterNumber, verseNumber, date } = this.props;

        return (
            <div>
                <Swipeable
                    onSwipedLeft={this.handleSwipingLeft}
                    onSwipedRight={this.handleSwipingRight}>                    
                    <VerseOfTheDayPanel isLoading={isLoading}
                        isLoadingSuccessful={isLoadingSuccessful}
                        bibleVersionId={bibleVersionId}
                        verseText={verseText}
                        bookName={bookName}
                        chapterNumber={chapterNumber}
                        verseNumber={verseNumber}
                        date={date} />
                </Swipeable>                
            </div>
        );        
    }
}

const mapStateToProps = (state, ownProps) => {
    const {
        bibleVersion,
        verseOfTheDay
    } = state
    
    const bibleVersionId = bibleVersion.id;
    const isLoading = verseOfTheDay.isLoading;
    const isLoadingSuccessful = verseOfTheDay.isLoadingSuccessful;
    const selectedVerseOfTheDay = getSelectedVerseOfTheDay(state);     
    const verseText = selectedVerseOfTheDay ? selectedVerseOfTheDay.verseText : '';
    const verseNumber = selectedVerseOfTheDay ? selectedVerseOfTheDay.verseNumber : '';
    const bookName = selectedVerseOfTheDay ? selectedVerseOfTheDay.bookName : '';
    const chapterNumber = selectedVerseOfTheDay ? selectedVerseOfTheDay.chapterNumber : '';
    const date = verseOfTheDay.date;        
    
    return {
        bibleVersionId,
        isLoading,
        isLoadingSuccessful,
        verseText,
        bookName,
        chapterNumber,
        verseNumber,
        date
    }
}

export default withRouter(connect(mapStateToProps, {
    getVerseOfTheDay
})(VerseOfTheDay));