import { take, fork, select, put } from 'redux-saga/effects';
import { verseOfTheDayActions } from '../actions';
import _ from 'lodash';

const getVerseOfTheDayList = (state) => _.values(state.entities.verseOfTheDay);

function getRndInteger(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) ) + min;
  }

function* generateVerseOfTheDay(date) {
    let verseOfTheDayList = yield select(getVerseOfTheDayList);

    let verseIndex = getRndInteger(0, verseOfTheDayList.length - 1);
    let verseOfTheDay = verseOfTheDayList[verseIndex];

    if (verseOfTheDay) {        
        yield put(verseOfTheDayActions.savePersonalVerseOfTheDayToStorage(date, verseOfTheDay.verseId));
        let response = { verseOfTheDay };
        response = { ...response, date }
        yield put(verseOfTheDayActions.generateVerseOfTheDay.success(verseOfTheDay.verseVersionId, response));
        //sync to server        
    }
    else {
        yield put(verseOfTheDayActions.generateVerseOfTheDay.failure(null, { error: 'Error encountered while generating verse of the day!' }))
    }    
}

export const watchGenerateVerseOfTheDay = function* () {
    while(true) {
        const {date} = yield take(verseOfTheDayActions.GENERATE_VERSEOFTHEDAY.REQUEST);
        yield fork(generateVerseOfTheDay, date);        
    }
}