import React from 'react';


const SearchWords = ({ text = '', highlight = '', search = '', favorite = '', verseOfTheDay = '' }) => {
    return (
        <span className='searchedText'>
            {text}
        </span>
    )
}

export default SearchWords;