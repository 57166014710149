import { take, fork, call, put } from 'redux-saga/effects';
import { changePasswordActions } from '../actions';
import { fetchSubmitChangePasswordRequestApi } from '../api/changePasswordApi';
import { fetchEntity } from '../../../../sagas/sagaHelper';
import { setAlertMessage } from '../../../../actions';
import { ALERT_TYPE_SUCCESS } from '../../../../constants';

// bind Generators
const fetchSubmitChangePasswordRequest = fetchEntity.bind(null, changePasswordActions.changePassword, fetchSubmitChangePasswordRequestApi);

function* submitChangePassword(encodedCredentials) {
    yield call(fetchSubmitChangePasswordRequest, encodedCredentials);
}

export const watchSubmitChangePasswordRequest = function* () {
    const { CHANGE_PASSWORD } = changePasswordActions;

    while(true) {
        const {encodedCredentials} = yield take(changePasswordActions.SUBMIT_CHANGE_PASSWORD_REQUEST);
        yield fork(submitChangePassword, encodedCredentials);

        const action = yield take([CHANGE_PASSWORD.SUCCESS, CHANGE_PASSWORD.FAILURE]);
        if (action.type === CHANGE_PASSWORD.SUCCESS) {
            yield put(setAlertMessage(ALERT_TYPE_SUCCESS, 'Password changed successfully!'));   
        }
    }
}