import { normalize } from 'normalizr';
import { bibleVersionStorageSchemaArray } from '../schema';
import { loadBibleVersionsFromLocalStore } from './index';

export const fetchBibleVersionsFromStorageService = () => {
    return loadBibleVersionsFromLocalStore()
        .then(bibleVersions => {        
            if (bibleVersions.length > 0) {
                const normalized = normalize(bibleVersions, bibleVersionStorageSchemaArray);
                return {...normalized}
            }
            else {
            }
        })
        .catch(error => {
        })
        .then(
            response => ({ response }),
            error => ({ error: error.message })
        );         
}