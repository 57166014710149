import * as actions from '../../../../actions/actionRequestTypes';

const { createRequestTypes, action } = actions;

export const LOAD_VIEWFILTERS_BIBLEBOOK_FROM_STORAGE = 'LOAD_VIEWFILTERS_BIBLEBOOK_FROM_STORAGE';
export const loadViewFiltersBibleBookFromStorage = () => action(LOAD_VIEWFILTERS_BIBLEBOOK_FROM_STORAGE);

export const GET_VIEWFILTERS_BIBLEBOOK_FROM_STORAGE = createRequestTypes('GET_VIEWFILTERS_BIBLEBOOK_FROM_STORAGE');
export const getViewFiltersBibleBookFromStorage = {
    request: () => action(GET_VIEWFILTERS_BIBLEBOOK_FROM_STORAGE.REQUEST),
    success: (id, response) => action(GET_VIEWFILTERS_BIBLEBOOK_FROM_STORAGE.SUCCESS, {response}),
    failure: (id, error) => action(GET_VIEWFILTERS_BIBLEBOOK_FROM_STORAGE.FAILURE, {error})
}

export const UPDATE_VIEWFILTERS_BIBLEBOOK_STORAGE = 'UPDATE_VIEWFILTERS_BIBLEBOOK_STORAGE';
export const updateViewFiltersBibleBookStorage = (bookId) => action(UPDATE_VIEWFILTERS_BIBLEBOOK_STORAGE, {bookId});