import { fork, all } from 'redux-saga/effects';
import { watchAddEntity } from './addEntitySaga';
import { watchGetEntityList } from './getEntityListSaga';
import { watchLoadEntityListFromStorage } from './getEntityListFromStorageSaga';
import { watchLoadLastUpdatedEntityListSettingFromStorage } from './getLastUpdatedEntityListSettingFromStorageSaga';
import { watchLoadEntityListFromApi } from './getEntityListFromApiSaga';
import { watchSaveEntityListToStorage } from './saveEntityListToStorageSaga';
import { watchSyncEntityListToTheCloud } from './syncEntityListToTheCloudSaga';
import { watchSyncEntityListFromTheCloud } from './syncEntityListFromTheCloudSaga';

export const watchEntitiesRequests = function*(entityName) {
    yield all([
        fork(watchAddEntity, entityName),
        fork(watchGetEntityList, entityName),
        fork(watchLoadEntityListFromStorage, entityName),
        fork(watchLoadLastUpdatedEntityListSettingFromStorage, entityName),
        fork(watchLoadEntityListFromApi, entityName),
        fork(watchSaveEntityListToStorage, entityName), 
        fork(watchSyncEntityListToTheCloud, entityName),
        fork(watchSyncEntityListFromTheCloud, entityName),         
    ])
}