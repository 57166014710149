import * as actions from '../../../../actions/actionRequestTypes';

const { createRequestTypes, action } = actions;

export const LOAD_VIEWFILTERS_BOOKCHAPTER_FROM_STORAGE = 'LOAD_VIEWFILTERS_BOOKCHAPTER_FROM_STORAGE';
export const loadViewFiltersBookChapterFromStorage = () => action(LOAD_VIEWFILTERS_BOOKCHAPTER_FROM_STORAGE);

export const GET_VIEWFILTERS_BOOKCHAPTER_FROM_STORAGE = createRequestTypes('GET_VIEWFILTERS_BOOKCHAPTER_FROM_STORAGE');
export const getViewFiltersBookChapterFromStorage = {
    request: () => action(GET_VIEWFILTERS_BOOKCHAPTER_FROM_STORAGE.REQUEST),
    success: (id, response) => action(GET_VIEWFILTERS_BOOKCHAPTER_FROM_STORAGE.SUCCESS, {response}),
    failure: (id, error) => action(GET_VIEWFILTERS_BOOKCHAPTER_FROM_STORAGE.FAILURE, {error})
}

export const UPDATE_VIEWFILTERS_BOOKCHAPTER_STORAGE = 'UPDATE_VIEWFILTERS_BOOKCHAPTER_STORAGE';
export const updateViewFiltersBookChapterStorage = (chapterId) => action(UPDATE_VIEWFILTERS_BOOKCHAPTER_STORAGE, {chapterId});