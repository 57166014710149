import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import LinearProgress from '@material-ui/core/LinearProgress';
//import AccountCircle from '@material-ui/icons/AccountCircle';
//import MoreIcon from '@material-ui/icons/MoreVert';
import { drawerWidth } from './Drawer';
import * as Routes from '../../constants';
import LoginHeader from '../auth/Login/Header';
import RegisterHeader from '../auth/Register/Header';
import UpdateUserInfoHeader from '../auth/UpdateUserInfo/Header';
import ChangePasswordHeader from '../auth/ChangePassword/Header';
import ForgotPasswordHeader from '../auth/ForgotPassword/Header';
import VerseOfTheDayHeader from '../VerseOfTheDay/Header';
import BibleHeader from '../Bible/Header';
import FavoriteVersesHeader from '../Bible/components/FavoriteVersesHeader';
import TopicsHeader from './components/TopicsHeader';
import SettingsHeader from './components/SettingsHeader';
import CopyrightsHeader from './components/CopyrightsHeader';
import ReleaseNotesHeader from './components/ReleaseNotesHeader';
import SignInAccountMenu from '../auth/SignInAccountMenu';
import SignOutAccountMenu from '../auth/SignOutAccountMenu';

import Typography from '@material-ui/core/Typography';

import SvgIcon from '@material-ui/core/SvgIcon';
import BibleHistoryHeader from '../BibleHistory/Header';
import { AlternateEmail } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        marginLeft: drawerWidth,
        background: "#0d1e2d",
        opacity: ".8",
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
        },
    },
    menuButton: {
        marginRight: 20,
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    grow: {
      flexGrow: 1,
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        }, 
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    loggedOut: {
        fill: '#FF0000',        
    },
    loggedIn: {
        fill: '#00FF00',        
    },
    loggingIn: {
        animation: '$userLoggingIn 1s infinite alternate'
    },
    '@keyframes userLoggingIn': {
        from : {
            fill: '#FF0000'
          },
        to : {
            fill: '#00FF00'
          },          
    },
}));

function AccountIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z" />
        </SvgIcon>
    );
}

const HomePageAppBar = props => {
    const classes = useStyles();
    const { isLoggedIn, userLoggingIn, userDisplayName, isProcessing, onHandleDrawerToggle } = props

    const [signInAccountAnchorEl, setSignInAccountAnchorEl] = useState(null);
    const [signOutAccountAnchorEl, setSignOutAccountAnchorEl] = useState(null);
    //const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

    //const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleSignInAccountMenuOpen = event => {
        setSignInAccountAnchorEl(event.currentTarget);
    }

    const handleSignOutAccountMenuOpen = event => {
        setSignOutAccountAnchorEl(event.currentTarget);
    }

    //const handleMobileMenuOpen = event => {
        //setMobileMoreAnchorEl(event.currentTarget);
    //}

    const handleMobileMenuClose = () => {
        //setMobileMoreAnchorEl(null);
    }

    const handleAccountMenuClose = () => {
        setSignInAccountAnchorEl(null);
        setSignOutAccountAnchorEl(null);
        handleMobileMenuClose();        
    }    

    // const mobileMenuId = 'more-menu-mobile';
    // const renderMobileMenu = (
    //     <Menu
    //         anchorEl={mobileMoreAnchorEl}
    //         anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    //         id={mobileMenuId}
    //         keepMounted
    //         transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    //         open={isMobileMenuOpen}
    //         onClose={handleMobileMenuClose}
    //     >
    //         <MenuItem onClick={handleSignInAccountMenuOpen}>
    //             <IconButton
    //                 aria-label='account of current user'
    //                 aria-controls='signIn-account-menu'
    //                 aria-haspopup="true"
    //                 color="inherit"
    //             >
    //                 <AccountCircle />
    //             </IconButton>
    //             <p>Account</p>
    //         </MenuItem>
    //     </Menu>
    // );

    return (
        <div>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        onClick={onHandleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>

                    <div className={classes.grow}>
                        <Route path={Routes.ROUTES_LOGIN_PATHNAME} component={LoginHeader} />
                        <Route path={Routes.ROUTES_REGISTER_PATHNAME} component={RegisterHeader} />
                        <Route path={Routes.ROUTES_UPDATE_USER_INFO_PATHNAME} component={UpdateUserInfoHeader} />
                        <Route path={Routes.ROUTES_CHANGE_PASSWORD_PATHNAME} component={ChangePasswordHeader} />
                        <Route path={Routes.ROUTES_FORGOTPASSWORD_PATHNAME} component={ForgotPasswordHeader} />
                        <Route path={Routes.ROUTES_VERSEOFTHEDAY_PATHNAME} component={VerseOfTheDayHeader} />
                        <Route path={Routes.ROUTES_BIBLE_PATHNAME} component={BibleHeader} />
                        <Route path={Routes.ROUTES_READINGHISTORY_PATHNAME} component={BibleHistoryHeader} />
                        <Route path={Routes.ROUTES_FAVORITEVERSES_PATHNAME} component={FavoriteVersesHeader} />
                        <Route path={Routes.ROUTES_TOPICS_PATHNAME} component={TopicsHeader} />
                        <Route path={Routes.ROUTES_SETTINGS_PATHNAME} component={SettingsHeader} />
                        <Route path={Routes.ROUTES_COPYRIGHTS_PATHNAME} component={CopyrightsHeader} />
                        <Route path={Routes.ROUTES_RELEASENOTES_PATHNAME} component={ReleaseNotesHeader} />
                    </div>
                    <div>
                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            aria-controls="signIn-account-menu"
                            aria-haspopup="true"
                            onClick={isLoggedIn ? handleSignOutAccountMenuOpen : handleSignInAccountMenuOpen}
                            color="inherit"
                        >
                            <AccountIcon className={isLoggedIn ? classes.loggedIn : 
                                userLoggingIn ? classes.loggingIn : classes.loggedOut} />
                            <Typography paragraph variant="caption">
                                {userDisplayName}                                
                            </Typography>
                        </IconButton>
                    </div>
                    {/* <div className={classes.sectionDesktop}>
                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            aria-controls="signIn-account-menu"
                            aria-haspopup="true"
                            onClick={isLoggedIn ? handleSignOutAccountMenuOpen : handleSignInAccountMenuOpen}
                            color="inherit"
                        >
                            <AccountIcon className={isLoggedIn ? classes.loggedIn : classes.loggedOut} />
                            <Typography paragraph variant="caption">
                                {userDisplayName}                                
                            </Typography>
                        </IconButton>
                    </div>
                    <div className={classes.sectionMobile}>
                        <IconButton
                            aria-label="show more"
                            aria-controls="more-menu-mobile"
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <AccountIcon className={isLoggedIn ? classes.loggedIn : classes.loggedOut} />
                            <MoreIcon />
                        </IconButton>
                    </div> */} 
                </Toolbar> 
                {isProcessing && <LinearProgress color="secondary" />}               
            </AppBar>            
            <SignInAccountMenu signInAccountAnchorEl={signInAccountAnchorEl}
                onAccountMenuClose={handleAccountMenuClose} />   
            <SignOutAccountMenu signOutAccountAnchorEl={signOutAccountAnchorEl}
                onAccountMenuClose={handleAccountMenuClose} />        
            {/* {renderMobileMenu} */}
        </div>
    );
}

HomePageAppBar.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    userLoggingIn: PropTypes.bool.isRequired,
    userDisplayName: PropTypes.string.isRequired,
    isProcessing: PropTypes.bool.isRequired,
    onHandleDrawerToggle: PropTypes.func.isRequired,
}

export default HomePageAppBar;