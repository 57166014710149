import { take, call, fork } from 'redux-saga/effects';
import { viewFiltersBookChapterFromStorageActions } from '../actions';
import { saveViewFiltersBookChapterToLocalStoreDb } from '../indexedDbStorage';

function* updateLocalStorage(chapterId) {
    yield call(saveViewFiltersBookChapterToLocalStoreDb.saveViewFiltersBookChapterToLocalStore, chapterId);
}

export const watchUpdateViewFiltersBookChapterLocalStorage = function* () {
    while(true) {
        const {chapterId} = yield take(viewFiltersBookChapterFromStorageActions.UPDATE_VIEWFILTERS_BOOKCHAPTER_STORAGE);
        yield fork(updateLocalStorage, chapterId);
    }
}