import * as ActionTypes from './actions';

const { GET_BOOK_CHAPTERS_FROM_STORAGE } = ActionTypes.getBookChaptersFromStorageActions;
const { GET_BOOK_CHAPTERS_FROM_API } = ActionTypes.getBookChaptersFromApiActions;
const { GET_BIBLE_CHAPTERS_FROM_API } = ActionTypes.bibleChaptersFromApiActions
const { GET_VIEWFILTERS_BOOKCHAPTER_FROM_STORAGE } = ActionTypes.viewFiltersBookChapterFromStorageActions;

export const bookChapter = (state = {id: 0, isLoading: false, isLoadingSuccessful: false}, action) => {    
    const { type, chapterId } = action

    if (type === ActionTypes.SET_BOOK_CHAPTER) {
        return {
            ...state,
            id: chapterId
        }
    } else if (type === GET_VIEWFILTERS_BOOKCHAPTER_FROM_STORAGE.SUCCESS) {
        return {
            ...state,
            id: action.response.bookChapter.chapterId
        }
    }  else if ((type === GET_BOOK_CHAPTERS_FROM_STORAGE.REQUEST) || (type === GET_BOOK_CHAPTERS_FROM_API.REQUEST) || (type === GET_BIBLE_CHAPTERS_FROM_API.REQUEST)) {
        return {
            ...state,
            isLoading: true
        }
    } else if ((type === GET_BOOK_CHAPTERS_FROM_STORAGE.SUCCESS) || (type === GET_BOOK_CHAPTERS_FROM_API.SUCCESS) || (type === GET_BIBLE_CHAPTERS_FROM_API.SUCCESS)) {
        return {
            ...state,
            isLoading: false,
            isLoadingSuccessful: true
        }
    } else if ((type === GET_BOOK_CHAPTERS_FROM_STORAGE.FAILURE) || (type === GET_BOOK_CHAPTERS_FROM_API.FAILURE) || (type === GET_BIBLE_CHAPTERS_FROM_API.FAILURE)) {
        return {
            ...state,
            isLoading: false,
            isLoadingSuccessful: false
        }
    }

    return state
}