import { fork, all } from 'redux-saga/effects';
import { watchLoadBibleVersions } from './getBibleVersionsSaga';
import { watchLoadBibleVersionsFromStorage } from './getBibleVersionsFromStorageSaga';
import { watchLoadBibleVersionsFromApi } from './getBibleVersionsFromApiSaga';
import { watchSetBibleVersion } from './setBibleVersionSaga';
import { watchUpdateBibleVersionsLocalStorage } from './updateBibleVersionsLocalStorageSaga';
import { watchUpdateViewFiltersBibleVersionLocalStorage } from './updateViewFiltersBibleVersionLocalStoreSaga';
import { watchLoadViewFiltersBibleVersionFromStorage } from './getViewFiltersBibleVersionFromStorageSaga';

export const watchBibleVersionsRequests = function*() {
    yield all([
        fork(watchLoadBibleVersions),
        fork(watchLoadBibleVersionsFromStorage),
        fork(watchLoadBibleVersionsFromApi),
        fork(watchUpdateBibleVersionsLocalStorage),
        fork(watchSetBibleVersion),
        fork(watchUpdateViewFiltersBibleVersionLocalStorage),
        fork(watchLoadViewFiltersBibleVersionFromStorage)
    ])
}