import { take, call, fork, select, put } from 'redux-saga/effects';
import { bibleChaptersFromApiActions } from '../../Chapters/actions';
import { bibleVersesFromApiActions, updateVersesStorage } from '../actions';
import { fetchBibleVersesApi } from '../bibleVersesApi';
import { fetchEntity } from '../../../../sagas/sagaHelper';

// bind Generators
const fetchBibleVerses = fetchEntity.bind(null, bibleVersesFromApiActions.getBibleVersesFromApi, fetchBibleVersesApi);

const getApiUrl = state => state.systemSettings.apiUrl;

function* loadVerses(bibleVersionId) {
    let apiUrl = yield select(getApiUrl);
    
    yield call(fetchBibleVerses, apiUrl, bibleVersionId);
}

export const watchLoadBibleVersesFromApi = function* () {
    const { GET_BIBLE_VERSES_FROM_API } = bibleVersesFromApiActions;

    while(true) {
        const {bibleVersionId} = yield take(bibleVersesFromApiActions.LOAD_BIBLE_VERSES_FROM_API);
        yield put(bibleChaptersFromApiActions.loadBibleChaptersFromApi(bibleVersionId));        
        yield fork(loadVerses, bibleVersionId);

        const action = yield take([GET_BIBLE_VERSES_FROM_API.SUCCESS, GET_BIBLE_VERSES_FROM_API.FAILURE]);
        if (action.type === GET_BIBLE_VERSES_FROM_API.SUCCESS) {
            const verses = action.response.entities.verses;
            yield put(updateVersesStorage(verses));
        }
    }
}