import { take, call, fork } from 'redux-saga/effects';
import { UPDATE_BIBLE_VERSIONS_STORAGE } from '../actions';
import { addBibleVersionsToLocalStore } from '../indexedDbStorage';

function* updateLocalStorage(bibleVersions) {
    yield call(addBibleVersionsToLocalStore, bibleVersions);
}

export const watchUpdateBibleVersionsLocalStorage = function* () {
    while(true) {
        const {bibleVersions} = yield take(UPDATE_BIBLE_VERSIONS_STORAGE);
        yield fork(updateLocalStorage, bibleVersions);
    }
}