import * as entitiesNames from '../../common/entities/constants';

export const bibleHistory = {
    entityName: entitiesNames.BIBLEHISTORY,
    storageTableName: 'bibleHistory',
    sortByField: 'readDateTimeLt',
    sortDirection: 'desc',
    apiTableName: 'UserChaptersReadHistory',
    filterByVersionId: false,
    shouldNormalizeApiResponse: false,
    isGetEntitiesUserAuthenticationRequired: true,
    //apiSchemaArray: verseOfTheDayApiSchemaArray,
    addApiData(payload) {
        return getApiDataToAdd(payload)
    },
    addToStoragePayload(payload) { 
        return payload;
    },
    getLoadedEntities(entities) {
        return entities;
    },
    //getEntityListFromApi: function*(filter) { yield call(getVerseOfTheDayListFromApi, filter) }  
    getUnsynchedEntitiesFilter(lastUploadedDateTimeLtISO) {
        let filter = lastUploadedDateTimeLtISO
            ? {
                readDateTimeLt: {
                    operand: '>',
                    value: lastUploadedDateTimeLtISO
                }
            }
            : null;
        return filter;
    },
    getEntityListFilterForApi(filter, userId, lastUpdatedDateTimeUtc) {
        filter = {...filter, userId: userId }
        if (lastUpdatedDateTimeUtc) {
            filter = {...filter, modifiedDateTimeUtc: lastUpdatedDateTimeUtc }
        }
            
        return filter;
    },
    getEntityListFromApiResponse(apiResponse) {
        return apiResponse.items;
    }
}

const getApiDataToAdd = data => {   
    const {bookId, ...historyData} = data;
    return historyData;
}