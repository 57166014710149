import { take, call, fork } from 'redux-saga/effects';
import { verseOfTheDayActions } from '../actions';
import { savePersonalVerseOfTheDayToStorageService } from '../indexedDbStorage';

function* updateStorage(date, verseId) {
    yield call(savePersonalVerseOfTheDayToStorageService.savePersonalVerseOfTheDayToStorage, date, verseId);
}

export const watchSavePersonalVerseOfTheDayToStorage = function* () {
    while(true) {
        const {date, verseId} = yield take(verseOfTheDayActions.SAVE_PERSONAL_VERSEOFTHEDAY_TO_STORAGE);
        yield fork(updateStorage, date, verseId);
    }
}