import { take, put, select } from 'redux-saga/effects';
import { entityListActions } from '../../../common/entities/actions';
import * as entitiesNames from '../../../common/entities/constants';
import { loadBookChapters } from '../../Bible/Chapters/actions';
import { getBookChaptersFromStorageActions } from '../../Bible/Chapters/actions';
import { settle } from '../../../sagas/sagaHelper';

const getBibleVersion = state => state.bibleVersion;

function* loadChaptersByBookId(bibleVersionId, bookId) {
    yield put(loadBookChapters(bibleVersionId, bookId));

    const { GET_BOOK_CHAPTERS_FROM_STORAGE } = getBookChaptersFromStorageActions;

    yield take([
        GET_BOOK_CHAPTERS_FROM_STORAGE.SUCCESS, 
        GET_BOOK_CHAPTERS_FROM_STORAGE.FAILURE
    ]);
}

export const watchBibleHistoryListTrigger = function* () {
    const { GET_ENTITY_LIST } = entityListActions;

    while(true) {
        const action = yield take([
            GET_ENTITY_LIST(entitiesNames.BIBLEHISTORY).SUCCESS, 
            GET_ENTITY_LIST(entitiesNames.BIBLEHISTORY).FAILURE
        ]);

        if (action.type === GET_ENTITY_LIST(entitiesNames.BIBLEHISTORY).SUCCESS) {
            let historyList = action.response.items ? action.response.items : action.response;
            let bibleVersion = yield select(getBibleVersion);
            let requestedBooks = [];
            
            let loadChaptersRequests = historyList.map(historyItem => {
                if (!requestedBooks.includes(historyItem.bookId)) {
                    requestedBooks.push(historyItem.bookId);
                    return settle(loadChaptersByBookId, bibleVersion.id, historyItem.bookId);
                }
            });

            for (let request of loadChaptersRequests) {
                yield request;
            }
        }
    }
}
