import { take, call, fork, select } from 'redux-saga/effects';
import { entityListStorageActions } from '../actions';
import { entityByName } from '../metadata';
import { saveEntityListToStorageService } from '../indexedDbStorage';

const getVersionId = state => state.bibleVersion.id;

function* updateStorage(entityName, entityList, currentUtcTime) {
    const entity = entityByName(entityName);
    let versionId = entity.filterByVersionId ? yield select(getVersionId) : null;
    yield call(saveEntityListToStorageService.saveEntityListToStorage, entityName, entityList, versionId, currentUtcTime);
}

export const watchSaveEntityListToStorage = function* (entityName) {
    while(true) {
        const {entityList, currentUtcTime} = yield take(entityListStorageActions.SAVE_ENTITY_LIST_TO_STORAGE(entityName));
        if (entityList) {
            yield fork(updateStorage, entityName, entityList, currentUtcTime);
        }        
    }
}