import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
//import { Redirect } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import ChristTheSheperd from '../components/ChristTheSheperd.jpg';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },    
}));

const MainPanel = () => {
    const classes = useStyles();
    
    return (    
    <div className={classes.root}>
        {/* <Redirect to='/verseoftheday'/>  */}
        <img src={ChristTheSheperd} alt={ChristTheSheperd} />
        <br />
        <Typography paragraph align="center">
            Welcome to Christ The Beloved Bible App. <br />            
        </Typography>
        <Typography paragraph align="center">            
            You can use the side menu to read the bible, view Verse of the Day, and follow your reading history. <br />
        </Typography>
        <Typography paragraph align="center">            
            We will be adding more features in the near future. Enjoy!<br />
        </Typography>
    </div>
)
    }

export default MainPanel