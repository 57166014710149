import { APIURL, BASEURL, LOGLEVEL } from './helper';

export const fetchSystemSettingsRequest = () => loadSystemSettings();

const loadSystemSettings = () => {
    return ({response: {
        'apiUrl': `${APIURL}`,
        'baseUrl': `${BASEURL}`,
        'logLevel': `${LOGLEVEL}`
    }})

    // return fetch(`${BASEURL}config.json`)
    //     .then((response) => response.json())
    //     .then(
    //         response => ({ response }),
    //         error => ({ error: error.message || 'Something bad happened' })
    //     )
}