import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ChaptersListButton from './ListButton';
import { loadBookChapters, setBookChapter } from './actions';
import { getChaptersByBookIdAndBibleVersion } from './selectors/getChaptersByBookIdAndBibleVersionSelector';
import { getChapterByBibleVersionAndSelectedChapterId } from './selectors/getChapterByBibleVersionAndChapterIdSelector';

class BookChapters extends Component {
    static propTpes = {
        chaptersList: PropTypes.array.isRequired,
        selectedChapter: PropTypes.object.isRequired,
        bibleVersionId: PropTypes.number.isRequired,
        bookId: PropTypes.number.isRequired,
        loadBookChapters: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        isLoadingSuccessful: PropTypes.bool.isRequired,
        setBookChapter: PropTypes.func.isRequired
    }

    state = {
        anchorChapterEl: null,
    };
    
    componentDidMount() {
        const { bibleVersionId, bookId } = this.props;
        if (bookId !== 0) {
            this.props.loadBookChapters(bibleVersionId, bookId);
        }                
    }

    handleChapterClick = event => {
        this.setState({ anchorChapterEl: event.currentTarget });
    };

    handleChapterClose = () => {
        this.setState({ anchorChapterEl: null });
    };

    handleChapterMenuItemClick = (event, chapterId) => {
        this.setState({ anchorChapterEl: null });
        this.props.setBookChapter(chapterId);
    }

    render() {
        const { isLoading, isLoadingSuccessful, selectedChapter } = this.props;
        let chapterNumber = "";

        if (isLoading) {
            chapterNumber = "Loading chapters";
        } else if (isLoadingSuccessful) {
            chapterNumber = selectedChapter ? selectedChapter.chapterNumber : "Select Chapter";
        } else {
            chapterNumber = "Chapter";
        }
        
        return (
            <div>
                <ChaptersListButton isLoading={isLoading}
                    isLoadingSuccessful={isLoadingSuccessful} 
                    chaptersList={this.props.chaptersList}
                    selectedChapterNumber={chapterNumber}
                    anchorChapterEl={this.state.anchorChapterEl} 
                    onHandleChapterClick={this.handleChapterClick} 
                    onHandleChapterClose={this.handleChapterClose}
                    onHandleChapterMenuItemClick={this.handleChapterMenuItemClick} />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {    
    const {
        bibleVersion,
        bibleBook,
        bookChapter
    } = state

    const chaptersList = getChaptersByBookIdAndBibleVersion(state);
    const selectedChapter = getChapterByBibleVersionAndSelectedChapterId(state);
    const bibleVersionId = bibleVersion.id;
    const bookId = bibleBook.id;
    const isLoading = bookChapter.isLoading;
    const isLoadingSuccessful = bookChapter.isLoadingSuccessful;
    
    return {
        chaptersList,
        selectedChapter,
        bibleVersionId,
        bookId,
        isLoading,
        isLoadingSuccessful
    }
}

export default withRouter(connect(mapStateToProps, {
    loadBookChapters,
    setBookChapter
    })(BookChapters));