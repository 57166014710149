import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import BibleBooks from '../Books';
import BookChapters from '../Chapters';
import BibleVersions from '../Versions';

const styles = {
    root: {
        display: 'flex'                
      },
    grow: {
        flexGrow: 1,
      },
}

class BiblePanelHeader extends Component {
    static propTpes = {
        classes: PropTypes.object.isRequired,
        bibleVersionId: PropTypes.number.isRequired
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root} >
                <div className={classes.root} style={{direction: ((this.props.bibleVersionId === 1) ? 'rtl' : 'ltr') }}>                
                    <BibleBooks />
                    <BookChapters />
                </div>                                
                <Typography color="inherit" className={classes.grow} />                                 
                <BibleVersions />
            </div>
        );
    }
}

export default withStyles(styles)(BiblePanelHeader);

