import * as actions from '../../../actions/actionRequestTypes';
import { VERSEOFTHEDAY } from '../../../common/entities/constants';

const { createRequestTypes, action } = actions;

export const LOAD_VERSEOFTHEDAY_LIST_FROM_API = `LOAD_${VERSEOFTHEDAY}_LIST_FROM_API`;
export const loadVerseOfTheDayListFromApi = (bibleVersionId) => action(LOAD_VERSEOFTHEDAY_LIST_FROM_API, {bibleVersionId});

export const LOAD_UPDATED_VERSEOFTHEDAY_LIST_FROM_API = `LOAD_UPDATED_${VERSEOFTHEDAY}_LIST_FROM_API`;
export const loadUpdatedVerseOfTheDayListFromApi = (bibleVersionId, modifiedSinceDateTimeUtc) => action(LOAD_UPDATED_VERSEOFTHEDAY_LIST_FROM_API, {bibleVersionId, modifiedSinceDateTimeUtc});

export const GET_VERSEOFTHEDAY_LIST_FROM_API = createRequestTypes(`GET_${VERSEOFTHEDAY}_LIST_FROM_API`);

export const getVerseOfTheDayListFromApi = {
    request: () => action(GET_VERSEOFTHEDAY_LIST_FROM_API.REQUEST),
    success: (id, response) => action(GET_VERSEOFTHEDAY_LIST_FROM_API.SUCCESS, {response}),
    failure: (id, error) => action(GET_VERSEOFTHEDAY_LIST_FROM_API.FAILURE, {error})
}