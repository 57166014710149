import * as ActionTypes from './actions';

const { GET_VERSEOFTHEDAY, GET_PERSONAL_VERSEOFTHEDAY_FROM_STORAGE, GENERATE_VERSEOFTHEDAY } = ActionTypes.verseOfTheDayActions;

export const verseOfTheDay = (state = {verseVersionId: 0, date: '',  isLoading: false, isLoadingSuccessful: false}, action) => {    
    const { type } = action

    switch (type) {
        case GET_VERSEOFTHEDAY:
            return {
                ...state,
                isLoading: true
            }
        case GET_PERSONAL_VERSEOFTHEDAY_FROM_STORAGE.SUCCESS:
        case GENERATE_VERSEOFTHEDAY.SUCCESS:
            return {
                ...state,
                verseVersionId: `${action.response.verseOfTheDay.versionId}-${action.response.verseOfTheDay.verseId}`,
                date: action.response.date,
                isLoading: false,
                isLoadingSuccessful: true
            }
        case GET_PERSONAL_VERSEOFTHEDAY_FROM_STORAGE.FAILURE:
        case GENERATE_VERSEOFTHEDAY.FAILURE:
            return {
                ...state,
                isLoading: false,
                isLoadingSuccessful: false
            }
        default:
            return state;
    }
}