import React from 'react';
import Typography from '@material-ui/core/Typography';

const ReleaseNotes = () => (
  <div>
    <Typography variant="h6" paragraph>
      1.1.1
    </Typography>    
    <Typography variant="body2" paragraph>
- Fixed errors related to loading verse of the day for different bible versions<br />
    </Typography>
    <Typography variant="h6" paragraph>
      1.1.0
    </Typography>    
    <Typography variant="body2" paragraph>
- Improved User Interface<br />
    </Typography>
    <Typography variant="h6" paragraph>
      1.0.3
    </Typography>    
    <Typography variant="body2" paragraph>
- Added "Reading History" feature. Now, you will be able to see your reading history and go back and read any of those chapters. This would help for example during group bible study where you can go back later and follow on those readings.<br />
- Improved checking if new version of web app is available<br />
- Fixed displaying Verse Of The Day issue due to recent update<br />
    </Typography>
    <Typography variant="h6" paragraph>
      0.9.0
    </Typography>    
    <Typography variant="body2" paragraph>
- Verse of the Day admin improvements
    </Typography>
    <Typography variant="h6" paragraph>
      0.8.2
    </Typography>    
    <Typography variant="body2" paragraph>
{/* - Added the ability to backup and sync bookmark and verse of the day automatically to the cloud and among devices. This requires registering for an online account.<br /> */}
- Added the ability to register for an online account. It's safe, secure and free. This will enable in upcoming release to backup and sync bookmark and verse of the day automatically to the cloud and among devices.<br />
- Service improvements.
    </Typography>
    <Typography variant="h6" paragraph>
      0.7.4
    </Typography>    
    <Typography variant="body2" paragraph>
- Added displaying Verse of the Day<br />
- Improved checking if new version of web app is available
    </Typography>
    <Typography variant="h6" paragraph>
      0.6.0
    </Typography>    
    <Typography variant="body2" paragraph>
- Added the ability to download a bible book to local storage to improve performance and offline experience when a chapter is served from web service. 
This helps the user to continue reading the downloaded book even when internet is not available.<br />
    </Typography>
    <Typography variant="h6" paragraph>
      0.5.0
    </Typography>    
    <Typography variant="body2" paragraph>
- Added the ability to update iOS web apps by displaying new version available message.<br />
- Service performance improvements.
    </Typography>
    <Typography variant="h6" paragraph>
      0.4.1
    </Typography>    
    <Typography variant="body2" paragraph>
- Auto-bookmark bible version, book and chapter. Next time the app is opened, when clicking/tapping Bible, it will auto-display bookmarked bible and chapter.<br />
- Store currently displayed chapter in local storage for improved offline experience and performance.
    </Typography>
    <Typography variant="h6" paragraph>
      0.3.1
    </Typography>    
    <Typography variant="body2" paragraph>
- Added Progressive Web App as an option where website can be stored as an app on the device.
    </Typography>
    <Typography variant="h6" paragraph>
      0.2.0
    </Typography>    
    <Typography variant="body2" paragraph>
- Added New King James Version
    </Typography>
    <Typography variant="h6" paragraph>
      0.1.3
    </Typography>    
    <Typography variant="body2" paragraph>
- Read King James Version Bible<br />
- Read Smith & Van Dyke Arabic Bible
    </Typography>
  </div>
)

export default ReleaseNotes