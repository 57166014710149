import * as actions from '../../../../actions/actionRequestTypes';

const { createRequestTypes, action } = actions;

export const LOAD_CHAPTER_VERSES_FROM_API = 'LOAD_CHAPTER_VERSES_FROM_API';
export const loadChapterVersesFromApi = (bibleVersionId, bookId, chapterId) => action(LOAD_CHAPTER_VERSES_FROM_API, {bibleVersionId, bookId, chapterId});

export const GET_CHAPTER_VERSES_FROM_API = createRequestTypes('GET_CHAPTER_VERSES_FROM_API');

export const getChapterVersesFromApi = {
    request: () => action(GET_CHAPTER_VERSES_FROM_API.REQUEST),
    success: (id, response) => action(GET_CHAPTER_VERSES_FROM_API.SUCCESS, {response}),
    failure: (id, error) => action(GET_CHAPTER_VERSES_FROM_API.FAILURE, {error})
}