import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import BibleHistoryPanel from './Panel';
import { entityListActions } from '../../common/entities/actions';
import * as entitiesNames from '../../common/entities/constants';

const { request: getEntityList } = entityListActions.getEntityList;

const BibleHistory = props => {
    const [pageLoaded, setPageLoaded] = React.useState(false);

    const { historyItems } = props;

    const initializeLoadingPage = () => {        
        props.getEntityList(entitiesNames.BIBLEHISTORY);
        setPageLoaded(true);
    }

    React.useEffect(() => {
        if (!pageLoaded) {
            initializeLoadingPage();            
        }
    })

    return (
        <div>
            <BibleHistoryPanel historyList={historyItems} />
        </div>
    )
}

BibleHistory.propTypes = {
    historyItems: PropTypes.array.isRequired,
    getEntityList: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    const {
        bibleHistory,
    } = state

    const historyItems = _.values(bibleHistory.items);

    return {
        historyItems
    }
}

export default withRouter(connect(mapStateToProps, {
    getEntityList    
})(BibleHistory));