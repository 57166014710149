import { take, call, fork, put } from 'redux-saga/effects';
import { chapterVersesActions, getChapterVersesFromStorageActions, getChapterVersesFromApiActions } from '../actions';
import { fetchVersesByChapterIdAndVersionIdFromStorageService } from '../indexedDbStorage';
import { fetchEntity } from '../../../../sagas/sagaHelper';

// bind Generators
const fetchChapterVersesFromStorage = fetchEntity.bind(null, getChapterVersesFromStorageActions.getChapterVersesFromStorage, fetchVersesByChapterIdAndVersionIdFromStorageService.fetchVersesByChapterIdAndVersionIdFromStorage);

function* loadVerses(bibleVersionId, chapterId) {
    yield call(fetchChapterVersesFromStorage, bibleVersionId, chapterId); 
}

export const watchLoadChapterVersesFromStorage = function* () {
    const { GET_CHAPTER_VERSES_FROM_STORAGE } = getChapterVersesFromStorageActions;
    
    while(true) {
        const {bibleVersionId, bookId, chapterId} = yield take(getChapterVersesFromStorageActions.LOAD_CHAPTER_VERSES_FROM_STORAGE);
        yield fork(loadVerses, bibleVersionId, chapterId);
        
        const action = yield take([
            GET_CHAPTER_VERSES_FROM_STORAGE.SUCCESS, 
            GET_CHAPTER_VERSES_FROM_STORAGE.FAILURE
        ]);
        
        if (action.type === GET_CHAPTER_VERSES_FROM_STORAGE.FAILURE) {
            yield put(getChapterVersesFromApiActions.loadChapterVersesFromApi(bibleVersionId, bookId, chapterId));
        } else if (action.type === GET_CHAPTER_VERSES_FROM_STORAGE.SUCCESS) {
            yield put (chapterVersesActions.getChapterVerses.success('ChapterVerses', action.response));
        }       
    }
}