import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware, { END } from 'redux-saga';
import rootReducer from '../reducers';
import rootSaga from '../sagas';

const configureStore = preloadedState => {
    const sagaMiddleware = createSagaMiddleware()
    const store = createStore (
        rootReducer,
        preloadedState,
        applyMiddleware(sagaMiddleware)
    )

    store.runSaga = sagaMiddleware.run(rootSaga);
    store.close = () => store.dispatch(END)
    return store
}

export default configureStore