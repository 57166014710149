import {
    drawerWidth,
    transition,
    container,
} from '../CTBWebApp';

const mainPageStyle = theme => ({
    root: {
        //display: 'flex',
        position: "relative",
        top: "0",
        height: "100vh"
    },
    mainPanel: {
        [theme.breakpoints.up("sm")]: {
            width: `calc(100% - ${drawerWidth}px)`
        },
        //flexGrow: 1,
        padding: theme.spacing(3),
        overflow: "auto",
        position: "relative",
        float: "right",
        ...transition,
        maxHeight: "100%",
        width: "100%",
        overflowScrolling: "touch"
    },
});

export default mainPageStyle;