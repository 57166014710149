import * as actions from '../../../../actions/actionRequestTypes';

const { createRequestTypes, action } = actions;

export const GET_CHAPTER_VERSES = createRequestTypes('GET_CHAPTER_VERSES');

export const getChapterVerses = {
    request: (bibleVersionId, bookId, chapterId) => action(GET_CHAPTER_VERSES.REQUEST, {bibleVersionId, bookId, chapterId}),
    success: (id, response) => action(GET_CHAPTER_VERSES.SUCCESS, {response}),
    failure: (id, error) => action(GET_CHAPTER_VERSES.FAILURE, {error})
}