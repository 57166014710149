import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';
import styles from '../../../assets/jss/components/drawerStyle';
import Button from '@material-ui/core/Button';
import * as Routes from '../../../constants';

// const useStyles = makeStyles(styles);

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        marginBottom: 12,
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    cardContentArea: {
        display: 'flex',
    },
    cardContentDescriptionArea: {
        flexGrow: 1,
    },
    cardActionArea: {        
        position: "relative", 
        width: 90,     
    },
    btn: {
        borderRadius: 20,
        padding: '0.125rem 0.75rem',
        borderColor: '#becddc',
        fontSize: '0.75rem', 
        position: "absolute",       
        bottom: 0,
        right: 0,
    }
});

function ReadingHistoryIcon(props) {
    return (
        <SvgIcon {...props}>
            <path  d='M19 1L14 6V17L19 12.5V1M21 5V18.5C19.9 18.15 18.7 18 17.5 18C15.8 18 13.35 18.65 12 19.5V6C10.55 4.9 8.45 4.5 6.5 4.5C4.55 4.5 2.45 4.9 1 6V20.65C1 20.9 1.25 21.15 1.5 21.15C1.6 21.15 1.65 21.1 1.75 21.1C3.1 20.45 5.05 20 6.5 20C8.45 20 10.55 20.4 12 21.5C13.35 20.65 15.8 20 17.5 20C19.15 20 20.85 20.3 22.25 21.05C22.35 21.1 22.4 21.1 22.5 21.1C22.75 21.1 23 20.85 23 20.6V6C22.4 5.55 21.75 5.25 21 5M10 18.41C8.75 18.09 7.5 18 6.5 18C5.44 18 4.18 18.19 3 18.5V7.13C3.91 6.73 5.14 6.5 6.5 6.5C7.86 6.5 9.09 6.73 10 7.13V18.41Z' />
        </SvgIcon>
    );
}

const BibleHistoryItemPanel = props => {
    const classes = useStyles();

    const { day, time, bookName, chapterId, chapterNumber, onHandleReadChapterClick } = props;

    return (
        <Card key={chapterId} className={classes.root}>
            <CardContent className={classes.cardContentArea}>
            <div className={classes.cardContentDescriptionArea}>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    {day}
                </Typography>
                <Typography className={classes.pos} color="textSecondary">
                    {time}
                </Typography>
                {/* <Icon className={classNames(classes.itemIcon, classes.whiteFont)}>
                        <ReadingHistoryIcon />
                    </Icon> */}
                <Typography variant="h6" component="h2">
                    {bookName} {chapterNumber}
                </Typography>
                </div>
                <div className={classes.cardActionArea}>
                    <Button className={classes.btn} variant={'outlined'} component={Link} to={Routes.ROUTES_BIBLE_PATHNAME} onClick={onHandleReadChapterClick}>
{/*                         <Icon className={classNames(classes.itemIcon, classes.whiteFont)}>
                            <MenuBookIcon />
                        </Icon> */} 
                        Read
                    </Button>
                </div>
            </CardContent>
        </Card>           
    )
}

BibleHistoryItemPanel.propTypes = {
    day: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    bookName: PropTypes.string.isRequired,
    chapterId: PropTypes.number.isRequired,
    chapterNumber: PropTypes.string.isRequired,
    onHandleReadChapterClick: PropTypes.func.isRequired,
}

export default BibleHistoryItemPanel;
