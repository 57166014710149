import * as actions from '../../../../actions/actionRequestTypes';

import * as _getBibleVersionsFromStorageActions from './getBibleVersionsFromStorageActions';
import * as _getBibleVersionsFromApiActions from './getBibleVersionsFromApiActions';
import * as _getViewFiltersBibleVersionFromStorageActions from './getViewFiltersBibleVersionFromStorageActions';

export const getBibleVersionsFromStorageActions = _getBibleVersionsFromStorageActions;
export const getBibleVersionsFromApiActions = _getBibleVersionsFromApiActions;
export const getViewFiltersBibleVersionFromStorageActions = _getViewFiltersBibleVersionFromStorageActions;

const { action } = actions;

export const LOAD_BIBLE_VERSIONS = 'LOAD_BIBLE_VERSIONS';
export const loadBibleVersions = () => action(LOAD_BIBLE_VERSIONS);

export const SET_BIBLE_VERSION = 'SET_BIBLE_VERSION';
export const setBibleVersion = (versionId, langId) => action(SET_BIBLE_VERSION, {versionId, langId});

export const UPDATE_BIBLE_VERSIONS_STORAGE = 'UPDATE_BIBLE_VERSIONS_STORAGE';
export const updateBibleVersionsStorage = (bibleVersions) => action(UPDATE_BIBLE_VERSIONS_STORAGE, {bibleVersions});

export const UPDATE_VIEWFILTERS_BIBLEVERSION_STORAGE = 'UPDATE_VIEWFILTERS_BIBLEVERSION_STORAGE';
export const updateViewFiltersBibleVersionStorage = (bibleVersionId, langId) => action(UPDATE_VIEWFILTERS_BIBLEVERSION_STORAGE, {bibleVersionId, langId});