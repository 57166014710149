import _ from 'lodash';
import { indexedDb } from '../../../services/indexedDb';
import { entityByName } from '../metadata';

const loadEntityListFromLocalStore = (entityName, filter) => {
    var table;
    const entity = entityByName(entityName);
    if (entity) {
        table = indexedDb.table(entity.storageTableName);
    }

    let entityList = table;

    if (filter && !_.isEmpty(filter)) {
        if (Object.values(filter)[0].operand) {
            if (Object.values(filter)[0].operand === '>') {
                entityList = entityList.where(Object.keys(filter)[0])
                    .above(Object.values(filter)[0].value)
            }            
        }
        else if (Object.values(filter)[0]) {
            entityList = entityList.where(Object.keys(filter)[0])
                .equals(Object.values(filter)[0])
        }        
    }

    if (entity && entity.sortDirection && entity.sortDirection === 'desc') {
        entityList = entityList.reverse();
    }
    
    return entityList.toArray();    
}

export const fetchEntityListFromStorage = (entityName, filter) => {
    return loadEntityListFromLocalStore(entityName, filter)
        .then(entities => {        
            if (entities.length > 0) {
                const entity = entityByName(entityName);
                return entity && entity.getLoadedEntities ? entity.getLoadedEntities(entities) : entities;                
            }
            else {
                //throw new Error('No verses found in local storage!');
            }
        })
        .catch(error => {
            //throw error;
        })
        .then(
            response => ({ response }),
            error => ({ error: error.message })
        );         
}