import React from 'react';
import { Typography } from '@material-ui/core';

const UpdateUserInfoHeader = () => {
    return (
        <Typography variant="h6" color="inherit" >
            View/Update Info
        </Typography>
    );
}

export default UpdateUserInfoHeader;