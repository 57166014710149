import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import * as Routes from '../../constants';

const SignInAccountMenu = props => {
    const { signInAccountAnchorEl, onAccountMenuClose } = props;

    const isSignInAccountMenuOpen = Boolean(signInAccountAnchorEl);
    const signInAccountMenuId = 'signIn-account-menu';

    return (
        <Menu
            id={signInAccountMenuId}
            anchorEl={signInAccountAnchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}          
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isSignInAccountMenuOpen}
            onClose={onAccountMenuClose}
        >
            <MenuItem component={Link} to={Routes.ROUTES_LOGIN_PATHNAME} onClick={onAccountMenuClose}>Sign in</MenuItem>
            <MenuItem component={Link} to={Routes.ROUTES_REGISTER_PATHNAME} onClick={onAccountMenuClose}>Register</MenuItem>                        
        </Menu>
    );
}

SignInAccountMenu.propTypes = {
    onAccountMenuClose: PropTypes.func.isRequired,
}

export default SignInAccountMenu;