import { take, call, fork } from 'redux-saga/effects';
import { verseOfTheDayListStorageActions } from '../actions';
import { saveVerseOfTheDayListToStorageService } from '../indexedDbStorage';

function* updateStorage(verses, currentUtcTime) {
    yield call(saveVerseOfTheDayListToStorageService.saveVerseOfTheDayListToStorage, verses, currentUtcTime);
}

export const watchSaveVerseOfTheDayListToStorage = function* () {
    while(true) {
        const {entityList, currentUtcTime} = yield take(verseOfTheDayListStorageActions.SAVE_VERSEOFTHEDAY_LIST_TO_STORAGE);
        if (entityList) {
            yield fork(updateStorage, entityList, currentUtcTime);
        }        
    }
}