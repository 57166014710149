import { fork, all } from 'redux-saga/effects';
import { watchLoadChapterVerses } from './getVersesSaga';
import { watchLoadChapterVersesFromStorage } from './getVersesFromStorageSaga';
import { watchLoadChapterVersesFromApi } from './getVersesFromApiSaga';
import { watchUpdateVersesLocalStorage } from './updateVersesLocalStorageSaga';
import { watchLoadBookVersesFromApi } from './getBookVersesFromApiSaga';
import { watchLoadBibleVersesFromApi } from './getBibleVersesFromApiSaga';

export const watchVersesRequests = function*() {
    yield all([
        fork(watchLoadChapterVerses),
        fork(watchLoadChapterVersesFromStorage),
        fork(watchLoadChapterVersesFromApi),
        fork(watchUpdateVersesLocalStorage),
        fork(watchLoadBookVersesFromApi),
        fork(watchLoadBibleVersesFromApi)   
    ])
}