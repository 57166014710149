export const getCurrentDateTimeLtISO = () => {
    const currentDateTime = new Date();
    const dd = String(currentDateTime.getDate()).padStart(2, '0');
    const mm = String(currentDateTime.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = currentDateTime.getFullYear();
    const hh = String(currentDateTime.getHours()).padStart(2, '0');
    const minutes = String(currentDateTime.getMinutes()).padStart(2, '0');
    const ss = String(currentDateTime.getSeconds()).padStart(2, '0');
    const ms = String(Math.round(currentDateTime.getMilliseconds()/100)*100).padStart(3, '0');

    return `${yyyy}-${mm}-${dd}T${hh}:${minutes}:${ss}.${ms}`;
}

export const getCurrentDateTimeLt = () => {
    const currentDateTime = new Date();
    const dd = String(currentDateTime.getDate()).padStart(2, '0');
    const mm = String(currentDateTime.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = currentDateTime.getFullYear();
    const hh = String(currentDateTime.getHours()).padStart(2, '0');
    const minutes = String(currentDateTime.getMinutes()).padStart(2, '0');
    const ss = String(currentDateTime.getSeconds()).padStart(2, '0');
    const ms = String(currentDateTime.getMilliseconds()).padStart(3, '0');

    return new Date(`${yyyy}-${mm}-${dd}T${hh}:${minutes}:${ss}.${ms}Z`);
}

export const getCurrentDate = () => {
    let currentDate = new Date();        
    return getFormattedDate(currentDate);
}

const getFormattedDate = date => {
    let dd = String(date.getDate()).padStart(2, '0');
    let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = date.getFullYear();    

    return `${yyyy}-${mm}-${dd}`;
}

Date.prototype.addDays = function (days) {
    let date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

export const getDateCaptionFromDateTime = (dateTime) => {
    let currentDate = new Date();
    let formattedCurrentDate = getFormattedDate(currentDate);
    let yesterday = currentDate.addDays(-1);
    let formattedYesterday = getFormattedDate(yesterday);

    let date = new Date(dateTime);
    let formattedDate = getFormattedDate(date);
    
    // Should format as: Saturday, October 15, 2020
    return formattedDate === formattedCurrentDate 
        ? 'Today' 
        : formattedDate === formattedYesterday
            ? 'Yesterday' 
            : date.toLocaleDateString(undefined, {
                weekday: 'long',
                day : 'numeric',
                month : 'long',
                year : 'numeric'
            });
}

export const getTimeCaptionFromDateTime = (dateTime) => {
    let date = new Date(dateTime);
    return date.toLocaleTimeString(undefined, {
        hour: '2-digit',
        minute: '2-digit',
    });
}

