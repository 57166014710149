import { normalize } from 'normalizr';
import { chapterStorageSchemaArray } from '../schema';
import { loadChaptersByBookIdAndLangIdFromLocalStore } from './loadChaptersByBookIdAndLangIdFromLocalStoreDb';

export const fetchChaptersByBookIdAndLangIdFromStorage = (langId, bookId) => {
    return loadChaptersByBookIdAndLangIdFromLocalStore(langId, bookId)
        .then(chapters => {        
            if (chapters.length > 0) {
                const normalized = normalize(chapters, chapterStorageSchemaArray);
                return {...normalized}
            }
            else {
            }
        })
        .catch(error => {
        })
        .then(
            response => ({ response }),
            error => ({ error: error.message })
        );         
}