import { take, call, fork, select, put } from 'redux-saga/effects';
import { bookVersesFromApiActions, updateVersesStorage } from '../actions';
import { fetchBookVersesApi } from '../bookVersesApi';
import { fetchEntity } from '../../../../sagas/sagaHelper';

// bind Generators
const fetchBookVerses = fetchEntity.bind(null, bookVersesFromApiActions.getBookVersesFromApi, fetchBookVersesApi);

const getApiUrl = state => state.systemSettings.apiUrl;

function* loadVerses(bibleVersionId, bookId) {
    let apiUrl = yield select(getApiUrl);
    
    yield call(fetchBookVerses, apiUrl, bibleVersionId, bookId);
}

export const watchLoadBookVersesFromApi = function* () {
    const { GET_BOOK_VERSES_FROM_API } = bookVersesFromApiActions;

    while(true) {
        const {bibleVersionId, bookId} = yield take(bookVersesFromApiActions.LOAD_BOOK_VERSES_FROM_API);        
        yield fork(loadVerses, bibleVersionId, bookId);

        const action = yield take([GET_BOOK_VERSES_FROM_API.SUCCESS, GET_BOOK_VERSES_FROM_API.FAILURE]);
        if (action.type === GET_BOOK_VERSES_FROM_API.SUCCESS) {
            const verses = action.response.entities.verses;
            yield put(updateVersesStorage(verses));
        }
    }
}