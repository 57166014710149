import React from "react";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
//import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
//import SignalWifiOffIcon from "@material-ui/icons/SignalWifiOff";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
     paper: {
        padding: '20px 20px 3px',        
        //padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit}px ${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
    }, 
    /* avatar: {
        backgroundColor: theme.palette.primary.main
    } */
}));

function MessageBanner(props) {
    const classes = useStyles();
    const { bannerMessage, buttonCaption, onHandleClose } = props;

    return (
        <React.Fragment>
            <Paper className={classes.paper}>
                <Grid container wrap="nowrap" spacing={2} alignItems="center">
                    <Grid item>
                        {/* <Avatar className={classes.avatar}>
                            <SignalWifiOffIcon />
                        </Avatar> */}
                    </Grid>
                    <Grid item>
                        <Typography>
                            {bannerMessage}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container justify="flex-end" spacing={8}>
                    <Grid item>
                        <Button color="primary" onClick={onHandleClose}>{buttonCaption}</Button>
                    </Grid>
                </Grid>
            </Paper>
            <Divider />
            <CssBaseline />
        </React.Fragment>
    );
}

export default MessageBanner;