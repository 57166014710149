import Dexie from 'dexie';

export const indexedDb = new Dexie('ChristTheBelovedDb');

// create stores
indexedDb.version(1).stores({
    bibleVersions: 'versionId',
    books: 'bookLangId, langId', // might need bookId
    chapters: 'chapterLangId, langId, bookId',
    verses: 'verseVersionId, versionId, chapterId',
    viewFilters: 'filterName'
});
indexedDb.version(2).stores({
    verses: 'verseVersionId, versionId, chapterId, verseId',
    settings: 'settingName',
    verseOfTheDayMasterList: 'verseId',
    personalVerseOfTheDay: 'date, verseId'
});
indexedDb.version(3).stores({
    verseOfTheDayMasterList: null,
    verseOfTheDayList: 'verseVersionId, verseId, versionId'
});
indexedDb.version(4).stores({
    //verseOfTheDayMasterList: null,
    userSettings: '[settingName+userId], settingName',
});
indexedDb.version(5).stores({
    highlightedVerses: 'storageId, verseId, chapterId',
});
indexedDb.version(6).stores({
    bibleHistory: 'readDateTimeLt, chapterId, userId',
});