import { take, call, fork, select, put } from 'redux-saga/effects';
import { VALIDATE_APP_VERSION, appVersionFromApiActions, appVersionFromStorageActions } from '../actions';
import { fetchAppVersionApi } from '../api';
import { fetchEntity } from '../../../sagas/sagaHelper';

// bind Generators
const fetchAppVersion = fetchEntity.bind(null, appVersionFromApiActions.getAppVersionFromApi, fetchAppVersionApi);

const getApiUrl = state => state.systemSettings.apiUrl;

function* loadAppVersionFromApi() {
    let apiUrl = yield select(getApiUrl);
    yield call(fetchAppVersion, apiUrl);
}

function* validateAppVersion(latestAppVersion) {
    if (latestAppVersion !== process.env.REACT_APP_VERSION) {
        yield put(appVersionFromStorageActions.loadAppVersionFromStorage(latestAppVersion));
    }
}

export const watchLoadAppVersionFromApi = function* () {
    const { GET_APP_VERSION_FROM_API } = appVersionFromApiActions;

    while(true) {
        yield take(VALIDATE_APP_VERSION);        
        yield fork(loadAppVersionFromApi);

        const action = yield take([GET_APP_VERSION_FROM_API.SUCCESS, GET_APP_VERSION_FROM_API.FAILURE]);
        if (action.type === GET_APP_VERSION_FROM_API.SUCCESS) {
            yield fork(validateAppVersion, action.response.webAppVersion);            
        }
    }
}