import * as ActionTypes from './actions';
import _ from 'lodash';

export const systemSettings = (state = null, action) => {    
    const { type, response } = action;

    if (type === ActionTypes.GET_SETTINGS.SUCCESS) {
        return _.merge({}, state, response);
    }

    return state
}