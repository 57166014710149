import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContentWrapper from './SnackbarContentWrapper';

export default function MessageBox(props) {
    const { canDisplayMessageBox, message, variant, onHandleClose } = props;

    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={canDisplayMessageBox}
                autoHideDuration={6000}
                onClose={onHandleClose}
            >
                <SnackbarContentWrapper
                    onClose={onHandleClose}
                    variant={variant}
                    message={message}
                />
            </Snackbar>
        </div>
    );
}

MessageBox.propTypes = {
    canDisplayMessageBox: PropTypes.bool,
    message: PropTypes.string,
    variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
    onHandleClose: PropTypes.func.isRequired
  };