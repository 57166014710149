import { createSelector } from 'reselect';
import _ from 'lodash';
import orderBy from 'lodash/orderBy';

const getChapters = state => _.values(state.entities.chapters);
const getSelectedBibleBook = state => state.bibleBook;

export const getChaptersByBookId = createSelector(
    [getChapters, getSelectedBibleBook],
    (chapters, bibleBook) => {
        const filteredChapters = chapters.filter(chapter => chapter.bookId === bibleBook.id);
        return orderBy(filteredChapters, c => c.chapterId, ['asc']);
    }
)