import { take, call, fork } from 'redux-saga/effects';
import { signOutActions } from '../actions';
import { deleteUserFromStorageService } from '../indexedDbStorage';
import * as Routes from '../../../../constants';

function* updateStorage(userId) {
    yield call(deleteUserFromStorageService.deleteUserFromStorage, userId);
}

export const watchSignOutRequest = function* () {
    while(true) {
        const {userId} = yield take(signOutActions.SIGNOUT_USER);
        yield fork(updateStorage, userId);

        if (window.location.pathname === Routes.ROUTES_UPDATE_USER_INFO_PATHNAME) {
            window.history.back();
        }
    }
}