import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import BibleBooksListButton from './ListButton';
import { loadBibleBooks, setBibleBook } from './actions';
import { getBooksByBibleVersion } from './selectors/getBooksByBibleVersionSelector';
import {  getBookByBibleVersionAndSelectedBookId } from './selectors/getBookByBibleVersionAndBookIdSelector';

class BibleBooks extends Component {
    static propTpes = {
        bibleBooksList: PropTypes.array.isRequired,
        selectedBibleBook: PropTypes.object.isRequired,
        langId: PropTypes.number.isRequired,
        bibleVersionId: PropTypes.number.isRequired,
        loadBibleBooks: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        isLoadingSuccessful: PropTypes.bool.isRequired,
        setBibleBook: PropTypes.func.isRequired
    }

    state = {
        anchorBibleBookEl: null,        
    };
    
    componentDidMount() {
        const { bibleVersionId, loadBibleBooks } = this.props  
        loadBibleBooks(bibleVersionId);
    }

    handleBibleBookClick = event => {
        this.setState({ anchorBibleBookEl: event.currentTarget });
    };

    handleBibleBookClose = () => {
        this.setState({ anchorBibleBookEl: null });
    };

    handleBibleBookMenuItemClick = (event, bookId) => {
        this.setState({ anchorBibleBookEl: null });
        this.props.setBibleBook(bookId);
    }

    render() {
        const { isLoading, isLoadingSuccessful, selectedBibleBook, langId } = this.props;
        let bibleBookName = "";

        if (isLoading) {
            bibleBookName = "Loading books";
        } else if (isLoadingSuccessful) {
            bibleBookName = selectedBibleBook ? selectedBibleBook.bookName : "Select Book";
        } else {
            bibleBookName = "Book";
        }

        return (
            <div>
                <BibleBooksListButton isLoading={isLoading}
                    isLoadingSuccessful={isLoadingSuccessful} 
                    bibleBooksList={this.props.bibleBooksList}
                    selectedBibleBookName={bibleBookName}
                    langId={langId}
                    anchorBibleBookEl={this.state.anchorBibleBookEl} 
                    onHandleBibleBookClick={this.handleBibleBookClick} 
                    onHandleBibleBookClose={this.handleBibleBookClose}
                    onHandleBibleBookMenuItemClick={this.handleBibleBookMenuItemClick} />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {    
    const {
        bibleVersion,
        bibleBook
    } = state

    const bibleBooksList = getBooksByBibleVersion(state);
    const selectedBibleBook = getBookByBibleVersionAndSelectedBookId(state);
    const bibleVersionId = bibleVersion.id;
    const langId = bibleVersion.langId;
    const isLoading = bibleBook.isLoading;
    const isLoadingSuccessful = bibleBook.isLoadingSuccessful;
    
    return {
        bibleBooksList,
        selectedBibleBook,
        bibleVersionId,
        isLoading,
        isLoadingSuccessful,
        langId
    }
}

export default withRouter(connect(mapStateToProps, {
    loadBibleBooks,
    setBibleBook
    })(BibleBooks));