export const compareChangedData = (changedData = {}, fieldName, currentValue, newValue) => {
    if (currentValue !== newValue) {
        return {...changedData, [fieldName]: newValue}
    }

    return changedData
}

export const getData = (data = {}, key, value) => {
    return {...data, [key]: value}
}

export const getSelectedText = () => {
    var text = "";
    if (typeof window.getSelection !== "undefined") {
        text = window.getSelection().toString();
    } else if (typeof document.selection !== "undefined" && document.selection.type === "Text") {
        text = document.selection.createRange().text;
    }
    return text;
}