import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import BiblePanelHeader from './Panel';

class BibleHeader extends Component {
    static propTpes = {
        bibleVersionId: PropTypes.number.isRequired
    }

    render() {
        return (
            <div>
                <BiblePanelHeader bibleVersionId={this.props.bibleVersionId} />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {    
    const {
        bibleVersion
    } = state

    const bibleVersionId = bibleVersion.id;
    
    return {
        bibleVersionId
    }
}

export default withRouter(connect(mapStateToProps, {
     })(BibleHeader));