import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ChristTheSheperd from '../Home/components/ChristTheSheperd.jpg';

const styles = theme => ({
    root: {
        padding: '30px 20px',
        '& span': {
            //color: 'blue',
        },
        // paddingTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    wrapper: {        
      position: 'relative',
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    content: {
        marginTop: 20,
    },
    verseReference: {
        //color: 'blue',        
    }
});

class VerseOfTheDayPanel extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        isLoading: PropTypes.bool.isRequired,
        isLoadingSuccessful: PropTypes.bool.isRequired,
        bibleVersionId: PropTypes.number.isRequired,
        bookName: PropTypes.string.isRequired,
        chapterNumber: PropTypes.string.isRequired,
        verseNumber: PropTypes.string.isRequired,
        verseText: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired
    }

    render() {
        const { classes } = this.props;
        const { isLoading, verseText, bookName, chapterNumber, verseNumber  } = this.props                

        return (                        
            <div  className={classes.wrapper} style={{direction: ((this.props.bibleVersionId === 1) ? 'rtl' : 'ltr') }}>                
                <Paper className={classes.root}>
                    <img src={ChristTheSheperd} alt={ChristTheSheperd} />
                    <Typography variant="h6" component="div" className={classes.content} align="center">
                        <span id='verseText'>{verseText}</span>
                        {verseText ?
                            <Box fontStyle="italic" fontSize="body1.fontSize" m={1} className={classes.verseReference}>
                                <span id='bookName'>{bookName}</span> <span id='chapterNumber'>{chapterNumber}</span> : <span id='verseNumber'>{verseNumber}</span>
                            </Box>
                            : ''
                        }
                    </Typography>                    
                </Paper>
                {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
        );
    }
}

export default withStyles(styles)(VerseOfTheDayPanel);