import { take, put, select } from 'redux-saga/effects';
import { entityByName } from '../metadata';
import { entityListActions, entityListStorageActions, syncEntityListFromTheCloud } from '../actions';

const getLoggedinUser = state => state.loggedinUser;

export const watchGetEntityList = function* (entityName) {
    const GET_ENTITY_LIST = entityListActions.GET_ENTITY_LIST(entityName);
    const GET_ENTITY_LIST_FROM_STORAGE = entityListStorageActions.GET_ENTITY_LIST_FROM_STORAGE(entityName);
    const entity = entityByName(entityName);

    while(true) {
        const {type, ...filter} = yield take(GET_ENTITY_LIST.REQUEST);
        yield put(entityListStorageActions.loadEntityListFromStorage(entityName, filter));

        const action = yield take([
            GET_ENTITY_LIST_FROM_STORAGE.SUCCESS, 
            GET_ENTITY_LIST_FROM_STORAGE.FAILURE
        ]);

        if (action.type === GET_ENTITY_LIST_FROM_STORAGE.FAILURE) {
            //yield put(entityListApiActions.loadEntityListFromApi(entityName, filter));            
        } else if (action.type === GET_ENTITY_LIST_FROM_STORAGE.SUCCESS) {
            yield put(entityListActions.getEntityList.success(entityName, action.response));
            //yield put(lastUpdatedEntityListSettingStorageActions.loadEntityListLastUpdatedSettingFromStorage(entityName, filter));            
        }
        
        let loggedinUser = yield select(getLoggedinUser);
        if (entity.isGetEntitiesUserAuthenticationRequired == null || 
            !entity.isGetEntitiesUserAuthenticationRequired || 
            (entity.isGetEntitiesUserAuthenticationRequired && loggedinUser)) {
            yield put(syncEntityListFromTheCloud(entityName, filter));
        }        
    }
}