import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import Root from './common/Root';
import configureStore from './store/configureStore';
import { BASEURL } from './features/Settings/System/helper';
import * as serviceWorker from './serviceWorker';

console.log(`Version: ${process.env.REACT_APP_VERSION}`)

const store = configureStore()

render(
    <Router basename={BASEURL}>
        <Root store={store} />
    </Router>,
    document.getElementById('root')
)

if (window.Cypress) {
    window.store = store
  }

serviceWorker.register();