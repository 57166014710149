import { indexedDb } from '../../../../services/indexedDb';
import { USER_SETTINGS_SIGNEDIN_USERS } from '../../../constants';

const loadSignedInUsersFromLocalStore = () => {
    return indexedDb.userSettings.where('settingName')
        .equals(USER_SETTINGS_SIGNEDIN_USERS)
        .toArray();
}

export const fetchSignedInUsersFromStorage = () => {
    return loadSignedInUsersFromLocalStore()
        .then(signedInUsers => {        
            if (signedInUsers.length > 0) {
                return {...signedInUsers}
            }
            else {
                //throw new Error('No verses found in local storage!');
            }
        })
        .catch(error => {
            //throw error;
        })
        .then(
            response => ({ response }),
            error => ({ error: error.message })
        );
}