import { take, call, fork, select, put } from 'redux-saga/effects';
import { chapterVersesActions, getChapterVersesFromApiActions, updateVersesStorage, displayDownloadBibleOptions } from '../actions';
import { fetchChapterVersesApi } from '../chapterVersesApi';
import { fetchEntity } from '../../../../sagas/sagaHelper';

// bind Generators
const fetchChapterVerses = fetchEntity.bind(null, getChapterVersesFromApiActions.getChapterVersesFromApi, fetchChapterVersesApi);

const getApiUrl = state => state.systemSettings.apiUrl;

function* loadVerses(bibleVersionId, bookId, chapterId) {
    let apiUrl = yield select(getApiUrl);
    
    yield call(fetchChapterVerses, apiUrl, bibleVersionId, bookId, chapterId);
}

export const watchLoadChapterVersesFromApi = function* () {
    const { GET_CHAPTER_VERSES_FROM_API } = getChapterVersesFromApiActions;

    while(true) {
        const {bibleVersionId, bookId, chapterId} = yield take(getChapterVersesFromApiActions.LOAD_CHAPTER_VERSES_FROM_API);        
        yield fork(loadVerses, bibleVersionId, bookId, chapterId);

        const action = yield take([GET_CHAPTER_VERSES_FROM_API.SUCCESS, GET_CHAPTER_VERSES_FROM_API.FAILURE]);
        if (action.type === GET_CHAPTER_VERSES_FROM_API.SUCCESS) {
            yield put(chapterVersesActions.getChapterVerses.success('ChapterVerses', action.response));
            yield put(displayDownloadBibleOptions(bibleVersionId, bookId));
            const verses = action.response.entities.verses;
            yield put(updateVersesStorage(verses));
        } else if (action.type === GET_CHAPTER_VERSES_FROM_API.FAILURE) {
            yield put(chapterVersesActions.getChapterVerses.failure());
        }
    }
}