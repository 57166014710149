import * as actions from '../../../../actions/actionRequestTypes';

import * as _chapterVersesActions from './chapterVersesActions';
import * as _getChapterVersesFromStorageActions from './getChapterVersesFromStorageActions';
import * as _getChapterVersesFromApiActions from './getChapterVersesFromApiActions';
import * as _bookVersesFromApiActions from './bookVersesFromApiActions';
import * as _bibleVersesFromApiActions from './bibleVersesFromApiActions';

export const chapterVersesActions = _chapterVersesActions;
export const getChapterVersesFromStorageActions = _getChapterVersesFromStorageActions;
export const getChapterVersesFromApiActions = _getChapterVersesFromApiActions;
export const bookVersesFromApiActions = _bookVersesFromApiActions;
export const bibleVersesFromApiActions = _bibleVersesFromApiActions;

const { action } = actions;

export const LOAD_CHAPTER_VERSES = 'LOAD_CHAPTER_VERSES';
export const loadChapterVerses = (bibleVersionId, bookId, chapterId) => action(LOAD_CHAPTER_VERSES, {bibleVersionId, bookId, chapterId});

export const UPDATE_VERSES_STORAGE = 'UPDATE_VERSES_STORAGE';
export const updateVersesStorage = (verses) => action(UPDATE_VERSES_STORAGE, {verses});

export const DISPLAY_DOWNLOAD_BIBLE_OPTIONS = 'DISPLAY_DOWNLOAD_BIBLE_OPTIONS';
export const displayDownloadBibleOptions = (bibleVersionId, bookId) => action(DISPLAY_DOWNLOAD_BIBLE_OPTIONS, {bibleVersionId, bookId});