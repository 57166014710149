import _ from 'lodash';
import { indexedDb } from '../../../services/indexedDb';
import { entityByName } from '../metadata';
import { SETTINGS_LASTUPDATED_ENTITYLIST_NAME } from '../../../features/constants';

export const saveEntityListToStorage = (entityName, entityList, versionId, currentUtcTime) => {
    var table;
    const entity = entityByName(entityName);
    if (entity) {
        table = indexedDb.table(entity.storageTableName);
    }

    if (table) {
        const entities = _.values(entityList);
        table.bulkPut(entities)
            .then(lastKey => {
            })
            .catch(error => {
            });

        indexedDb.settings.put({ settingName: SETTINGS_LASTUPDATED_ENTITYLIST_NAME(entityName, versionId), lastUpdatedUtcTime: currentUtcTime })
            .catch(error => {
            });
    }    
}