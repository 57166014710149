import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const styles = theme => ({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    wrapper: {        
      position: 'relative',
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
});

class BibleBooksListButton extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        theme: PropTypes.object.isRequired,
        isLoading: PropTypes.bool.isRequired,
        isLoadingSuccessful: PropTypes.bool.isRequired,
        bibleBooksList: PropTypes.array.isRequired,
        selectedBibleBookName: PropTypes.string.isRequired,
        langId: PropTypes.number.isRequired,
        onHandleBibleBookClick: PropTypes.func.isRequired,
        onHandleBibleBookClose: PropTypes.func.isRequired,
        onHandleBibleBookMenuItemClick: PropTypes.func.isRequired
    }
    render() {
        const { classes } = this.props;
        const { isLoading, isLoadingSuccessful, bibleBooksList, selectedBibleBookName, langId } = this.props; 
        const { anchorBibleBookEl, onHandleBibleBookClick, onHandleBibleBookClose, onHandleBibleBookMenuItemClick } = this.props      
        
        return (
            <div className={classes.wrapper} style={{direction: ((langId === 1) ? 'rtl' : 'ltr') }}>
                <Button                    
                    aria-owns={anchorBibleBookEl ? 'bibleBooks-menu' : undefined}
                    aria-haspopup="true"
                    color="inherit"
                    disabled={isLoading || !isLoadingSuccessful}                    
                    onClick={onHandleBibleBookClick}                                        
                >
                    {selectedBibleBookName}
                </Button>
                {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                <Menu
                    id="bibleBooks-menu"
                    anchorEl={anchorBibleBookEl}
                    open={Boolean(anchorBibleBookEl)}
                    onClose={onHandleBibleBookClose}
                    style={{direction: ((langId === 1) ? 'rtl' : 'ltr') }}
                >
                    {bibleBooksList.map(book => (
                        <MenuItem key={book.bookLangId} 
                            onClick={event => onHandleBibleBookMenuItemClick(event, book.bookId)}>
                                {book.bookName}
                        </MenuItem>
                        ))}                                       
                </Menu>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(BibleBooksListButton);