import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import BibleVersionsListButton from './ListButton';
import { loadBibleVersions, setBibleVersion } from './actions';

class BibleVersions extends Component {
    static propTpes = {
        bibleVersionsList: PropTypes.array.isRequired,
        selectedBibleVersion: PropTypes.object.isRequired,
        loadBibleVersions: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        isLoadingSuccessful: PropTypes.bool.isRequired,
        setBibleVersion: PropTypes.func.isRequired
    }

    state = {
        anchorBibleVersionEl: null,
    };
    
    componentDidMount() {
        this.props.loadBibleVersions();
    }

    handleBibleVersionClick = event => {
        this.setState({ anchorBibleVersionEl: event.currentTarget });
    };

    handleBibleVersionClose = () => {
        this.setState({ anchorBibleVersionEl: null });
    };

    handleBibleVersionMenuItemClick = (event, versionId, langId) => {
        this.setState({ anchorBibleVersionEl: null });
        this.props.setBibleVersion(versionId, langId);
    }

    render() {
        const { isLoading, isLoadingSuccessful, selectedBibleVersion } = this.props;
        let bibleVersionName = "";

        if (isLoading) {
            bibleVersionName = "Loading versions";
        } else if (isLoadingSuccessful) {
            bibleVersionName = selectedBibleVersion ? `${selectedBibleVersion.langName} - ${selectedBibleVersion.versionName}` : "Select Version";
        } else {
            bibleVersionName = "Version";
        }

        return (
            <div>
                <BibleVersionsListButton isLoading={isLoading}
                    isLoadingSuccessful={isLoadingSuccessful} 
                    bibleVersionsList={this.props.bibleVersionsList}
                    selectedBibleVersionName={bibleVersionName}
                    anchorBibleVersionEl={this.state.anchorBibleVersionEl} 
                    onHandleBibleVersionClick={this.handleBibleVersionClick} 
                    onHandleBibleVersionClose={this.handleBibleVersionClose}
                    onHandleBibleVersionMenuItemClick={this.handleBibleVersionMenuItemClick} />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const {
        entities: { bibleVersions },
        bibleVersion
    } = state

    const bibleVersionsList = _.values(bibleVersions);
    const selectedBibleVersion = bibleVersions[bibleVersion.id];
    const isLoading = bibleVersion.isLoading;
    const isLoadingSuccessful = bibleVersion.isLoadingSuccessful;
    
    return {
        bibleVersionsList,
        selectedBibleVersion,
        isLoading,
        isLoadingSuccessful
    }
}

export default withRouter(connect(mapStateToProps, {
    loadBibleVersions,
    setBibleVersion
    })(BibleVersions));